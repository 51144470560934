import {Typography} from "@mui/material";
import {useGetQAsQueryGenerated} from "../types/generated";
import {ArrowForward} from "@mui/icons-material";
import {Link} from "react-router-dom";
import LoadingIcons from "react-loading-icons";

export function QA() {

  const { loading, data, error } = useGetQAsQueryGenerated();

  return (
    <div id='qa-page' className="flex min-w-[100vw] flex-col lg:items-center">
      <div className="flex flex-col gap-4 lg:w-[750px]">
        {loading && (
          <div className="flex flex-col items-center">
            <LoadingIcons.Bars />
            <Typography variant="overline">Loading Q&As</Typography>
          </div>
        )}
        {error && (
          <div>
            {error && "Error Loading Q&As"}
          </div>
        )}
        {data?.getQAs.map(({ id, title, date, body, cssId }) => (
          <Link
            to={id?.toString() || ""}
            state={{ qa: { id, title, date, body, cssId } }} 
          >
            <div key={`qa-${id}`}className="transmission-item flex items-center justify-between px-4 py-2">
              <div className="flex flex-col">
                <div>
                  <Typography variant="caption" className="opacity-50">
                    {date || ''}
                  </Typography>
                </div>
                <div className="-mt-[5px]">
                  <Typography variant="overline">{title}</Typography>
                </div>
              </div>
              <ArrowForward />
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}
