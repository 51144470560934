import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

type SwipeOptionsProps = {
  id: number;
  status: string;
  children: React.ReactNode;
};

const SwipeOptions = (props: SwipeOptionsProps) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/profile/notifications/${props.id}`);
  };

  const borderClass =
    props.status === "unread"
      ? "border border-[#6DFE95]"
      : "border-t border-b border-[#ffffff30]";

  return (
    <div className={`relative my-2 flex flex-row items-center ${borderClass}`}>
      <div className="w-full">
        <div
          ref={itemRef}
          className="item flex items-center justify-between bg-[#00000042] py-4 px-4 text-white"
          onClick={handleClick}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default SwipeOptions;
