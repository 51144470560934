import Profile from "../Profile";
import Notifications from "../Profile/Notifications";
import AvatarPage from "../Profile/AvatarPage";
import NotificationsDetail from "../Profile/NotificationsDetail";
import Settings from "../Profile/Settings";
import UpdateIdentity from "../Profile/UpdateIdentity";
import UpdateSubscription from "../Profile/UpdateSubscription";
import Plan from "../Profile/Plan";
import Connect from "../Profile/Connect";
import Help from "../Profile/Help";
import UpdateCognitoField from "../Profile/UpdateCognitoField";
import { AttributeConfirmationForm } from "../Profile/AttributeConfirmationForm";
import { SubscriptionPage } from "./subscription/SubscriptionPage";
import AccountInfo from "../Profile/AccountInfo";
import { Route } from "react-router-dom";
import { Scores } from "./Scores";

export function useProfileRoutes() {
  return (
    <>
      <Route path="/profile" element={<Profile />} />
      <Route path="/profile/avatar" element={<AvatarPage />} />
      <Route path="/profile/subscription" element={<SubscriptionPage />} />
      <Route path="/profile/verify" element={<AttributeConfirmationForm />} />
      <Route path="/profile/notifications" element={<Notifications />} />
      <Route
        path="/profile/notifications/:noteId"
        element={<NotificationsDetail />}
      />
      <Route path="/profile/settings" element={<Settings />} />
      <Route path="/profile/plan" element={<Plan />} />
      <Route path="/profile/connect" element={<Connect />} />
      <Route path="/profile/accountInfo" element={<AccountInfo />} />
      <Route
        path="/profile/accountInfo/update"
        element={<UpdateCognitoField />}
      />
      <Route path="/profile/updateIdentity" element={<UpdateIdentity />} />
      <Route
        path="/profile/updateSubscription"
        element={<UpdateSubscription />}
      />
      <Route path="/profile/help" element={<Help />} />
      <Route path="/profile/scores" element={<Scores />} />
    </>
  );
}
