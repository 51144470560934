import { Pause, PlayArrow } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useContext } from "react";
import { ProgressSlider } from "./ProgressSlider";
import { MediaPlayerContext } from "./MediaPlayerProvider";

import "./styles.css";

interface Props {
  onOpen: () => void;
}

export function Player({ onOpen }: Props) {
  const {
    nowPlayingType,
    nowPlayingAlbum,
    nowPlayingIndex,
    setIsPlaying,
    isPlaying,
    updatePlayer,
    playerType,
    progress,
    setProgress,
    playerRef,
    setIsSeeking,
    playbackError,
  } = useContext(MediaPlayerContext);

  const handleSliderChange = (_: any, value: any) => {
    setProgress({ ...progress, played: Number(value) });
  };

  const handleOnMouseUpSeekBar = (_: any, value: any) => {
    setIsSeeking(false);
    return playerRef.current?.seekTo(Number(value));
  };

  // console.log("PLAYER TYPE: ", playerType);

  // console.log("NOW PLAYING ALBUM IN PLAYER: ", nowPlayingAlbum);
  // console.log("NOW PLAYING INDEX: ", nowPlayingIndex);
  // console.log("NOW PLAYING TYPE: ", nowPlayingType);

  // console.log("MIXCLOUD BOOL 1: ", nowPlayingIndex >= 0);
  // console.log("MIXCLOUD BOOL 2: ", playerType == "Mixcloud");
  // console.log("MIXCLOUD BOOL 3: ", nowPlayingAlbum.trackList != undefined);

  // console.log(
  //   "MIXCLOUD BOOL TEST: ",
  //   nowPlayingIndex >= 0 &&
  //     playerType == "Mixcloud" &&
  //     nowPlayingAlbum.trackList != undefined
  // );

  // console.log("OTHER BOOL 1: ", nowPlayingIndex >= 0);
  // console.log("OTHER BOOL 2: ", playerType != "Mixcloud");
  // console.log("OTHER BOOL 3: ", nowPlayingAlbum.trackList != undefined);

  // console.log(
  //   "OTHER BOOL TEST: ",
  //   nowPlayingIndex >= 0 &&
  //     playerType != "Mixcloud" &&
  //     nowPlayingAlbum.trackList != undefined
  // );

  return nowPlayingIndex > -1 &&
    playerType != "Mixcloud" &&
    nowPlayingAlbum.trackList != undefined &&
    nowPlayingAlbum.trackList[nowPlayingIndex] != undefined ? (
    <>
      <ProgressSlider
        className={playerType === "Mixcloud" ? "bg-transparent" : ""}
        step={0.000001}
        defaultValue={0}
        max={0.999999}
        min={0}
        value={progress.played}
        onChange={handleSliderChange}
        onChangeCommitted={handleOnMouseUpSeekBar}
      />
      <div
        className="player-background flex items-center justify-between"
        onClick={onOpen}
      >
        <div className="flex items-center">
          {nowPlayingAlbum?.coverArt && (
            <img
              src={nowPlayingAlbum.coverArt}
              alt={nowPlayingAlbum.title}
              className="now-playing-artwork rounded-sm"
            />
          )}

          <div className="flex flex-col">
            <Typography variant="trackTitle">
              {nowPlayingAlbum.trackList[nowPlayingIndex].title}
            </Typography>
            <Typography variant="body1">{nowPlayingAlbum.trackList[nowPlayingIndex].artist}</Typography>
          </div>
        </div>

        <div
          className="play-button flex justify-center rounded-full p-1 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsPlaying(!isPlaying);
            updatePlayer(
              nowPlayingType,
              nowPlayingIndex,
              nowPlayingAlbum,
              !isPlaying
            );
        }}>
          {isPlaying && !playbackError ? (
            <Pause sx={{ color: "#7d0b00", fontSize: "35px" }} />
          ) : (
            <PlayArrow sx={{ color: "#7d0b00", fontSize: "35px" }} />
          )}
        </div>
      </div>
    </>
  ) : (
    <div className="h-[5.5rem]" />
  );
}
