import { ArrowBack } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

export function QADetails() {
  const {
    state: { qa },
  } = useLocation();

  return (
    <div id={qa.cssId ? qa.cssId : "qa-detail"} className="flex min-w-[100vw] flex-col lg:items-center">
      <div className="transmission-body mb-4 flex flex-col px-4 py-2 lg:max-w-[750px] lg:min-w-[750px]">
        <div className="mt-[10px]">
          <Typography variant="caption" className="opacity-50">
            {qa.date}
          </Typography>
        </div>
        <div className="-mt-[10px]">
          <Typography variant="overline">{qa.title}</Typography>
        </div>
        <div className="mt-4">
          <div dangerouslySetInnerHTML={{ __html: qa.body }} />
        </div>
      </div>
      <div className="flex justify-center">
        <Link to="/thoughts/qa">
          <Button variant="bassnectar" startIcon={<ArrowBack />}>
            Back To Q&A
          </Button>
        </Link>
      </div>
    </div>
  );
}
