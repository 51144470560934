import { Suspense } from "react";
import { Route } from "react-router-dom";
import { Events } from "../Events";
import { EventDetails } from "../Events/Details";
import { SkeletonVariants } from "../types/generated";
import { Skeleton } from "../ui/Skeleton";

export function useEventRoutes() {
  return (
    <>
      {/* <Route path="/events" element={<Events />} /> */}
      <Route
        path="/events/:eventId"
        element={
          <Suspense fallback={<Skeleton variant={SkeletonVariants.event} />}>
            <EventDetails />
          </Suspense>
        }
      />
    </>
  );
}
