import { IconButton, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthProvider";

interface Props {
  error?: string;
}

export function ErrorToast({ error: errorProps }: Props) {
  const { errors: authErrors, clearErrors } = useContext(AuthContext);
  const onDismiss = () => clearErrors();

  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    setErrors(errorProps ? [errorProps] : authErrors ?? []);
  }, [authErrors, errorProps]);

  return (
    <>
      {errors && errors.length > 0 && (
        <div className="m-6 flex items-start justify-between rounded-xl bg-[#c11212] p-4">
          <div className="flex flex-col">
            {errors.map((e: string, index) => (
              <Typography key={`${index}`} variant="h6">
                {e}
              </Typography>
            ))}
          </div>
          <IconButton onClick={onDismiss} />
        </div>
      )}
    </>
  );
}
