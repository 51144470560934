import { Button } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const topStyle = {
  background: "linear-gradient(180deg, #6DFE95 0%, #1CF9D7 100%)",
  boxShadow: "0px -18.9971px 22.7965px rgba(0, 0, 0, 0.25)",
  color: "#730A1C",
};

const btmStyle = {
  background:
    "linear-gradient(180deg, rgba(254, 234, 131, 0) 0%, rgba(254, 234, 131, 0.1) 54.69%, rgba(0, 0, 0, 0) 100%, rgba(254, 234, 131, 0) 100%), linear-gradient(355.67deg, #F63523 -18.37%, #7D0B00 -18.36%, #55006E 100.08%)",
  boxShadow: "0px -18.9971px 22.7965px rgba(0, 0, 0, 0.25)",
};

interface Props {
  onDismiss: () => void;
}

const OtherSide = ({ onDismiss }: Props) => {
  const navigate = useNavigate();
  const goToAbout = () => {
    navigate("/about");
  };

  return (
    <div className="other-side-container relative">
      <Button
        className="hide absolute -left-[8px] top-[40px] min-w-0"
        onClick={onDismiss}
      >
        <CloseIcon
          className="text-[15px] font-bold"
          style={{ color: "#730A1C" }}
        />
      </Button>

      <div
        className="top py-2 text-center text-[24px] font-extrabold leading-[29px]"
        style={topStyle}
        onClick={goToAbout}
      >
        What is The Other Side?
      </div>
      <div
        className="btm py-1 text-center text-[12px] font-bold"
        style={btmStyle}
      >
        <span className="bottom-text">
          Learn about the platform & view subscription options
        </span>
      </div>
    </div>
  );
};

export default OtherSide;
