import { Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const UpdateSubscription = () => {
  return (
    <div>
      Subscription form
      <div className='space-y-2 mt-10'>
        <Typography variant='h4'>
          Delete Account <span className='text-m'>*</span>
        </Typography>
        <Typography variant='h6'>You may delete your account here.</Typography>
        <Typography variant='h6'>
          <span className='text-m'>* </span>
          Please Note:
          <br />
          <div className='ml-4'>
            as per the Term & Conditions we cannot refund any fees you have
            incurred. However, you are welcome to cancel your account at any
            time
          </div>
        </Typography>
      </div>
      <div className='mt-5'>
        <Link to='/profile/deleteAccount'>
          <Button variant='bassnectarJumbo'>Delete Account</Button>
        </Link>
      </div>
    </div>
  );
};

export default UpdateSubscription;
