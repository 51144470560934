import { Route } from "react-router-dom";
import { Interact } from ".";

export function useInteractRoutes() {
  return (
    <>
      <Route path="/community/interact" element={<Interact />} />
    </>
  );
}
