import "./styles.css";

import { BackButton } from "../ui/BackButton";
import { useNavigate } from "react-router-dom";

const game = process.env.PUBLIC_URL + "/games/locoqueen/index.html";

export default function Locoqueen() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1 || "/");
  };

  return (
    <div className="locoqueen-bkg absolute top-0 left-0 z-[999] min-h-[100vh] min-w-[100vw] md:min-w-auto overflow-hidden">
      <iframe
        title="Locoqueen"
        src={game}
        className="m-auto block h-[90vh] max-h-[100vh] w-[100vw]"
      />
      <div className="absolute top-2 left-2 z-[999] w-full">
        <BackButton onClick={goBack} />
      </div>
    </div>
  );
}
