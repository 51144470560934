import SliderUnstyled, { SliderUnstyledProps } from "@mui/base/SliderUnstyled";

export function ProgressSlider(props: SliderUnstyledProps) {
  return (
    <SliderUnstyled
      {...props}
      slotProps={{
        thumb: {
          className: "slider-thumb -mt-1 -ml-2 absolute hidden",
        },
        root: {
          className: "w-full inline-block h-2 absolute cursor-pointer",
        },
        rail: {
          className: "slider-rail h-[2px] w-full block absolute",
        },
        track: {
          className: "slider-track h-[2px] absolute",
        },
      }}
    />
  );
}
