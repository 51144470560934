import { Typography } from "@mui/material";
import MediaQuery from "react-responsive";

type IHProps = {
  headline: string;
  artwork: string;
};

export function ImageHero({ headline, artwork }: IHProps) {
  return (
    <div className="flex w-full flex-col items-center gap-4 lg:max-w-[1150px]">
      <div className="px-4">
        <MediaQuery minWidth={1024}>
          <Typography variant="h3" className="uppercase">
            {headline}
          </Typography>
        </MediaQuery>
        <MediaQuery maxWidth={1023}>
          <Typography variant="h4" className="uppercase">
            {headline}
          </Typography>
        </MediaQuery>
      </div>
      <img className="w-full" src={artwork} alt="hero" />
    </div>
  );
}
