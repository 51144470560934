import { createContext, PropsWithChildren, useEffect, useState } from "react";
import {
  StrapiEvent,
  useGetAlbumSuspenseQueryGenerated,
  useGetUpcomingEventQueryGenerated,
  useGetUpcomingEventSuspenseQueryGenerated,
} from "../types/generated";
export const THREE_HOURS = 10800000;
export const TWO_HOURS = 7200000;

type LiveEventContextParams = {
  countdownDisplay: string;
  eventDay: boolean;
  doorsOpen: boolean;
  eventStarted: boolean;
  eventEnded: boolean;
  event?: StrapiEvent;
  eventLoading: boolean;
};

export const LiveEventContext = createContext<LiveEventContextParams>({
  countdownDisplay: "",
  eventDay: false,
  doorsOpen: false,
  eventStarted: false,
  eventEnded: false,
  event: undefined,
  eventLoading: true,
});

// const countDownDate = new Date("Fri Sep 29 2023 00:00:00 GMT");
// const eventLength = 3600000;
// const eventWaitingRoomPeriod = TWO_HOURS;

export function LiveEventProvider({ children }: PropsWithChildren) {
  const [eventDay, setEventDay] = useState(false);
  const [eventEnded, setEventEnded] = useState(false);
  const [eventStarted, setEventStarted] = useState(false);
  const [doorsOpen, setDoorsOpen] = useState(false);
  const [countdownDisplay, setCountdownDisplay] = useState("");
  const [event, setEvent] = useState<StrapiEvent | undefined>(undefined);
  const [eventLoading, setEventLoading] = useState(true);

  const { data, loading } = useGetUpcomingEventQueryGenerated();

  useEffect(() => {
    var x = setInterval(function () {
      if (
        !data ||
        !data.getUpcomingEvent ||
        !data.getUpcomingEvent.liveData ||
        !data.getUpcomingEvent.liveData.eventStartDate ||
        !data.getUpcomingEvent.liveData.eventEndDate
      ) {
        if (!loading) {
          setEventLoading(false);
        }
        return;
      }
      var now = new Date();
      const startDate = new Date(data.getUpcomingEvent.liveData.eventStartDate);
      const endDate = new Date(data.getUpcomingEvent.liveData.eventEndDate);
      const preshow = data.getUpcomingEvent.liveData.preshowCloudflareId;

      var distance = startDate.getTime() - now.getTime();

      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setEventDay(true);
      setEventStarted(distance <= 0);
      setDoorsOpen(distance <= THREE_HOURS);
      setEventEnded(now.getTime() > endDate.getTime());
      setCountdownDisplay(
        `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
          seconds < 10 ? `0${seconds}` : seconds
        }`
      );
      setEvent(data.getUpcomingEvent);
      setEventLoading(false);
    }, 1000);

    return () => clearInterval(x);
  }, [data]);

  return (
    <LiveEventContext.Provider
      value={{
        eventDay,
        doorsOpen,
        eventEnded,
        eventStarted,
        countdownDisplay,
        event,
        eventLoading,
      }}
    >
      {children}
    </LiveEventContext.Provider>
  );
}
