import { Button, FormControl, Typography } from "@mui/material";
import React, { useContext, useState, useRef } from "react";
//import { Link } from 'react-router-dom';
import { AuthContext } from "../Auth/AuthProvider";
import { SectionHero } from "../ui/components/SectionHero";

import ChangePassword from "./ChangePassword";
import EditField from "./EditField";

const UpdateIdentity = () => {
  const { user } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  //const [password, setPassword] = useState('');
  const [passwordActive, setPasswordActive] = useState(false);

  const userNameRef = useRef<HTMLInputElement | null>(null);
  const firstNameRef = useRef<HTMLInputElement | null>(null);
  const lastNameRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);
  //const passwordRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission here
    // You can update the necessary user information or make an API call
  };

  return (
    <div>
      <SectionHero title="My TOS IDENTITY" />
      <div className="mb-8 flex flex-col px-6">
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <Typography variant="h4">Identity</Typography>
          </div>
          <Typography>
            <form onSubmit={handleSubmit}>
              <FormControl className="w-full">
                <EditField
                  title="Username "
                  item={user?.username}
                  inputRef={userNameRef}
                  state={username}
                  setState={setUsername}
                />
              </FormControl>
              <FormControl className="w-full">
                <EditField
                  title="First Name "
                  item={user?.given_name}
                  inputRef={firstNameRef}
                  state={firstName}
                  setState={setFirstName}
                />
              </FormControl>
              <FormControl className="w-full">
                <EditField
                  title="Last Name "
                  item={user?.family_name}
                  inputRef={lastNameRef}
                  state={lastName}
                  setState={setLastName}
                />
              </FormControl>
              <FormControl className="w-full">
                <EditField
                  title="Email "
                  item={user?.email}
                  inputRef={emailRef}
                  state={email}
                  setState={setEmail}
                />
              </FormControl>

              <div className="mt-5 flex justify-center">
                <Button type="submit" variant="bassnectarJumbo">
                  Save
                </Button>
              </div>
            </form>
          </Typography>
        </div>
      </div>
      <div className="">
        {!passwordActive && (
          <Button
            onClick={() => setPasswordActive(true)}
            variant="bassnectarSecondary"
          >
            Change Password{" "}
          </Button>
        )}
        {passwordActive && (
          <div>
            <ChangePassword />
            <Button
              onClick={() => setPasswordActive(false)}
              variant="bassnectarText"
            >
              Cancel
            </Button>
          </div>
        )}
        {}
      </div>
    </div>
  );
};

export default UpdateIdentity;
