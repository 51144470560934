import { useContext, useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import LoadingIcons from "react-loading-icons";
import pluralize from "pluralize";
import { useGetMyWalletQueryGenerated } from "../types/generated";
import { AuthContext } from "../Auth/AuthProvider";
import {PurchaseKeysModal} from "./PurchaseKeysModal";
import {userIsGold} from "../Auth/utils";
import Keys from '../assets/wallet-key.svg';
import GuestPass from '../assets/guest.svg';
import {PurchaseGuestPassModal} from "./PurchaseGuestPassModal";
import {UseGuestPassModal} from "./UseGuestPassModal";

export const Wallet = () => {
  const [keyBalance, setKeyBalance] = useState(0);
  const [passBalance, setPassBalance] = useState(0);

  const { user } = useContext(AuthContext);
  const { loading, data, error } = useGetMyWalletQueryGenerated({ fetchPolicy: 'network-only' });

  const [purchaseKeys, setPurchaseKeys] = useState(false);
  const [purchaseGuestPass, setPurchaseGuestPass] = useState(false);
  const [useGuestPass, setUseGuestPass] = useState(false);

  useEffect(() => {
    if (data?.getMyWallet && data.getMyWallet.length > 0) {
      const keyBalance = data.getMyWallet
        .filter((item) => item.sortKey!.indexOf('KEYS') !== -1)
        .reduce((acc, item) => acc + item.amount!, 0);

      const passBalance = data.getMyWallet
        .filter((item) => item.sortKey!.indexOf('GUESTPASS') !== -1)
        .reduce((acc, item) => acc + item.amount!, 0);

      setKeyBalance(keyBalance);
      setPassBalance(passBalance);
    }
  }, [data])

  return (
    <div className="relative border-solid border border-amber-200 bg-black bg-opacity-20 p-4 mb-[40px] w-full rounded-lg flex flex-col items-center gap-4">
      <PurchaseKeysModal
        open={purchaseKeys}
        onDismiss={() => setPurchaseKeys(false)}
      />
      <PurchaseGuestPassModal
        open={purchaseGuestPass}
        keyBalance={keyBalance}
        onDismiss={() => setPurchaseGuestPass(false)}
      />
      <UseGuestPassModal
        open={useGuestPass}
        onDismiss={() => setUseGuestPass(false)}
      />
      <Typography variant="gold" color="black">Your Wallet</Typography>
      {loading ? (
        <div className="flex flex-col items-center">
          <div style={{ margin: "0 auto", width: "200px" }}>
            <LoadingIcons.Bars width="200px" />
          </div>
          <Typography variant="h6">Loading your wallet</Typography>
        </div>
      ) : (
        <div className="w-full min-w-full flex flex-col md:flex-row justify-around px-8">
          {data && data.getMyWallet && (
            <div className="flex flex-col gap-2">
              <div className="mr-2 flex h-[100px] items-center gap-4">
                <img src={Keys} alt="keys-icon" style={{ height: 75 }} />
                <Typography variant="gold">{`${keyBalance} ${pluralize('Key', keyBalance)}`}</Typography>
              </div>
              <Button variant="bassnectarGold" onClick={() => setPurchaseKeys(true)}>ADD KEYS</Button>
            </div>
          )}
          {userIsGold(user) && (
            <div className="flex flex-col gap-2">
              <div className="mr-2 flex h-[100px] items-center gap-4">
                <img src={GuestPass} alt="guest-pass-icon" style={{ height: 75 }} />
                <Typography variant="gold">{`${passBalance} Guest ${pluralize('Pass', passBalance)}`}</Typography>
              </div>
              <div className="flex w-full gap-2 justify-between">
                <Button
                  variant="bassnectarGold"
                  disabled={passBalance === 0}
                  className="flex-1"
                  onClick={() => setUseGuestPass(true)}
                >
                  USE A PASS
                </Button>
                <Button
                  variant="bassnectarGold"
                  className="flex-1"
                  onClick={() => setPurchaseGuestPass(true)}
                >
                  BUY A PASS
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
