import { Link } from "react-router-dom";
import discord from "../assets/discord-color.svg";
import apple from "../assets/apple-color.svg";
import google from "../assets/google-color.svg";
import twitter from "../assets/twitter-color.svg";
import instagram from "../assets/instagram-color.svg";

const Connect = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center px-6">
        {/*<h1 className="text-white font-bold text-xl my-4">
        My connected accounts
      </h1>*/}

        {/*<div className="flex flex-col mb-4 mt-4 w-full">
        <label
          htmlFor="username"
          className="text-white text-center text-2xl font-bold"
        >
          Connect Social Profiles
        </label>
      </div>*/}

        <div className="mb-4 flex w-full flex-col">
          <label
            htmlFor="username"
            className="text-center text-2xl font-bold text-white"
          >
            These features coming soon!
          </label>
        </div>
        <Link
          to={""}
          className="mt-0 flex w-56 rounded-3xl bg-gray-600 py-2 px-4 font-bold text-gray-900"
        >
          <img src={discord} alt={""} />{" "}
          <span className="ml-2">Connect Discord</span>
        </Link>
        <Link
          to={""}
          className="mt-4 flex w-56 rounded-3xl bg-gray-600 py-2 px-4 font-bold text-gray-900"
        >
          <img src={apple} alt={""} />{" "}
          <span className="ml-2">Connect Apple</span>
        </Link>

        <Link
          to={""}
          className="mt-4 flex w-56 rounded-3xl bg-gray-600 py-2 px-4 font-bold text-gray-900"
        >
          <img src={google} alt={""} />{" "}
          <span className="ml-2">Connect Google</span>
        </Link>

        <Link
          to={""}
          className="mt-4 flex w-56 rounded-3xl bg-gray-600 py-2 px-4 font-bold text-gray-900"
        >
          <img src={twitter} alt={""} />{" "}
          <span className="ml-2">Connect Twitter</span>
        </Link>

        <Link
          to={""}
          className="mt-4 flex w-56 rounded-3xl bg-gray-600 py-2 px-4 font-bold text-gray-900"
        >
          <img src={instagram} alt={""} />{" "}
          <span className="ml-2">Connect Instagram</span>
        </Link>

        <div className="mb-0 mt-9 flex w-full flex-col">
          <label
            htmlFor="username"
            className="text-center text-2xl font-bold text-gray-600"
          >
            Connect Music Profile
          </label>
          <span className="text-center text-sm text-gray-600">
            Choose your preferred music streaming provider & we’ll customize
            your listening experience.
          </span>
        </div>

        <Link
          to={""}
          className="mt-4 flex w-56 rounded-3xl bg-gray-600 py-2 px-4 font-bold text-gray-900"
        >
          <span className="w-full text-center">Connect to Spotify</span>
        </Link>

        <Link
          to={""}
          className="mt-4 flex w-56 rounded-3xl bg-gray-600 py-2 px-4 font-bold text-gray-900"
        >
          <span className="w-full text-center">Connect to Apple Music</span>
        </Link>

        <Link
          to={""}
          className="mt-4 flex w-56 rounded-3xl bg-gray-600 py-2 px-0 font-bold text-gray-900"
        >
          <span className="w-full text-center">Connect to Amazon Music</span>
        </Link>

        <Link
          to={""}
          className="mt-4 flex w-56 rounded-3xl bg-gray-600 py-2 px-4 font-bold text-gray-900"
        >
          <span className="w-full text-center">Connect to Soundcloud</span>
        </Link>

        {/*<div className="flex flex-col mt-4 w-full">
        <span className="text-gray-400 mt-2 w-full">
          <Link to="/profile/settings" className="underline">
            Update
          </Link>{" "}
          my account info
        </span>
      </div>*/}

        {/*<Link
        to={"/profile"}
        className="py-2 rounded-md bg-gray-300 text-gray-700 text-center font-bold flex justify-center items-center px-4 mt-6 mb-28 w-full"
      >
        <span>DONE</span>
      </Link>
      {open ? (
        <div>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  '<iframe src="https://discord.com/widget?id=1065850757032706118&theme=dark" width="100%" style="height: 92vh;" allowtransparency="true" frameborder="0"></iframe>',
              }}
            />
          </Dialog>
        </div>
      ) : null}*/}
      </div>
    </>
  );
};

export default Connect;
