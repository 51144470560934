import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Auth/AuthProvider";
import {
  SquareSubscription,
  StripeSubscription,
  useGetSubscriptionsLazyQueryGenerated,
} from "../../types/generated";

export const useSubscriptionPageLogic = () => {
  const { user } = useContext(AuthContext);

  const [getSubscriptions, { loading, data, error, refetch }] =
    useGetSubscriptionsLazyQueryGenerated();

  const [squareSubscription, setSquareSubscription] = useState<
    SquareSubscription | undefined
  >(undefined);

  const [stripeSubscription, setStripeSubscription] = useState<
    StripeSubscription | undefined
  >(undefined);

  useEffect(() => {
    if (user?.email && getSubscriptions) {
      getSubscriptions({
        variables: {
          email: user.email,
        },
      });
    }
  }, [user?.email, getSubscriptions]);

  useEffect(() => {
    if (data?.getUser.squareCustomer?.subscriptions) {
      handleSquareSubscriptions(data.getUser.squareCustomer.subscriptions);
    }
    if (data?.getUser.stripeSubscriptions) {
      handleStripeSubscriptions(data.getUser.stripeSubscriptions);
    }
  }, [data?.getUser.squareCustomer, data?.getUser.stripeSubscriptions]);

  const handleSquareSubscriptions = (subscriptions: SquareSubscription[]) => {
    const now = new Date();

    const sub = subscriptions.find((sub) => {
      if (!sub.startDate || !sub.chargedThroughDate) {
        return false;
      }

      return (
        (sub.status === "ACTIVE" ||
          sub.status === "TRIAL" ||
          sub.status === "CANCELED") &&
        new Date(sub.startDate) <= now &&
        new Date(sub.chargedThroughDate) >= now
      );
    });

    setSquareSubscription(sub);
  };

  const handleStripeSubscriptions = (subscriptions: StripeSubscription[]) => {
    if (subscriptions.length > 0) {
      const sub = subscriptions[0];
      setStripeSubscription(sub);
    }
  };

  const handleRefetchSubscriptions = async () => {
    await refetch({ email: user?.email });
  };

  const onSquareSubscriptionCanceled = (subscription: SquareSubscription) => {
    setSquareSubscription(subscription);
  };

  return {
    loading,
    error,
    squareSubscription,
    stripeSubscription,
    refetchSubscriptions: handleRefetchSubscriptions,
    onSquareSubscriptionCanceled,
  };
};

export type SubscriptionPageProps = ReturnType<typeof useSubscriptionPageLogic>;
