/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_cognito_identity_pool_id": "us-west-1:338cafb3-5b10-45c1-a32f-eb5ac505cefa",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_cGpJsLQX1",
    "aws_user_pools_web_client_id": "55mdko83210ph47e9ndu4p94r2",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tos-avatars-public155134-production",
    "aws_user_files_s3_bucket_region": "us-west-1"
};


export default awsmobile;
