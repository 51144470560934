import { CircularProgress, IconButton, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ErrorIcon from "@mui/icons-material/Error";
import EQ from "../assets/animation/now_playing.gif";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

interface TrackItemProps {
  title: string;
  info: string;
  unlocked: boolean;
  active: boolean;
  isPlaying: boolean;
  started: boolean;
  isError: boolean;
  onPlay: () => void;
}

export function TrackItem({
  title,
  info,
  unlocked,
  active,
  isPlaying,
  isError,
  started,
  onPlay,
}: TrackItemProps) {
  return (
    <div className="max-h-[32px] min-h-[32px] w-full cursor-pointer">
      <div
        className="flex h-full items-center justify-between"
        onClick={onPlay}
      >
        <div className="flex flex-col">
          <Typography
            variant="trackTitle"
            className={active ? "current-track" : ""}
          >
            {title}
          </Typography>
          <Typography variant="body1" className={active ? "current-track" : ""}>
            {info}
          </Typography>
        </div>
        <div className="flex">
          {active && isPlaying && started && (
            <img
              style={{ marginRight: 10, height: 32 }}
              src={EQ}
              alt="play animation"
            />
          )}

          {active && !started && !isError && (
            <CircularProgress color="success" size={24} />
          )}

          {active && isError && (
            <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-white">
              <ErrorIcon color="error" />
            </div>
          )}

          {!unlocked && (
            <IconButton onClick={onPlay}>
              <LockIcon sx={{ color: "white" }} />
            </IconButton>
          )}
          {/* TODO: Playlist functionality
            <IconButton onClick={() => {}}>
              <MoreVertIcon />
            </IconButton>
          */}
        </div>
      </div>
    </div>
  );
}
