import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Notification,
  useGetNotificationDetailsQueryGenerated,
  useMarkNotificationAsReadMutationGenerated,
} from "../types/generated";
import { SectionHero } from "../ui/components/SectionHero";
import { Stack } from "../ui/Stack";
import { NotificationsContext } from "./NotificationsContext";

const NotificationsDetail = () => {
  const [notification, setNotification] = useState<Notification | null>(null);
  const { notifications } = useContext(NotificationsContext);
  const { noteId } = useParams();

  const { data } = useGetNotificationDetailsQueryGenerated({
    variables: { noteId: parseInt(noteId!) },
  });

  const [markAsRead] = useMarkNotificationAsReadMutationGenerated();

  useEffect(() => {
    const nextNote = notifications.filter(
      (note) => note.id === parseInt(noteId!)
    )[0];
    setNotification(nextNote);

    console.log(nextNote);

    if (data && data.getNotificationDetails) {
      markAsRead({ variables: { key: nextNote.key } });
    }
  }, [noteId, notifications, markAsRead, data]);

  return (
    <>
      <SectionHero title="Notifications" />
      <div className="mb-8 flex flex-col items-center justify-center px-6">
        {notification && (
          <>
            <h2 className="mb-2 text-3xl font-bold text-white">
              {notification.title}
            </h2>
            {data && <Stack stack={data.getNotificationDetails.stack} />}
          </>
        )}
      </div>
    </>
  );
};

export default NotificationsDetail;
