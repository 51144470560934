import { ReactNode } from "react";
import AlbumCard from "../Listen/AlbumCard";
import { FeaturedContent } from "./FeaturedContent";
import { HTMLBlock } from "./components/HTMLBlock";
import { SectionHero } from "./components/SectionHero";
import { TileGrid } from "./components/TileGrid";
import { TileList } from "./components/TileList";
import { ImageHero } from "./components/ImageHero";
import { StrapiDynamicPageStack, StrapiTile } from "../types/generated";
import { CTA } from "./components/CTA";
import { TypographyComponent } from "./components/TypographyComponent";

type StackItemProps = {
  component: StrapiDynamicPageStack;
  index: number;
  onNavigate: (slug: string) => () => void;
  onTileClick: (tile: StrapiTile) => () => void;
};

export function StackItem({
  component,
  index,
  onNavigate,
  onTileClick,
}: StackItemProps) {
  const stackItemContent = () => {
    let out: ReactNode = undefined;
    switch (component.__typename) {
      case "StrapiFeaturedContent":
        out = (
          <FeaturedContent
            index={index}
            headline={component.headline}
            title={component.title!}
            artwork={component.artwork!}
            subtitle={component.subtitle!}
            detailSlug={component.detailSlug!}
            locked={false}
          />
        );
        break;

      case "StrapiTileList":
        out = (
          <TileList
            key={`stack-${index}`}
            title={component.title!}
            seeAll={component.detailSlug ? true : false}
            seeAllAction={
              component.detailSlug
                ? onNavigate(component.detailSlug)
                : undefined
            }
            seeAllLabel={component.detailLabel || undefined}
          >
            {component.tiles.map((tile, index) => (
              <AlbumCard
                key={`stack-tile-${index}`}
                variant="small"
                id={tile.id}
                artwork={tile.thumbnail ?? undefined}
                title={tile.title ?? ""}
                info={tile.description ?? undefined}
                onClick={onTileClick(tile)}
                playAllButton={false}
                playAllAction={() => {}}
                hideImage={false}
                locked={tile.locked || false}
              />
            ))}
          </TileList>
        );
        break;

      case "StrapiHTMLBlock":
        out = <HTMLBlock markup={component.markup} />;
        break;

      case "StrapiTileGrid":
        out = (
          <TileGrid
            title={component.title}
            tiles={component.tiles}
            onTileClick={onTileClick}
          />
        );
        break;

      case "Typography":
        out = (
          <TypographyComponent
            variant={component.typeVariant}
            body={component.body}
          />
        );
        break;

      case "SectionHero":
        out = <SectionHero title={component.title!} background="frequency" />;
        break;

      case "ImageHero":
        out = (
          <ImageHero
            artwork={component.artwork!}
            headline={component.headline!}
          />
        );
        break;

      case "CTA":
        out = (
          <CTA
            ctaVariant={component.ctaVariant}
            ctaType={component.ctaType}
            ctaPath={component.ctaPath}
            ctaLabel={component.ctaLabel}
          />
        );
        break;

      default:
        out = null;
        break;
    }
    return out;
  };

  return (
    <div className={`flex w-screen flex-col items-center`}>
      {stackItemContent()}
    </div>
  );
}
