import { IconButton } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";

interface Props {
  onDismiss: () => void;
}

export function FullScreenPlayerHeader({ onDismiss }: Props) {
  return (
    <nav className="fs-player-nav absolute left-0 top-0 z-20 flex w-screen items-center justify-between p-4">
      <IconButton onClick={onDismiss}>
        <ExpandMore sx={{ color: "#6DFE95", fontSize: "2.5rem" }} />
      </IconButton>
      <Link to={"/"}>
        <img src={logo} alt="" />{" "}
      </Link>
      <div className="w-[50px]"></div>
    </nav>
  );
}
