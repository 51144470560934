import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { StrapiTile } from "../../types/generated";

type TileGridProps = {
  title?: string;
  tiles: StrapiTile[];
  onTileClick: (tile: StrapiTile) => () => void;
};

export function TileGrid({
  title,
  tiles,
  onTileClick,
}: PropsWithChildren<TileGridProps>) {
  return (
    <div className="flex flex-col">
      {title && <Typography variant="h6">{title}</Typography>}
      <div className="flex w-screen flex-wrap lg:max-w-[1150px]">
        {tiles.map((t) => (
          <div
            className="mb-4 w-full cursor-pointer px-4 lg:w-1/4 lg:px-2"
            onClick={onTileClick(t)}
          >
            <img src={t.thumbnail} alt={t.title} />
            <div className="my-2 flex flex-col">
              <Typography variant="h5">{t.title}</Typography>
              <Typography variant="h6">{t.description}</Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
