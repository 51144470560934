import { Typography } from "@mui/material";
import EditIcon from "../assets/edit.svg";
import { Field } from "./types";

type Props = {
  field: Field;
};

export function InputCell({ field }: Props) {
  const { label, value, secure, disabled } = field;

  return (
    <div className={`flex items-center justify-between py-2`}>
      <div className="flex flex-col">
        <Typography variant="caption2">
          {label.replace("Current ", "")}
        </Typography>
        <Typography
          variant={secure ? "h2" : "overline2"}
          className={`${secure ? "max-h-[28px]" : ""}`}
        >
          {secure ? "********" : value}
        </Typography>
      </div>
      {!disabled && <img src={EditIcon} alt="icon" />}
    </div>
  );
}
