import { useLocation } from "react-router-dom";
import { useGetDynamicPageBySlugSuspenseQueryGenerated } from "../types/generated";
import { Stack } from "./Stack";
import { Subscribe } from "../Sales/Subscribe";

export function DynamicPage() {
  const { pathname } = useLocation();

  const { data } = useGetDynamicPageBySlugSuspenseQueryGenerated({
    variables: { slug: pathname },
  });

  return (
    <div className="flex w-full flex-col items-center">
      {data?.getDynamicPageBySlug && data.getDynamicPageBySlug.stack && (
        <div
          id={data.getDynamicPageBySlug.cssId
            ? data.getDynamicPageBySlug.cssId
            : 'dynamic-page-detail'
          }
          className="mt-8 flex w-full flex-col"
        >
          <Stack stack={data.getDynamicPageBySlug.stack} />
        </div>
      )}
      {pathname === "/subscribe" && <Subscribe />}
    </div>
  );
}
