import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Cta, CtaTypes } from "../../types/generated";

export function CTA({ ctaType, ctaVariant, ctaPath, ctaLabel }: Cta) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (ctaType === CtaTypes.link) {
      if (ctaPath.indexOf("http") !== -1) {
        window.location.href = ctaPath;
      } else {
        navigate(ctaPath);
      }
    }
  };
  console.log(ctaVariant);
  return (
    <div className="w-full px-4 lg:max-w-[900px]">
      <Button
        fullWidth
        variant={ctaVariant.valueOf() as any}
        onClick={handleClick}
      >
        {ctaLabel}
      </Button>
    </div>
  );
}
