import { Route, Navigate } from "react-router-dom";
import { LoginForm } from "./Login";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { ForgotPasswordConfirmationForm } from "./ForgotPasswordConfirm";
import { ForcedPasswordResetForm } from "./ForcedPasswordReset";
import { AccountForm } from "./AccountForm";
import { ConfirmationForm } from "./ConfirmationForm";
import { Signup } from "./Signup";
import { Auth } from ".";
import { AcceptInvite } from "./AcceptInvite";

export const useAuthRoutes = () => {
  return (
    <>
      <Route path="/auth" element={<Auth />}>
        <Route path="signin" element={<LoginForm />} />
        <Route path="forgot" element={<ForgotPasswordForm />} />
        <Route
          path="reset-password"
          element={<ForgotPasswordConfirmationForm />}
        />
        <Route path="accept-invite" element={<AcceptInvite />} />
        <Route
          path="set-new-password"
          element={<ForcedPasswordResetForm />}
        />
        <Route path="create-account" element={<Signup />}>
          <Route index element={<Navigate to="details" replace />} />
          <Route path="details" element={<AccountForm />} />
          <Route path="confirm" element={<ConfirmationForm />} />
        </Route>
      </Route>
    </>
  );
};
