import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import {Card, useGetUserCardsOnFileQueryGenerated} from "../types/generated";
import {getPaymentType} from '../utils/getPaymentType';
import {PaymentIcon} from 'react-svg-credit-card-payment-icons';
import {useEffect} from 'react';

type Props = {
  onChange: (val: string) => void,
  value:string,
}

export const PaymentSelect = ({ onChange, value }:Props) => {
  const { loading, data } = useGetUserCardsOnFileQueryGenerated();

  const handleChange = (e:SelectChangeEvent) => {
    onChange(e.target.value);
  };

  useEffect(() => {
    if(value === '') {
      if( data?.getCardsOnFile?.cards && data?.getCardsOnFile.cards.length > 0) {
        onChange(data.getCardsOnFile.cards[0].id!);
      }
    }
  }, [data, onChange, value]);

  return (
    <>
      {loading && (
        <Box className="bg-white opacity-50 w-full h-8" />
      )}
      {!loading && data && data.getCardsOnFile.cards && data.getCardsOnFile.cards.length > 0 && (
        <Select onChange={handleChange} value={value}>
          {data.getCardsOnFile.cards.map((card:Card) => (
            <MenuItem value={card.id} key={card.id}>
              <div className="flex w-full items-center justify-between">
                <PaymentIcon
                  type={getPaymentType(card.cardBrand)}
                  format="flatRounded"
                  width={40}
                />
                <Typography variant='body1' style={{ color: 'black' }}>{`**** ${card.last4}`}</Typography>
              </div>
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};
