import { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { InfoFooter } from "../ui/InfoFooter";

export interface OutletContextParams {
  recovery: boolean;
  paid: boolean;
}

export function Signup() {
  const [paid, setPaid] = useState(false);
  const [recovery, setRecovery] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams) {
      const p = searchParams.get("paid");
      const r = searchParams.get("recovery");
      if (p === "true") {
        setPaid(true);
      }
      if (r === "true") {
        setRecovery(true);
      }
    }
  }, [searchParams]);

  return (
    <div className="h-full w-full">
      <Outlet context={{ paid, recovery }} />
      <InfoFooter />
    </div>
  );
}
