import { YoutubeLink } from "../ui/YoutubeLink";

import { useGetThoughtsLinksQueryGenerated } from "../types/generated";

export function Links() {
  const {
    data: linksData,
    loading: isLoading,
    error: isError,
  } = useGetThoughtsLinksQueryGenerated();

  return (
    <div className="flex min-w-[100vw] flex-col lg:items-center">
      <div className="lg:max-w-[750px]">
        {!isLoading &&
          !isError &&
          linksData?.getThoughtsLinks.map((link, index) => (
            <YoutubeLink
              key={`${index}`}
              link={{ ...link, variant: "title-top" }}
            />
          ))}
      </div>
    </div>
  );
}
