import { useState } from "react";
import { Typography, Select, SelectChangeEvent, MenuItem, Button } from "@mui/material";
import {Link} from "react-router-dom";
import {PaymentSelect} from "../ui/PaymentSelect";

interface Props {
  submitLabel?: string;
  handleSubmit: (cardId: string, quantity: number) => void;
}

export const PurchaseKeysForm = ({ handleSubmit, submitLabel }:Props) => {
  const [cardId, setCardId] = useState('');
  const [quantity, setQuantity] = useState(50);

  const handlePayment = (id:string) => {
    console.log(id);
    setCardId(id);
  };
  
  const handleQuantity = (e:SelectChangeEvent) => {
    setQuantity(parseInt(e.target.value));
  };

  const onSubmit = () => {
    handleSubmit(cardId, quantity);
  };

  return (
    <div className="my-4 flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <Typography variant="caption2" sx={{ color: 'black' }}>Key Quantity:</Typography>
        <Select onChange={handleQuantity} value={quantity.toString()}>
          <MenuItem value="50">
            <Typography variant="h6" style={{ color: 'black' }}>50 Keys - $5.00</Typography>
          </MenuItem>
          <MenuItem value="100">
            <Typography variant="h6" style={{ color: 'black' }}>100 Keys - $10.00</Typography>
          </MenuItem>
        </Select>
      </div>
      <div className="flex flex-col gap-1">
        <Typography variant="caption2" sx={{ color: 'black' }}>Payment Method:</Typography>
        <PaymentSelect onChange={handlePayment} value={cardId} />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <Link to="/profile/accountInfo">
          <Button variant="text" sx={{ color: "#000", textDecoration: 'underline' }}>Manage Payment Methods</Button>
        </Link>
      </div>
      <Button
        variant="bassnectarDarkGold"
        onClick={onSubmit}
        fullWidth
        disabled={cardId === ""}
      >
        {submitLabel || "Complete Purchase"}
      </Button>
    </div>
  );
}
