import React from 'react';
import Vimeo from '@u-wave/react-vimeo';

type VimeoProps = {
  id: string;
};

export const VimeoPlayer = ({ id }: VimeoProps) => {
  return (
    <div className='relative'>
      <Vimeo
        video={id}
        autoplay={false}
        loop={false}
        responsive={true}
        controls={true}
      />
    </div>
  );
};
