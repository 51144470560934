import { Suspense } from "react";
import { SkeletonVariants } from "../types/generated";
import { DynamicPage } from "../ui/IndexPage";
import { Skeleton } from "../ui/Skeleton";

export default function Listen() {
  return (
    <Suspense fallback={<Skeleton variant={SkeletonVariants.index} />}>
      <DynamicPage />
    </Suspense>
  );
}
