import React, { useState } from 'react';
import { FormControl, FormHelperText, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface ChangePasswordProps {
  // Add any necessary props here
}

const ChangePassword: React.FC<ChangePasswordProps> = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleValidate = () => {
    // Add your validation logic here
    // For simplicity, let's assume the validation is successful
    setIsValid(true);
  };

  const handleChangeNewPasswords = (
    value: string,
    whichState: 'new' | 'confirm',
  ) => {
    whichState === 'new' ? setNewPassword(value) : setConfirmPassword(value);
    if (whichState === 'new') {
      setPasswordsMatch(value === confirmPassword);
    } else {
      setPasswordsMatch(newPassword === value);
    }
  };

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    // Add your logic to change the password here
    // You can access the oldPassword, newPassword, and confirmPassword states here
    // and perform the necessary password change action
  };

  if (!isValid) {
    return (
      <div className='mt-5'>
        <FormControl>
          <div className='flex'>
            <div className='max-w-[60%] flex justify-center'>
              <TextField
                placeholder='Enter Password'
                label='Enter Password'
                type={showOldPassword ? 'text' : 'password'}
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
                sx={{ marginBottom: '1rem', width: '100%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {showOldPassword ? (
                        <Visibility
                          onClick={() => setShowOldPassword(false)}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <VisibilityOff
                          onClick={() => setShowOldPassword(true)}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className='flex-grow flex justify-end items-center'>
              <Button onClick={handleValidate}>Validate</Button>
            </div>
          </div>
        </FormControl>
      </div>
    );
  }

  return (
    <FormControl>
      <div className='flex'>
        <div className='max-w-[60%] flex flex-col justify-center'>
          <TextField
            placeholder='New Password'
            label='New Password'
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={e => handleChangeNewPasswords(e.target.value, 'new')}
            sx={{ marginBottom: '1rem', width: '100%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {showNewPassword ? (
                    <Visibility
                      onClick={() => setShowNewPassword(false)}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={() => setShowNewPassword(true)}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            placeholder='Confirm Password'
            label='Confirm Password'
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={e => handleChangeNewPasswords(e.target.value, 'confirm')}
            sx={{ marginBottom: '1rem', width: '100%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {showConfirmPassword ? (
                    <Visibility
                      onClick={() => setShowConfirmPassword(false)}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={() => setShowConfirmPassword(true)}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {!passwordsMatch && (
            <FormHelperText error>Passwords do not match.</FormHelperText>
          )}
        </div>
        <div className='flex-grow flex justify-end items-center'>
          <Button
            onClick={handleChangePassword}
            variant={passwordsMatch ? 'bassnectarText' : 'text'}
            disabled={!passwordsMatch}
          >
            Change
          </Button>
        </div>
      </div>
    </FormControl>
  );
};

export default ChangePassword;
