import {PaymentTypeExtended} from 'react-svg-credit-card-payment-icons';

export const getPaymentType = (t?:string): PaymentTypeExtended => {
  switch (t) {
    case "VISA":
      return "Visa";
    case "MASTERCARD":
      return "Mastercard";
    case "AMERICAN_EXPRESS":
      return "Amex";
    case "DISCOVER":
      return "Discover";
    case "DISCOVER_DINERS":
      return "Diners";
    case "CHINA_UNIONPAY":
      return "Unionpay";
    case "JCB":
      return "Jcb";
    default:
      return "Code";
  }
}


