interface ColorStop {
  hexColor?: string | null;
  position?: number | null;
}

/*
interface GradientDTO {
  angle?: number | null;
  colors?: [ColorStop] | null;
}
 */

interface GradientProps {
  gradient?: any;
}

export const gradientString = (gradient: any) => {
  if (gradient) {
    return `
      linear-gradient(
        ${gradient.angle ? `${gradient.angle}deg,` : ""}
        ${gradient.colors?.map(
          (color: ColorStop) =>
            `${color.hexColor}${
              color.position !== undefined ? ` ${color.position}%` : ""
            }`
        )}
      )
    `;
  }
  return "none";
};

export function Gradient({ gradient }: GradientProps) {
  return (
    <div
      className="z-1 fixed left-0 top-0 h-full min-w-[100vw]"
      style={{ background: gradientString(gradient) }}
    ></div>
  );
}
