import { useState, useContext } from "react";
import { IconButton, Typography, Modal, Box } from "@mui/material";
import LoadingIcons from "react-loading-icons";
import CloseIcon from "@mui/icons-material/Close";
import { useProcessKeyPurchaseMutationGenerated } from "../types/generated";
import { AuthContext } from "../Auth/AuthProvider";
import Keys from '../assets/wallet-key.svg';
import { PurchaseKeysForm } from "./PurchaseKeysForm";

interface Props {
  open: boolean;
  onDismiss: () => void;
}

export function PurchaseKeysModal({ open, onDismiss }: Props) {
  const [loading, setLoading] = useState(false);

  const { refreshUser } = useContext(AuthContext);
  const [purchaseKeys, { error }] = useProcessKeyPurchaseMutationGenerated({
    fetchPolicy: "network-only",
  });

  const handleSubmit = async (cardId: string, quantity: number) => {
    setLoading(true);

    try {
      await purchaseKeys({
        variables: {
          input: {
            cardId,
            quantity,
          },
        },
      });

      await refreshUser(false);
      onDismiss();
    } catch (err: any) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex justify-center items-center"
    >
      <div className="flex h-full w-full lg:max-w-[760px] items-center justify-center">
        <Box className="h-{350px} bg-light-gold relative mx-6 w-full rounded-2xl p-6">
          <div className="flex items-center justify-between">
            <div className="z-0 my-4 flex items-center gap-4">
              <img src={Keys} alt="keys-icon" style={{ height: 50 }} />
              <Typography variant="h3" sx={{ color: '#c69c6d' }}>Purchase Keys</Typography>
            </div>
            <IconButton onClick={onDismiss} sx={{ color: "#000" }}>
              <CloseIcon />
            </IconButton>
          </div>
          {error && (
            <Typography variant="body1">
              Sorry we were not able to purchase your keys.
            </Typography>
          )}
          {!loading && (
            <PurchaseKeysForm handleSubmit={handleSubmit} />
          )}
          {loading && (
            <div>
              <div style={{ margin: "0 auto", width: "200px" }}>
                <LoadingIcons.Bars width="198px" />
              </div>
            </div>
          )}
        </Box>
      </div>
    </Modal>
  );
}
