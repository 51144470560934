import { Typography } from "@mui/material";
import MediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";
import AlbumCard from "../Listen/AlbumCard";

type FeaturedContentProps = {
  index: number;
  headline?: string;
  title: string;
  subtitle: string;
  artwork: string;
  detailSlug: string;
  locked?: boolean;
};

export function FeaturedContent({
  index,
  headline,
  title,
  subtitle,
  artwork,
  detailSlug,
  locked,
}: FeaturedContentProps) {
  const navigate = useNavigate();

  const handleClick = () => navigate(detailSlug);

  return (
    <div className="mb-4" onClick={handleClick} key={`stack-${index}`}>
      <MediaQuery minWidth={1024}>
        <div className="flex w-screen justify-center bg-[#00000080]">
          <div className="flex max-w-[1150px] items-center px-2 py-8">
            <div className="w-1/3">
              <img src={artwork} alt={title} />
            </div>
            <div className="flex w-2/3 flex-col px-4">
              {headline && <Typography variant="h2">{headline}</Typography>}
              {headline && title && headline !== title && (
                <Typography variant="h4">{title}</Typography>
              )}
              <Typography variant="h5">{subtitle}</Typography>
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1023}>
        <div className="flex w-screen flex-col gap-2 bg-[#00000080] px-4 py-6">
          {headline && <Typography variant="h4">{headline}</Typography>}
          <AlbumCard
            id={index}
            artwork={artwork ?? undefined}
            title={title ?? ""}
            info={subtitle ?? undefined}
            variant="large"
            playAllButton={false}
            playAllAction={() => {}}
            hideImage={false}
            locked={locked}
          />
        </div>
      </MediaQuery>
    </div>
  );
}
