import { IconButton, Typography, Modal, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  reason?: string;
  onDismiss: () => void;
}

export function ReliveModal({ open, reason, onDismiss }: Props) {
  const navigate = useNavigate();

  const about = () => navigate("/about");
  const join = () => navigate("/join");

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="flex h-full w-full items-center justify-center"
        onClick={onDismiss}
      >
        <Box className="h-{350px} round-4 mx-6 w-fit bg-black p-6">
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Unlocked membership required
              </Typography>
              <IconButton onClick={onDismiss} sx={{ color: "#fff" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography
              variant="body1"
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              In order to experience TOS Live Events, Youll need an unlocked
              account.
            </Typography>
            <div className="mt-[12px] text-center">
              <Link to="/auth/create-account" state={{ paid: true }}>
                <Button variant="bassnectar" style={{ width: "100%" }}>
                  Unlock The Other Side
                </Button>
              </Link>
            </div>
            <Button
              variant="bassnectarText"
              onClick={about}
              style={{ width: "100%" }}
            >
              Membership Details
            </Button>
          </div>
        </Box>
      </div>
    </Modal>
  );
}
