import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../Auth/AuthProvider";

export const ProtectedRoutes = () => {
  const { loggedIn, authenticating } = useContext(AuthContext);
  return !authenticating && !loggedIn ? (
    <Navigate to="/auth/signin" />
  ) : (
    <Outlet />
  );
};
