import { IconButton, Typography, Modal, Box, Checkbox, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingIcons from "react-loading-icons";
import {Card, GetUserCardsOnFileDocumentGenerated, useDisablePaymentMethodMutationGenerated} from "../types/generated";
import {useState} from "react";
import {PaymentIcon} from "react-svg-credit-card-payment-icons";
import {getPaymentType} from "../utils/getPaymentType";

interface Props {
  open: boolean;
  card?: Card;
  onDismiss: () => void;
}

export function DisableCardModal({ open, card, onDismiss }: Props) {
  const [agree, setAgree] = useState(false);
  const [disableCard, {loading, error}] = useDisablePaymentMethodMutationGenerated();

  const handleAgree = () => setAgree(!agree);

  const disable = async () => {
    try {
      await disableCard({
        variables: {
          cardId: card?.id!,
        },
        refetchQueries: [{ query: GetUserCardsOnFileDocumentGenerated }],
      })
      setAgree(false);
      onDismiss();
    } catch (err: any) {
    }
  };

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex flex-col h-full w-full items-center justify-center">
        <Box className="h-{350px} tos-body-bkg relative mx-6 w-fit rounded-2xl p-6">
          <IconButton
            onClick={onDismiss}
            sx={{
              color: "#67F99B",
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 999,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="z-0 mt-12 flex flex-col gap-4 items-center justify-center">
            <Typography id="modal-modal-title" variant="h5">
              Disable Payment Method
            </Typography>

            {loading && (
              <div>
                <div style={{ margin: "0 auto", width: "200px" }}>
                  <LoadingIcons.Bars width="198px" />
                </div>
              </div>
            )}
            {!loading && (
              <>
                <Typography variant="body2">
                  If you disable this payment method, it will no longer be able to be used 
                  to renew your subscription or purchaase keys. If you have no payment 
                  methods on file, your access will be removed at the end of your
                  subscription term.
                </Typography>

                <>
                  {card && (
                    <div className="flex gap-8 items-center">
                      <PaymentIcon
                        type={getPaymentType(card.cardBrand)}
                        format="flatRounded"
                        width={60}
                      />
                      <Typography variant="h5">{`**** ${card.last4}`}</Typography>
                      <Typography variant="h5">
                        {`${card.expMonth}/${card.expYear}`}
                      </Typography>
                    </div>
                  )}
                </>

                <div className="flex items-center justify-start gap-1">
                  <Checkbox checked={agree} onChange={handleAgree} />
                  <Typography variant="body1">
                    I understand, please disable this card.
                  </Typography>
                </div>
                <Button disabled={!agree} variant="bassnectar" onClick={disable}>
                  Disable Payment
                </Button>
              </>
            )}
          </div>

          <div className="my-4 mt-12 flex items-center justify-center">
            {error && (
              <Typography variant="body1">
                Sorry we were not able to disable your card at this time..
              </Typography>
            )}
          </div>
        </Box>
      </div>
    </Modal>
  );
}


