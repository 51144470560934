import TermsConditions from "../Legal/TermsConditions";
import PrivacyPolicy from "../Legal/PrivacyPolicy";
import { Route } from "react-router-dom";

export function useLegalRoutes() {
  return (
    <>
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </>
  );
}
