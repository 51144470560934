import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.svg";
import { BackButton } from "../ui/BackButton";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";

interface props {
  minimal?: boolean;
}

const hideLogoPatterns = [
  /^\/artists\/\d+$/g,
  /^\/igf\/\d+$/g,
  /^\/terms-conditions$/g,
  /^\/privacy-policy$/g,
];

interface Props extends props {
  minimal?: boolean;
}

const TopNavigation = React.memo(({ minimal }: Props) => {
  const [canGoBack, setCanGoBack] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);

  const { authenticating, loggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setCanGoBack(window.history.state && window.history.state.idx !== 0);
    setHideLogo(hideLogoPatterns.some((p) => p.test(pathname)));
  }, [pathname]);

  const eventBack = async () => {
    navigate(-1);
  };

  return (
    <nav className="top-navigation relative z-[999] min-h-[65px] md:min-h-[90px]">
      <div className="px-[26px] pb-[20px] pt-[15px]">
        <div className="relative flex min-h-[60px] w-full items-center justify-center">
          <>
            {!minimal && (
              <>
                {loggedIn &&
                  window.history.state &&
                  window.history.state.idx > 0 && (
                    <div className="navigation-back absolute left-0">
                      {canGoBack ? <BackButton onClick={eventBack} /> : null}
                    </div>
                  )}
                {!loggedIn && !authenticating && (
                  <Link
                    to={"/join"}
                    className="navigation-back absolute left-0 block"
                  >
                    <Chip
                      label="JOIN"
                      style={{
                        background:
                          "linear-gradient(180deg, #6DFE95 0%, #6DFE95 50%, #1CF9D7 100%)",
                        fontWeight: 700,
                      }}
                    />
                  </Link>
                )}
              </>
            )}

            {(!loggedIn || !hideLogo) && (
              <div className="navigation-logo">
                <Link to={loggedIn ? "/" : "/home"}>
                  <img src={logo} alt="" />
                </Link>
              </div>
            )}

            {!minimal && (
              <div className="absolute right-0 flex items-center">
                {!loggedIn && (
                  <Link to="/auth/signin">
                    <Chip
                      label="SIGN IN"
                      style={{
                        background:
                          "linear-gradient(180deg, #6DFE95 0%, #6DFE95 50%, #1CF9D7 100%)",
                        fontWeight: 700,
                      }}
                    />
                  </Link>
                )}
              </div>
            )}
          </>
        </div>
      </div>
    </nav>
  );
});

export default TopNavigation;
