import { Support } from "../Support";
import { FAQ } from "../Support/FAQ";
import { Help as SupportHelp } from "../Support/Help";
import { Route, Navigate } from "react-router-dom";

export function useSupportRoutes() {
  return (
    <>
      {/* <Route path="/support" element={<Support />}>
        <Route index element={<Navigate to="help" replace />} />
        <Route path="help" element={<SupportHelp />} />
        <Route path="faq" element={<FAQ />} />
      </Route> */}
    </>
  );
}
