type Pagination = {
  start: number;
  limit: number;
  total: number;
};

type PaginatedResult = {
  results: any[];
  pagination: Pagination;
};

export function mergePaginated(
  existing: PaginatedResult,
  incoming: PaginatedResult
) {
  let out;
  if (existing) {
    const ids = existing.results.map((r: any) => r.__ref);
    const newResults = incoming.results.filter(
      (r: any) => !ids.includes(r.__ref)
    );
    const nextResults = [...existing.results, ...newResults];
    out = {
      ...existing,
      results: nextResults,
      pagination: incoming.pagination,
    };
  } else {
    out = incoming;
  }
  return out;
}
