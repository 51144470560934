import env from "react-dotenv";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useGetTixrPrivateUrlLazyQueryGenerated } from "../types/generated";
import { User, getPictureUrl } from "../types/User";

const restApiUrl = env.API_URL;

const restApi = axios.create({
  baseURL: restApiUrl,
  withCredentials: true,
});

export const usePicture = (user: User | null): string | null => {
  const [picture, setPicture] = useState<string | null>(null);

  useEffect(() => {
    const getPicture = async () => {
      if (user && user.picture) {
        try {
          let pictureUrl = await getPictureUrl(user);
          setPicture(pictureUrl);
        } catch (e) {}
      }
    };
    getPicture();
  }, [user]);

  return picture;
};

export const useTixrRedirect = () => {
  const [getUrl] = useGetTixrPrivateUrlLazyQueryGenerated();

  const callback = useCallback(async () => {
    const response = await getUrl();
    return response?.data?.getTixrPrivateUrl;
  }, [getUrl]);

  return callback;
};

export const useGetSignedCookies = () => {
  const callback = useCallback(async (token: string) => {
    if (token) {
      await restApi.get("/cookies", {
        headers: {
          authorization: token,
        },
      });
    } else {
      throw new Error("Please log in again!");
    }
  }, []);
  return callback;
};
