import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth/AuthProvider";
import { SubscriptionModal } from "../Sales/SubscriptionModal";
import { userIsUnlocked } from "../Auth/utils";
import { StackItem } from "./StackItem";
import { Tile } from "./StackTypes";
import { StrapiDynamicPageStack } from "../types/generated";

type StackProps = {
  stack: StrapiDynamicPageStack[];
};

export function Stack({ stack }: StackProps) {
  const { user } = useContext(AuthContext);

  const [modalCondition, setModalCondition] = useState<string | undefined>(
    undefined
  );

  const navigate = useNavigate();

  const navigateToSlug = (slug: string) => navigate(slug);

  const handleNavigate = (slug?: string) => () => {
    if (!slug) return;
    if (slug.includes("http")) {
      window.location.href = slug;
    } else {
      navigateToSlug(slug);
    }
  };

  const handleTileClick = (tile: Tile) => () => {
    if (!tile.clicksThrough || !tile.slug) {
      return;
    }

    const isPaid = userIsUnlocked(user);
    const isLocked = tile.locked ?? false;
    if (isLocked && !isPaid) {
      setModalCondition("locked");
    } else {
      navigateToSlug(tile.slug);
    }
  };

  return (
    <>
      <SubscriptionModal
        open={modalCondition !== undefined}
        reason={modalCondition}
        onDismiss={() => setModalCondition(undefined)}
      />
      <div className="mb-8 flex flex-col gap-8">
        {stack.map((component, index) => (
          <StackItem
            key={index}
            component={component}
            index={index}
            onNavigate={handleNavigate}
            onTileClick={handleTileClick}
          />
        ))}
      </div>
    </>
  );
}
