import { Button, IconButton, TextField, Typography } from "@mui/material";
import { ChangeEvent, useContext, useReducer, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { object, string, ValidationError } from "yup";
import { SectionHero } from "../ui/components/SectionHero";
import { AuthContext } from "./AuthProvider";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ErrorToast } from "./ErrorToast";

const loginSchema = object({
  username: string().required(),
  password: string().min(8).required("Password is required"),
});

export interface LoginFields {
  username: string;
  password: string;
}

interface Action {
  key: string;
  value: string | boolean;
}

export function LoginForm() {
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [showPassword, setShowPassword] = useState(false); // State to track password visibility

  const { state:navState } = useLocation();
  const { from, username } = navState || { from: { pathname: "/" } };

  const reducer = (state: LoginFields, action: Action) => ({
    ...state,
    [action.key]: action.value,
  });

  const { signIn, loggedIn } = useContext(AuthContext);

  const [state, dispatch] = useReducer(reducer, {
    username: username || "",
    password: "",
  });

  const handleInput =
    (key: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch({ key, value: e.target.value });
    };

  const handleSubmit = async () => {
    try {
      await loginSchema.validate(state, {
        abortEarly: false,
      });

      await signIn(state.username, state.password);
    } catch (err) {
      const errors: Record<string, string[]> = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((e: ValidationError) => {
          if (errors[e.path!]?.length > 0) {
            errors[e.path!].push(e.message);
          } else {
            errors[e.path!] = [e.message];
          }
        });
        setErrors(errors);
      } else {
        console.log(err);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div id="login-page" className="flex flex-col items-center">
      {loggedIn && <Navigate to="/" />}
      <SectionHero title="Sign In" />
      <div className="flex flex-col items-center px-6">
        <ErrorToast />
        {from === 'auth/set-new-password' && (
          <div className="flex flex-col gap-2 my-4 text-center">
            <Typography variant="h5">Your password has been set!</Typography>
            <Typography variant="h6">Please sign in with your new password.</Typography>
          </div>
        )}
        <div className="mb-4 flex w-full flex-col gap-2">
          <TextField
            fullWidth
            variant="filled"
            onChange={handleInput("username")}
            label="User Name"
            value={state.username}
            error={errors.username?.length > 0}
          />
          <TextField
            variant="filled"
            fullWidth
            type={showPassword ? "text" : "password"}
            onChange={handleInput("password")}
            label="Password"
            value={state.password}
            error={errors.password?.length > 0}
            InputProps={{
              endAdornment: (
                <div className="bg-transparent">
                  <IconButton
                    onClick={togglePasswordVisibility}
                    sx={{
                      bgcolor: "transparent", // TODO Set background color to transparent
                      // Remove padding
                      "&:hover": {
                        bgcolor: "transparent", // TODO Set background color to transparent on hover
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: "1rem",
                      },
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              ),
            }}
          />
          <div className="w-full text-right">
            <Link to="/auth/forgot">
              <Typography variant="h6" className="mb-4">
                Forgot Password?
              </Typography>
            </Link>
          </div>
        </div>
        <Button variant="bassnectarJumbo" onClick={handleSubmit} fullWidth>
          Sign In
        </Button>
        {/* <hr className="mx-auto my-4 h-[1px] w-48 rounded border-0 bg-gray-100 dark:bg-gray-700 md:my-10"></hr> */}
        {/* <div className="mb-6 mt-[40px] flex flex-col gap-4 px-6 text-center">
            <Link to="/auth/create-account">
              <Typography variant="h6">
                If you are a legacy TOS user that needs to upgrade to the latest
                version version
              </Typography>
              <Link to="/auth/create-account">
                <Button variant="bassnectarText">Upgrade Here</Button>
              </Link>
            </Link>
          </div> */}
        <hr className="mx-auto my-4 h-[1px] w-48 rounded border-0 bg-gray-100 dark:bg-gray-700 md:my-10"></hr>
        <div className="mb-8 mt-8 flex flex-col text-center">
          <Typography variant="h6">
            If you haven't created an account yet, or you need to upgrade a
            legacy TOS account
          </Typography>
          <Link to="/auth/create-account">
            <Button variant="bassnectarText">Sign Up Here</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
