import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { userIsUnlocked } from "../Auth/utils";
import { StrapiEvent } from "../types/generated";

interface Props {
  event?: StrapiEvent;
}

export const EventPrivacy = ({ event }: Props) => {
  const { loggedIn, authenticating, user } = useContext(AuthContext);

  if (
    !authenticating &&
    !loggedIn &&
    (event?.eventPrivacy == "FREESTYLE" || event?.eventPrivacy == "UNLOCKED")
  ) {
    return <Navigate to="/auth/signin" />;
  } else if (
    !authenticating &&
    loggedIn &&
    !userIsUnlocked(user) &&
    event?.eventPrivacy == "UNLOCKED"
  ) {
    return <Navigate to="/subscribe" replace />;
  } else {
    return null;
  }
};
