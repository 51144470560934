import env from "react-dotenv";
import { Typography } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { ReliveModal } from "./ReliveModal";
import { LiveEventContext } from "../Events/LiveEventProvider";
import { StrapiEventType } from "../types/generated";
import { userIsUnlocked } from "../Auth/utils";

export function EventBanner() {
  const [modalCondition, setModalCondition] = useState<string | undefined>(
    undefined
  );

  const { eventStarted, doorsOpen, countdownDisplay, event } =
    useContext(LiveEventContext);

  const { user } = useContext(AuthContext);

  const handleClick = () => {
    if (user && userIsUnlocked(user)) {
      if (doorsOpen) {
        window.location.assign(`/events/${event?.id}`);
      }
    } else if (user && !userIsUnlocked(user)) {
      setModalCondition("locked");
    } else {
      setModalCondition("guest");
    }
  };

  const handleClose = () => setModalCondition(undefined);

  const pointerClass = doorsOpen ? "cursor-pointer" : "";

  return (
    <>
      <ReliveModal
        open={modalCondition !== undefined}
        reason={modalCondition}
        onDismiss={handleClose}
      />
      <div
        className={`${pointerClass} relative flex min-w-[100vw] items-center bg-black px-4 py-2 md:min-w-auto`}
        onClick={handleClick}
      >
        <img
          src={event?.badge}
          alt={event?.name}
          className="absolute left-4 h-[50px] w-[50px]"
        />
        <div className="flex w-full flex-col text-center">
          <Typography variant="h6">{`${
            event?.liveData?.name ??
            event?.name ??
            (event?.type == StrapiEventType.MIXTAPE ? "Live Mixtape" : "Relive")
          } Begins In`}</Typography>
          <Typography variant="subheading">
            {eventStarted ? "Live Now" : countdownDisplay}
          </Typography>
          {doorsOpen && (
            <Typography variant="body1">Click here to enter</Typography>
          )}
        </div>
      </div>
    </>
  );
}
