import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface EditFieldProps {
  title: string;
  item: string | undefined;
  inputRef: React.RefObject<HTMLInputElement | null>;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
}

const EditField: React.FC<EditFieldProps> = ({
  title,
  item,
  inputRef,
  state,
  setState,
}) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (active) {
      inputRef.current?.focus();
    }
  }, [active, inputRef]);

  return (
    <div className='flex w-full items-center'>
      <div className='text-xs'>{title}:</div>
      {active ? (
        <TextField
          inputRef={inputRef}
          value={state}
          onChange={e => setState(e.target.value)}
          size='small'
          required
        />
      ) : (
        <div className='ml-2 text-base'>{item}</div>
      )}
      <div className='flex-grow'></div>
      <div className='flex justify-end'>
        {active ? (
          <Button variant='bassnectarText' onClick={() => setActive(false)}>
            Cancel
          </Button>
        ) : (
          <Button variant='bassnectarText' onClick={() => setActive(true)}>
            Edit
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditField;
