import LoadingIcons from "react-loading-icons";
import {Card, useGetUserCardsOnFileQueryGenerated} from "../../types/generated";
import {Button, Typography} from "@mui/material";
import {PaymentIcon} from 'react-svg-credit-card-payment-icons';
import {Close} from "@mui/icons-material";
import {AddCardModal} from "../AddCardModal";
import {useState} from "react";
import {DisableCardModal} from "../DisableCardModal";
import {getPaymentType} from "../../utils/getPaymentType";

export const PaymentInfoPage = () => {
  const [card, setCard] = useState<Card | undefined>(undefined);
  const [addCardOpen, setAddCardOpen] = useState(false);
  const [disableCardOpen, setDisableCardOpen] = useState(false);

  const { loading, error, data } = useGetUserCardsOnFileQueryGenerated();

  const showDeleteCardDialog = (card:Card) => () => {
    setCard(card);
    setDisableCardOpen(true)
  };
  const showAddCardDialog = () => { setAddCardOpen(true) };

  return (
    <div className="flex flex-col pb-4">
      <AddCardModal 
        open={addCardOpen}
        onDismiss={() => setAddCardOpen(false)}
      />
      <DisableCardModal
        open={disableCardOpen}
        card={card}
        onDismiss={() => setDisableCardOpen(false)}
      />
      {loading && (
        <div className="flex h-full items-center justify-center">
          <LoadingIcons.Bars width="200px" />
        </div>
      )}
      {data && data.getCardsOnFile.cards && (
        <div className="flex flex-col gap-4 my-4">
          <>
            {(error || (data.getCardsOnFile.cards && data.getCardsOnFile.cards.length === 0)) && (
              <Typography variant="body1">
                No Card Info on File. Please Add A Payment Method.
              </Typography>
            )}
            {data.getCardsOnFile.cards.map((card:Card) => (
              <div className="flex items-center justify-between">
                <div className="flex gap-8 items-center">
                  <PaymentIcon type={getPaymentType(card.cardBrand)} format="flatRounded" width={60} />
                  <Typography variant="h5">{`**** ${card.last4}`}</Typography>
                  <Typography variant="h5">
                    {`${card.expMonth}/${card.expYear}`}
                  </Typography>
                </div>
                
                <div onClick={showDeleteCardDialog(card)} className="cursor-pointer">
                  <Close style={{ color: "#6DFE95" }} />
                </div>
              </div>
            ))}
          </>
          <Button variant="bassnectarText" onClick={showAddCardDialog}>Add a Card</Button>
        </div>
      )}
    </div>
  );
};
