import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { SectionHero } from "../ui/components/SectionHero";
import { AuthContext } from "../Auth/AuthProvider";
import { InfoFooter } from "../ui/InfoFooter";
import { useNavigate } from "react-router-dom";
import { ErrorToast } from "../Auth/ErrorToast";

const UpdateCognitoField = () => {
  //This object is passed via the Link component in AccountInfo.tsx
  //If we enable deep links this will need to be updated to re-create the field from url params.
  const {
    state: {
      field: { label, id, value: fieldValue, secure, disabled },
    },
  } = useLocation();

  const navigate = useNavigate();

  const { updateUser, changePassword } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState<string | undefined>(undefined);

  const [value, setValue] = useState(fieldValue);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleUpdateField = async () => {
    let update = {
      [id]: id === "email" ? value.toLowerCase() : value,
    };

    let result = await updateUser(update);

    if (result) {
      if (id === "email") {
        navigate("/profile/verify", { state: { key: id } });
      } else {
        navigate("/profile/accountInfo", { replace: true });
      }
    }
  };

  const handleUpdatePassword = async () => {
    if (newPassword === confirmPassword) {
      const result = await changePassword({
        newPassword,
        oldPassword: value,
      });
      if (result) {
        navigate("/profile/accountInfo");
      }
    } else {
      setError("Passwords do not match");
    }
  };

  const handleSubmit = async () => {
    setError(undefined);
    if (fieldValue !== value) {
      if (secure) {
        handleUpdatePassword();
      } else {
        handleUpdateField();
      }
    }
  };

  const handleCancel = () => {
    setValue(fieldValue);
    setNewPassword("");
    setConfirmPassword("");
    navigate(-1);
  };

  const visibilityAdornment = secure ? (
    <InputAdornment position="end" style={{ color: "#ffffff" }}>
      {showPassword ? (
        <Visibility
          onClick={() => setShowPassword(false)}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <VisibilityOff
          onClick={() => setShowPassword(true)}
          style={{ cursor: "pointer" }}
        />
      )}
    </InputAdornment>
  ) : undefined;

  const inputLabelProps = {
    style: { color: "#ffffff80", fontSize: "1rem" },
  };

  const inputProps = {
    disableUnderline: true,
    style: { backgroundColor: "#ffffff20", color: "#ffffff" },
    endAdornment: visibilityAdornment,
  };

  return (
    <>
      <SectionHero title={label} />
      <ErrorToast />
      <div className="flex min-w-[100vw] flex-col lg:items-center">
        <div className="flex flex-col items-center justify-center px-6 lg:max-w-[750px]">
          <div className="mb-2 mt-4 flex w-full items-center justify-start">
            <Typography variant="h5">{`Edit ${label}`}</Typography>
          </div>
          <TextField
            type={secure && !showPassword ? "password" : "text"}
            fullWidth
            autoFocus
            focused={true}
            variant="filled"
            spellCheck={false}
            label={label}
            value={value}
            disabled={disabled}
            placeholder={secure ? "Current Password" : undefined}
            onChange={(e) => setValue(e.target.value)}
            InputProps={inputProps}
            InputLabelProps={inputLabelProps}
          />
          {secure && (
            <>
              <div className="mt-2 w-full">
                <TextField
                  type={secure && !showPassword ? "password" : "text"}
                  fullWidth
                  focused={true}
                  variant="filled"
                  spellCheck={false}
                  label={"New Password"}
                  value={newPassword}
                  disabled={disabled}
                  placeholder={"New Password"}
                  onChange={(e) => setNewPassword(e.target.value)}
                  InputProps={inputProps}
                  InputLabelProps={inputLabelProps}
                />
              </div>
              <div className="mt-2 w-full">
                <TextField
                  type={secure && !showPassword ? "password" : "text"}
                  fullWidth
                  focused={true}
                  variant="filled"
                  spellCheck={false}
                  label={"Confirm Password"}
                  value={confirmPassword}
                  disabled={disabled}
                  placeholder={"Confirm Password"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={inputProps}
                  InputLabelProps={inputLabelProps}
                />
              </div>
            </>
          )}
          <div className="mt-12 flex w-full items-center justify-center space-x-6">
            <Button variant="bassnectar" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="bassnectar" onClick={handleSubmit}>
              Save
            </Button>
          </div>
          <ErrorToast error={error} />
          <InfoFooter />
        </div>
      </div>
    </>
  );
};

/* <EditableField disabled label="Password" value={""} secure>
    <div className="flex w-full flex-col gap-2">
      <TextField
        type={showPassword ? "text" : "password"}
        fullWidth
        variant="filled"
        label="Old Password"
        onChange={handlePassInput("oldPassword")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {showPassword ? (
                <Visibility
                  onClick={() => setShowPassword(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <VisibilityOff
                  onClick={() => setShowPassword(true)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        type={showPassword ? "text" : "password"}
        fullWidth
        variant="filled"
        label="New Password"
        onChange={handlePassInput("newPassword")}
      />
      <TextField
        type={showPassword ? "text" : "password"}
        fullWidth
        variant="filled"
        label="Confirm Password"
        onChange={handlePassInput("confirmPassword")}
      />
    </div>
  </EditableField> */

export default UpdateCognitoField;
