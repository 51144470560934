import { useContext, useEffect, useState } from "react";
import { SectionHero } from "../ui/components/SectionHero";
import { Typography } from "@mui/material";
import { AuthContext } from "../Auth/AuthProvider";
import { InfoFooter } from "../ui/InfoFooter";
import { Divider } from "../ui/Divider";
import { InputCell } from "../ui/InputCell";
import { Link } from "react-router-dom";
import { Field } from "../ui/types";
import { SubscriptionPage } from "./subscription/SubscriptionPage";
import {PaymentInfoPage} from "./subscription/PaymentInfoPage";
import {userIsAGuest, userIsVip} from "../Auth/utils";

const AccountInfo = () => {
  const { user } = useContext(AuthContext);

  const [fields, setFields] = useState<Field[]>([]);

  useEffect(() => {
    const buildFields = (): Field[] => [
      {
        label: "Username",
        value: user?.username ?? "",
        id: "preferred_username",
      },
      {
        label: "E-Mail",
        value: user?.email ?? "",
        id: "email",
      },
      {
        label: "Current Password",
        value: "",
        id: "oldpassword",
        secure: true,
      },
    ];

    setFields(buildFields());
  }, [user]);

  return (
    <div id="account-info-page">
      <SectionHero title="Account" />
      <div className="flex min-w-[100vw] flex-col lg:items-center">
        <div className="flex flex-col items-center justify-center px-6 lg:max-w-[750px]">
          <div className="mb-2 mt-4 flex w-full items-center justify-start">
            <Typography variant="h5">My Account Information</Typography>
          </div>
          <div className="w-full">
            <Divider />

            {fields.map((field, index) => (
              <Link
                key={`${index}`}
                to={"/profile/accountInfo/update"}
                state={{ field }}
              >
                <div className="w-full">
                  <InputCell field={field} />
                  <Divider />
                </div>
              </Link>
            ))}
          </div>

          {!userIsVip(user) && !userIsAGuest(user) && (
            <>
              <div className="mb-2 mt-4 flex w-full flex-col justify-start">
                <Typography variant="h5">My Subscription Information</Typography>
                <SubscriptionPage />
                <Divider />
              </div>
              <div className="mb-2 mt-4 flex w-full flex-col justify-start">
                <Typography variant="h5">My Payment Information</Typography>
                <PaymentInfoPage />
                <Divider />
              </div>
            </> 
          )}

          <InfoFooter />
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
