import { ButtonProps, IconButton } from "@mui/material";

export function BackButton({ onClick }: Partial<ButtonProps>) {
  return (
    <IconButton onClick={onClick}>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={1} x2={0} y2={1}>
          <stop offset={0} stopColor="#6DFE95" />
          <stop offset={1} stopColor="#1CF9D7" />
        </linearGradient>
      </svg>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 12.375L5.26625 12.375L12.9525 20.0613L11 22L1.9233e-06 11L11 1.9233e-06L12.9388 1.93875L5.26625 9.625L22 9.625L22 12.375Z"
          fill="url(#linearColors)"
        />
      </svg>
    </IconButton>
  );
}
