import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

type InputValue = {
  [key: string]: string;
};

const Settings = () => {
  const [inputValue, setInputValue] = useState<InputValue>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveChanges = () => {
    // TODO: Save changes to backend
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 pt-16">
      <h1 className="my-4 text-xl font-bold text-white">My profile</h1>

      <Avatar />

      <div className="mb-6 mt-4 flex w-full flex-col">
        <label htmlFor="username" className="text-2xl font-bold text-white">
          Change username
        </label>
        <input
          id="username"
          name="username"
          className="bg-grey mt-3 rounded-md border border-white bg-black py-4 px-4 text-white"
          placeholder="Username"
          value={inputValue.username ?? ""}
          onChange={handleChange}
        />
      </div>

      <div className="mb-6 flex w-full flex-col">
        <label htmlFor="email" className="text-2xl font-bold text-white">
          Change email
        </label>
        <input
          id="email"
          name="email"
          type="email"
          className="bg-grey mt-3 rounded-md border border-white bg-black py-4 px-4 text-white"
          placeholder="Email Address"
          value={inputValue.email ?? ""}
          onChange={handleChange}
        />
      </div>

      <div className="mb-6 flex w-full flex-col">
        <label htmlFor="password" className="text-2xl font-bold text-white">
          Change password
        </label>
        <input
          id="password"
          name="password"
          type="password"
          className="bg-grey mt-3 rounded-md border border-white bg-black py-4 px-4 text-white"
          placeholder="Change password"
          value={inputValue.password ?? ""}
          onChange={handleChange}
        />
        <input
          id="confirm-password"
          name="confirmPassword"
          type="password"
          className="bg-grey mt-3 rounded-md border border-white bg-black py-4 px-4 text-white"
          placeholder="Confirm password"
          value={inputValue.confirmPassword ?? ""}
          onChange={handleChange}
        />
      </div>

      <div className="flex w-full flex-col">
        <label
          htmlFor="street-address"
          className="text-2xl font-bold text-white"
        >
          Billing address
        </label>
        <input
          id="street-address"
          name="streetAddress"
          type="text"
          className="bg-grey mt-3 rounded-md border border-white bg-black py-4 px-4 text-white"
          placeholder="Street address"
          value={inputValue.streetAddress ?? ""}
          onChange={handleChange}
        />
        <input
          id="unit"
          name="unit"
          type="text"
          className="bg-grey mt-3 rounded-md border border-white bg-black py-4 px-4 text-white"
          placeholder="Unit"
          value={inputValue.unit ?? ""}
          onChange={handleChange}
        />
        <input
          id="city"
          name="city"
          type="text"
          className="bg-grey mt-3 rounded-md border border-white bg-black py-4 px-4 text-white"
          placeholder="City"
          value={inputValue.city ?? ""}
          onChange={handleChange}
        />
        <div className="flex xl:w-1/2">
          <input
            id="zip"
            name="zip"
            type="text"
            className="bg-grey mt-3 mr-3 w-full rounded-md border border-white bg-black py-4 px-4 text-white"
            placeholder="Zip"
            value={inputValue.zip ?? ""}
            onChange={handleChange}
          />
          <input
            id="state"
            name="state"
            type="text"
            className="bg-grey mt-3 w-full rounded-md border border-white bg-black py-4 px-4 text-white"
            placeholder="State"
            value={inputValue.state ?? ""}
            onChange={handleChange}
          />
        </div>
        <span className="mt-5 w-full text-gray-400">
          View my billing history{" "}
          <Link to="" className="underline">
            <span>here</span>
          </Link>
        </span>
      </div>

      <div className="mb-6 mt-4 flex w-full flex-col">
        <label htmlFor="username" className="text-2xl font-bold text-white">
          My plan
        </label>
        <Link
          to="/profile/plan"
          className="bg-grey mt-3 flex items-center justify-between rounded-md border border-white bg-black py-4 px-4 text-white"
        >
          <span>UNLOCKED</span>
          <FaArrowRight />
        </Link>
      </div>

      <div className="mb-6 mt-3 flex w-full flex-col">
        <span className="mt-2 w-full text-gray-400">
          Update my connected accounts{" "}
          <Link to="" className="underline">
            <span>here</span>
          </Link>
        </span>
        <span className="mt-2 w-full text-gray-400">
          Update my notifications{" "}
          <Link to="" className="underline">
            <span>here</span>
          </Link>
        </span>
      </div>

      <button
        className="mt-6 mb-36 flex w-full items-center justify-center rounded-md bg-gray-300 py-2 px-4 text-center font-bold uppercase text-gray-700"
        onClick={handleSaveChanges}
      >
        Save changes
      </button>
    </div>
  );
};

export default Settings;
