import { Route } from "react-router-dom";
import ArtistDetails from "../Artists/Details";
import { Suspense } from "react";
import { Skeleton } from "../ui/Skeleton";
import { DynamicPage } from "../ui/IndexPage";
import { SkeletonVariants } from "../types/generated";

export function useArtistRoutes() {
  return (
    <>
      <Route
        path="/artists"
        element={
          <Suspense
            fallback={<Skeleton variant={SkeletonVariants.indexWithGrid} />}
          >
            <DynamicPage />
          </Suspense>
        }
      />
      <Route
        path="/artists/:artistId"
        element={
          <Suspense fallback={<Skeleton variant={SkeletonVariants.artists} />}>
            <ArtistDetails />
          </Suspense>
        }
      />
    </>
  );
}
