import { createContext, PropsWithChildren, RefObject } from "react";

type DimensionProviderProps = {
  headerHeight: number;
  footerHeight: number;
  observerRoot: HTMLDivElement | null;
};

export const DimensionContext = createContext<DimensionProviderProps>({
  headerHeight: 0,
  footerHeight: 0,
  observerRoot: null,
});

export function DimensionProvider({
  headerHeight,
  footerHeight,
  observerRoot,
  children,
}: PropsWithChildren<DimensionProviderProps>) {
  return (
    <DimensionContext.Provider
      value={{ headerHeight, footerHeight, observerRoot }}
    >
      {children}
    </DimensionContext.Provider>
  );
}
