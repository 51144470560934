import { Typography, Checkbox, Button } from "@mui/material";
import { useContext, useState } from "react";
import ReactCodeInput from "react-code-input";
import { Navigate, useLocation } from "react-router-dom";
import { SectionHero } from "../ui/components/SectionHero";
import { AuthContext } from "./AuthProvider";
import { ErrorToast } from "./ErrorToast";

export function ConfirmationForm() {
  const [checked, setChecked] = useState(false);
  const [code, setCode] = useState("");
  const [success, setSuccess] = useState(false);

  const { confirmSignUp, user, tempUsername } =
    useContext(AuthContext);

  const location = useLocation();

  let locationState;

  if (location.state) {
    locationState = location.state;
  }

  const handleCheck = () => setChecked(!checked);

  const handleCode = (value: string) => {
    setCode(value);
  };

  const handleSubmit = async () => {
    console.log('Submitting');
    if (code.length === 6 && (user !== null || tempUsername !== null)) {
      const name = user !== null ? user.username : tempUsername!;
      const success = await confirmSignUp(name, code);
      setSuccess(success);
    }
  };

  return (
    <div className="flex flex-col lg:items-center">
      {success && (
        <>
          {locationState?.paid ? (
            <Navigate to="/subscribe" />
          ) : (
            <Navigate to="/" />
          )}
        </>
      )}
      <SectionHero title="Verification" />
      <div className="lg:max-w-[750px]">
        <ErrorToast />
        <div className="flex flex-col px-6">
          <Typography variant="h6">New Device Sign In</Typography>
          <Typography variant="body1">
            To secure your account, we need to confirm it's really you. Enter
            the code we sent to your email to verify your account and sign in.
          </Typography>
          <ReactCodeInput
            name="confirmationCode"
            type="number"
            fields={6}
            inputMode="numeric"
            onChange={handleCode}
            inputStyle={{
              backgroundColor: 'transparent',
              borderBottom: '1px solid white',
              marginRight: 10,
              fontSize: 36,
              textAlign: 'center',
            }}
          />
          <div className="flex items-center justify-start gap-1">
            <Checkbox checked={checked} onChange={handleCheck} />
            <Typography variant="body1">
              Don't ask again on this device
            </Typography>
          </div>
          <Button variant="bassnectar" onClick={handleSubmit}>
            {locationState?.paid
              ? "Continue to Payment"
              : "Confirm Your Account"}
          </Button>
        </div>
      </div>
    </div>
  );
}
