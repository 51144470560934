import {
  ReactElement,
  PropsWithChildren,
  useEffect,
  useContext,
  createRef,
  startTransition,
} from "react";
import { DimensionContext } from "./DimensionProvider";

type InfiniteScrollerProps = {
  skeleton: ReactElement;
  allResultsLoaded: boolean;
  onLoadRequest: () => void;
};

export function InfiniteScroller({
  skeleton,
  children,
  allResultsLoaded,
  onLoadRequest,
}: PropsWithChildren<InfiniteScrollerProps>) {
  const { observerRoot } = useContext(DimensionContext);
  const loaderRef = createRef<HTMLDivElement>();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          startTransition(() => {
            onLoadRequest();
          });
        }
      },
      {
        root: observerRoot,
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [onLoadRequest, loaderRef, observerRoot]);

  return (
    <>
      {children}
      {!allResultsLoaded && <div ref={loaderRef}>{skeleton}</div>}
    </>
  );
}
