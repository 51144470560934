import { Typography } from "@mui/material";
import { useGetAppVersionQueryGenerated } from "../types/generated";
import appInfo from "../../package.json";
import LoadingIcons from "react-loading-icons";

export const AppVersion = () => {
  const { data, loading } = useGetAppVersionQueryGenerated();

  return (
    <>
      <Typography variant="h6" marginRight={1}>
        App Version:{" "}
      </Typography>
      {loading ? (
        <LoadingIcons.Bars width="20px" height={"20px"} />
      ) : (
        <Typography variant="h6">
          {data?.getAppVersion ?? appInfo.version}
        </Typography>
      )}
    </>
  );
};
