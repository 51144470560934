import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { userIsUnlocked } from "../Auth/utils";

export const LockedRoutes = () => {
  const { loggedIn, authenticating, user } = useContext(AuthContext);

  if (!authenticating && !loggedIn) {
    return <Navigate to="/auth/signin" />;
  } else if (!authenticating && loggedIn && !userIsUnlocked(user)) {
    return <Navigate to="/subscribe" replace />;
  } else {
    return <Outlet />;
  }
};
