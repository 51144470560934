import { Typography } from "@mui/material";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";

interface ListItemProps {
  title: string;
  artwork: string;
  info: string;
  path: string;
}

export function ListItem({ title, artwork, info, path }: ListItemProps) {
  return (
    <Link to={path}>
      <div className="mb-4 flex h-[120px] min-w-[100%] items-center lg:h-[280px]">
        <img src={artwork} alt={title} className="h-[120px] lg:h-[280px]" />
        <MediaQuery maxWidth={1023}>
          <div className="ml-4 flex flex-col">
            <Typography variant="h5">{title}</Typography>
            <Typography variant="body1">{info}</Typography>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <div className="ml-4 flex flex-col">
            <Typography variant="h4">{title}</Typography>
            <Typography variant="h5">{info}</Typography>
          </div>
        </MediaQuery>
      </div>
    </Link>
  );
}
