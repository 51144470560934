import { Section } from "./index";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Divider } from "../ui/Divider";

interface Props {
  section: Section;
}

export const ProfileListSection = ({
  section: { icon, title, badge, link, action },
}: Props) => {
  const Content = () => {
    return (
      <div className="flex items-center justify-center">
        <img src={icon} alt="icon" className="mr-6" />
        <div className=" fon mr-6 text-lg font-semibold">{title}</div>
        {badge && badge}
      </div>
    );
  };

  return (
    <div className="w-full">
      {link ? (
        <Link
          to={link.url}
          target={link.target}
          rel={link.rel}
          className="flex items-center justify-between px-4 py-4 text-white"
        >
          <Content />
          <ArrowForwardIcon color="primary" />
        </Link>
      ) : (
        <div className="flex items-center justify-between px-4 py-4 text-white">
          <Content />
          {action && action}
        </div>
      )}

      <Divider />
    </div>
  );
};
