import { Typography } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { SectionHero } from "../ui/components/SectionHero";
import "./style.css";

const Thoughts = () => {
  const { pathname } = useLocation();

  return (
    <div id="thoughts-index" className="flex min-w-[100vw] flex-col md:min-w-auto">
      <SectionHero title="Thoughts" />

      <div className="flex items-center justify-around bg-[#00000042] py-1">
        <Link to="./links">
          <Typography
            variant="subheading"
            className={
              pathname.indexOf("/links") !== -1
                ? "border-b border-[#6DFE95]"
                : ""
            }
          >
            Links
          </Typography>
        </Link>
        <Link to="./transmissions">
          <Typography
            variant="subheading"
            className={
              pathname.indexOf("/transmissions") !== -1
                ? "border-b border-[#6DFE95]"
                : ""
            }
          >
            Transmissions
          </Typography>
        </Link>
        <Link to="./qa">
          <Typography
            variant="subheading"
            className={
              pathname.indexOf("/qa") !== -1 ? "border-b border-[#6DFE95]" : ""
            }
          >
            Q&A
          </Typography>
        </Link>
      </div>

      <div className="p-6">
        <Outlet />
      </div>
    </div>
  );
};

export default Thoughts;
