import React, { useEffect } from 'react';

// Declare the global type for the `TF` object provided by Typeform
declare global {
  interface Window {
    TF: {
      open: (url: string, options: Record<string, unknown>) => void;
    };
  }
}

interface TypeformWidgetProps {
  title: string;
  id: string;
}

export const TypeformWidget: React.FC<TypeformWidgetProps> = ({
  title,
  id,
}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    script.onload = () => {
      window.TF.open(`//typeform.com/to/${id}`, {
        hideFooter: true,
        opacity: 100,
        // Add other options as needed
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [id]);

  return (
    <div
      data-tf-widget={id}
      data-tf-opacity='100'
      data-tf-iframe-props={`title=${title}`}
      data-tf-transitive-search-params
      data-tf-medium='snippet'
      style={{ width: '100%', height: '500px' }}
    ></div>
  );
};
