import {useEffect, useState} from 'react';
import { User } from '../types/User';
import { userIsUnlocked, userIsGold, userIsAGuest, userIsAdmin, userIsVip } from "../Auth/utils";
import Unlocked from "../assets/unlocked.png";
import { Button, Typography } from "@mui/material";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import BassDropGold from "../assets/bass-drop-gold.png";
import BassDropTOS from '../assets/bassdrop-tos.svg';
import {Countdown} from '../ui/Countdownt';

type SubscriptionStatusProps = {
  user: User | null;
};

export const UserSubscriptionStatus = ({ user }: SubscriptionStatusProps) => {
  const [userStatus, setUserStatus] = useState('freestyle');

  useEffect(() => {
    if (user) {
      if(userIsAdmin(user)) {
        setUserStatus('admin');
        return;
      }

      if(userIsVip(user)) {
        setUserStatus('vip');
        return;
      }

      if(userIsGold(user)) {
        setUserStatus('gold');
      } else if (userIsUnlocked(user)) {
        if(userIsAGuest(user)) {
          setUserStatus('guest-pass');
          
        } else {
          setUserStatus('unlocked');
        }
      }
    }
  }, [user]);

  const statusBarForStatus = () => {
    switch(userStatus) {
      case "gold": {
        return (
          <div className="w-full bg-light-gold border border-[#c69c6d] py-4 rounded-xl mb-[20px] mt-2 px-4 flex items-center">
            <img src={Unlocked} alt="unlocked icon" className="max-h-24 mr-4" />
            <MediaQuery maxWidth={1023}>
              <div className="flex flex-col text-center">
                <Typography variant="goldInverse">
                  You've Unlocked
                </Typography>
                <Typography variant="goldInverse">
                  TOS Gold
                </Typography>
              </div>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
              <Typography variant="goldInverse">You've Unlocked TOS Gold!</Typography>
            </MediaQuery>
          </div>
        );
      }

      case 'unlocked': {
        return (
          <div className="relative w-full tos-body-bkg border border-[#6DFE95] py-4 rounded-xl mb-[20px] mt-2 px-4 flex items-center gap-4">
            <div className="absolute left-0 top-0 h-full w-full rounded-xl bg-white mix-blend-overlay"></div>
            <img src={Unlocked} alt="unlocked icon" className='z-20' />
            <MediaQuery maxWidth={1023}>
              <div className="flex flex-col text-center ">
                <Typography variant="subheading">
                  You've Unlocked
                </Typography>
                <Typography variant="subheading">
                  The Other Side!
                </Typography>
              </div>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
              <Typography variant="subheading">You've Unlocked The Other Side!</Typography>
            </MediaQuery>
          </div>
        );
      }

      case 'guest-pass': {
        return (
          <div className="bg-black bg-opacity-50 border border-[#c69c6d] relative mb-2 text-center rounded-xl">
            <div className="px-2 py-4">
              <div className="relative flex flex-col items-center gap-4 px-2 pb-6">
                <div className="flex items-center gap-4">
                  <img src={Unlocked} alt="keys-icon" style={{ height: 50 }} />
                    <Typography id="modal-modal-title" className="text-left" variant="gold" component="h2" color="#c69c6d">
                      Unlocked Guest Pass
                    </Typography>
                </div>
                {user && user.guestPassExpiration && (
                  <Countdown variant="gold" time={user.guestPassExpiration} />
                )}
                <Typography variant="h6" className="w-3/4">
                  At the end of your Guest Pass, your account will be downgraded to freestyle.
                  Upgrade your account now to continue enjoying the TOS Unlocked Experience.
                </Typography>
              </div>
              <Link to="/subscribe">
                <Button variant="bassnectarGold">
                  UPGRADE MEMBERSHIP
                </Button>
              </Link>
            </div>
          </div>
        );        
      }

      case 'vip': {
        return (
          <div className="relative w-full bg-black bg-opacity-80 border border-[#c69c6d] py-4 rounded-xl mb-[20px] mt-2 px-4 flex items-center gap-4">
            <img src={BassDropGold} alt="unlocked icon" className='z-20' style={{ height: 50 }} />
            <div className="flex flex-col gap-4">
              <Typography variant="gold">VIP Access</Typography>
            </div>
          </div>
        );
      }

      case 'admin': {
        return (
          <div className="relative w-full bg-black bg-opacity-80 border border-[#0cb0e8] py-4 rounded-xl mb-[20px] mt-2 px-4 flex items-center gap-4">
            <img src={BassDropTOS} alt="unlocked icon" className='z-20' style={{ height: 50 }} />
            <div className="flex flex-col gap-4">
              <Typography variant="subheading">TOS Admin</Typography>
              <div className="flex gap-4">
                <Link to="https://support.unlocktheotherside.com">
                  <Button variant="bassnectar">Support Site</Button>
                </Link>
                <Link to="https://bassnetwork.freshdesk.com">
                  <Button variant="bassnectar">Help Desk</Button>
                </Link>
              </div>
            </div>
          </div>
        );
      }

      case 'freestyle': 
      default: {
        return (
          <div className="bg-light-gold relative mb-2 text-center rounded-xl">
            <div className="px-2 py-4">
              <div className="relative flex flex-col items-center gap-4 px-2 pb-6">
                <div className="flex items-center gap-4">
                  <img src={BassDropGold} alt="keys-icon" style={{ height: 50 }} />
                  <div className="flex flex-col justify-start items-start">
                    <Typography id="modal-modal-title" className="text-left" variant="h4" component="h2" color="#c69c6d">
                      Unlock The Other Side
                    </Typography>
                    <Typography variant="h6" color='black'>$10/month</Typography>
                  </div>
                </div>
                <Typography variant="h6" color='black' className="w-3/4">
                  With an unlocked subscription you gain access to all current
                  and future content and plenty of members-only perks! 
                </Typography>
                <Link to="/about">
                  <Button variant="text" sx={{ color: "#000", textDecoration: 'underline' }}>
                    Explore UNLOCKED Features
                  </Button>
                </Link>
              </div>
              <Link to="/subscribe">
                <Button variant="bassnectarDarkGold">
                  Upgrade Membership
                </Button>
              </Link>
            </div>
          </div>
        )
      }
    }
  }

  return (
    <>
      {statusBarForStatus()}
    </>
  )
}
