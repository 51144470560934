import { Button } from "@mui/material";
// import LoadingIcons from "react-loading-icons";
import { withLogicHook } from "../../../utils/withLogicHook";
import { CancelSubscriptionModal } from "../CancelSubscriptionModal";
import {
  SquareSubscriptionProps,
  useSquareSubscriptionLogic,
} from "./useSquareSubscriptionLogic";

const SquareSubscriptionComponent = ({
  cells,
  cancelSubscription,
  loading,
  error,
  isActive,
  showDialog,
  closeDialog,
  openDialog,
}: SquareSubscriptionProps) => {
  return (
    <div className="flex w-full flex-col items-center">
      <CancelSubscriptionModal
        onDismiss={closeDialog}
        open={showDialog}
        onSubmit={cancelSubscription}
        loading={loading}
      />
      <div className="my-4 w-full flex-col">
        {cells.map((cell, index) => (
          <div key={`${index}`} className="flex w-full justify-between">
            <div className="p-2">{cell.label}</div>
            <div className="p-2">{cell.value}</div>
          </div>
        ))}
      </div>
      {isActive && (
        <Button
          fullWidth
          variant="bassnectarText"
          onClick={openDialog}
          disabled={loading}
          className="mt-4 flex w-48 items-center justify-center rounded-2xl bg-red-600 p-4 font-semibold text-white"
        >
          <div>Cancel Subscription</div>
        </Button>
      )}
      {error && <div>{`${error}`}</div>}
    </div>
  );
};

export const SquareSubscription = withLogicHook(
  SquareSubscriptionComponent,
  useSquareSubscriptionLogic
);
