import { useState } from "react";

const Help = () => {
  const [helpType, setHelpType] = useState(""); // new state for the dropdown selection
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  //  team@unlocktheotherside.com
  const handleSubmit = () => {
    // TODO: Save changes to backend
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 pt-16">
      <h1 className="my-4 text-xl font-bold text-white">Help</h1>

      <div className="mb-4 mt-4 flex w-full flex-col">
        <label htmlFor="help" className="text-2xl font-bold text-white">
          I need help with
        </label>
        <select
          id="helpType"
          className="bg-grey mt-3 rounded-md border border-white bg-black py-4 px-4 text-white"
          value={helpType}
          onChange={(event) => setHelpType(event.target.value)}
        >
          <option value="">Select a help type</option>
          <option value="account">Account</option>
          <option value="text">Text</option>
          <option value="discord">Discord</option>
          <option value="other">Other</option>
        </select>
      </div>

      <div className="mb-6 mt-4 flex w-full flex-col">
        <label htmlFor="username" className="text-2xl font-bold text-white">
          Username
        </label>
        <input
          id="username"
          className="bg-grey mt-3 rounded-md border border-white bg-black py-4 px-4 text-white"
          placeholder="Username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
      </div>

      <div className="mb-6 flex w-full flex-col">
        <label htmlFor="email" className="text-2xl font-bold text-white">
          Change email
        </label>
        <input
          id="email"
          type="email"
          className="bg-grey mt-3 rounded-md border border-white bg-black py-4 px-4 text-white"
          placeholder="Email Address"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </div>

      <div className="mb-6 flex w-full flex-col">
        <label htmlFor="email" className="text-2xl font-bold text-white">
          Message
        </label>
        <textarea
          id="message"
          className="bg-grey mt-3 h-36 rounded-md border border-white bg-black py-4 px-4 text-white"
          placeholder="Message"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
      </div>

      <button
        className="mt-6 mb-36 flex w-full items-center justify-center rounded-md bg-gray-300 py-2 px-4 text-center font-bold text-gray-700"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default Help;
