import { outlinedInputClasses } from "@mui/material";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    subheading: React.CSSProperties;
    description: React.CSSProperties;
    trackTitle: React.CSSProperties;
    jumbo: React.CSSProperties;
    gold: React.CSSProperties;
    goldInverse: React.CSSProperties;
    caption2: React.CSSProperties;
    overline2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subheading?: React.CSSProperties;
    description?: React.CSSProperties;
    trackTitle?: React.CSSProperties;
    jumbo: React.CSSProperties;
    caption2: React.CSSProperties;
    overline2: React.CSSProperties;
    gold: React.CSSProperties;
    goldInverse: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    description: true;
    subheading: true;
    trackTitle: true;
    jumbo: true;
    gold: true;
    goldInverse: true;
    caption2: true;
    overline2: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    bassnectar: true;
    bassnectarText: true;
    bassnectarJumbo: true;
    bassnectarJumboPadding: true;
    bassnectarSecondary: true;
    bassnectarGold: true;
    bassnectarDarkGold: true;
  }
}

export const BassnectarTheme = createTheme({
  palette: {
    primary: {
      main: "#6DFE95",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#6DFE95",
        },
        root: {
          backgroundColor: "white",
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "#0cb0e8",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "#0cb0e8",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: 16,
          borderRadius: 0,
          backgroundColor: "white", // Set the desired background color
          "&:hover": {
            backgroundColor: "white", // Set the desired background color on hover
            borderRadius: 0,
          },
          "&.Mui-focused": {
            backgroundColor: "white", // Set the desired background color on focus
            borderRadius: 0,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#555", // Set the desired label color
          "&$shrink": {
            transform: "translate(12px, 10px) scale(0.75)",
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "bassnectar" },
          style: {
            fontSize: 16,
            height: 32,
            borderRadius: 32,
            background:
              "linear-gradient(180deg, #6DFE95 0%, #6DFE95 30%, #0cb0e8 100%);",
            fontFamily: "HexFranklinBold",
            color: "black",
            textTransform: "none",
          },
        },
        {
          props: { variant: "bassnectarGold" },
          style: {
            fontSize: 16,
            height: 32,
            borderRadius: 4,
            background:
              "linear-gradient(to bottom, #ffecb3 10%, #dbba74 78%)",
            fontFamily: "HexFranklinBold",
            color: "black",
            textTransform: "none",
          },
        },
        {
          props: { variant: "bassnectarDarkGold" },
          style: {
            fontSize: 16,
            height: 32,
            borderRadius: 4,
            background:
              "linear-gradient(to bottom, #c69c6d 0%, #845e34 50%)",
            fontFamily: "HexFranklinBold",
            color: "white",
            textTransform: "none",
          },
        },
        {
          props: { variant: "bassnectarText" },
          style: {
            fontSize: 16,
            height: 32,
            borderRadius: 32,
            background:
              "linear-gradient(180deg, #6DFE95 0%, #6DFE95 30%, #0cb0e8 100%);",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily: "HexFranklinBold",
            color: "white",
            textTransform: "uppercase",
          },
        },
        {
          props: { variant: "bassnectarJumbo" },
          style: {
            fontSize: 22,
            height: 42,
            borderRadius: 42,
            background:
              "linear-gradient(180deg, #6DFE95 0%, #6DFE95 30%, #0cb0e8 100%);",
            fontFamily: "HexFranklinBold",
            color: "black",
            textTransform: "none",
          },
        },
        {
          props: { variant: "bassnectarJumboPadding" },
          style: {
            fontSize: 22,
            height: 42,
            borderRadius: 42,
            paddingLeft: 60,
            paddingRight: 60,
            background:
              "linear-gradient(180deg, #6DFE95 0%, #6DFE95 30%, #0cb0e8 100%);",
            fontFamily: "HexFranklinBold",
            color: "black",
            textTransform: "none",
          },
        },
        {
          props: { variant: "bassnectarSecondary" },
          style: {
            fontSize: 20,
            textTransform: "uppercase",
            height: 38,
            borderRadius: 38,
            background:
              "linear-gradient(355.67deg, #E0796F -18.37%, #7D0B00 -18.36%, #55006E 100.08%)",
            boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.3)",
            fontFamily: "HexFranklinBold",
            color: "white",
            border: "1px solid white",
            justifyContent: "space-between",
            display: "flex",
          },
        },
      ],
    },
  },
  typography: {
    fontFamily: "HexFranklinRegular",
    jumbo: {
      color: "white",
      fontFamily: "HexFranklinExtraCondensedExtraBold",
      fontSize: 70,
      background:
        "linear-gradient(180deg, #6DFE95 0%, #6DFE95 30%, #0cb0e8 100%);",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      lineHeight: "72px",
      letterSpacing: "0.06em",
      backgroundClip: "text",
      WebkitFilter: `drop-shadow(-.5px -.5px 0 #812B7F)
         drop-shadow(.5px -.5px 0 #812B7F)
         drop-shadow( -.5px .5px 0 #812B7F)
         drop-shadow(.5px .5px 0 #812B7F)`,
    },
    gold: {
      color: "white",
      fontFamily: "HexFranklinExtraCondensedExtraBold",
      fontSize: 34,
      background:
        "linear-gradient(to bottom, #cfc09f 0%, #ffecb3 20%, #3a2c0f 100%);",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textTransform: "uppercase",
      lineHeight: "50px",
      letterSpacing: "0.06em",
      backgroundClip: "text",
    },
    goldInverse: {
      color: "white",
      fontFamily: "HexFranklinExtraCondensedExtraBold",
      fontSize: 34,
      textTransform: "uppercase",
      background:
        "linear-gradient(to bottom, #3a2c0f 10%, #c69c6d 25%, #3a2c0f 80%);",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      lineHeight: "50px",
      letterSpacing: "0.06em",
      backgroundClip: "text",
    },
    h1: {
      color: "white",
      fontFamily: "HexFranklinExtraCondensedExtraBold",
      fontSize: 50,
      background:
        "linear-gradient(180deg, #6DFE95 0%, #6DFE95 30%, #0cb0e8 100%);",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textTransform: "uppercase",
      lineHeight: "60px",
      letterSpacing: "0.06em",
      backgroundClip: "text",
      WebkitFilter: `drop-shadow(-.5px -.5px 0 #812B7F)
         drop-shadow(.5px -.5px 0 #812B7F)
         drop-shadow( -.5px .5px 0 #812B7F)
         drop-shadow(.5px .5px 0 #812B7F)`,
    },
    h2: {
      color: "white",
      fontFamily: "HexFranklinBold",
      fontSize: 40,
    },
    h3: {
      color: "white",
      fontFamily: "HexFranklinBold",
      fontSize: 32,
    },
    h4: {
      color: "white",
      fontFamily: "HexFranklinBold",
      fontSize: 24,
    },
    h5: {
      color: "white",
      fontFamily: "HexFranklinBold",
      fontSize: 20,
    },
    h6: {
      color: "white",
      fontFamily: "HexFranklinMedium",
      fontSize: 14,
    },
    trackTitle: {
      color: "white",
      fontFamily: "HexFranklinSemibold",
      fontSize: 14,
      lineHeight: 1.2,
    },
    subheading: {
      color: "white",
      fontFamily: "HexFranklinExtraCondensedExtraBold",
      fontSize: 30,
      background:
        "linear-gradient(180deg, #6DFE95 0%, #6DFE95 30%, #0cb0e8 100%);",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textTransform: "uppercase",
      lineHeight: "30px",
      letterSpacing: "0.06em",
      backgroundClip: "text",
      WebkitFilter: `drop-shadow(-.5px -.5px 0 #812B7F)
         drop-shadow(.5px -.5px 0 #812B7F)
         drop-shadow( -.5px .5px 0 #812B7F)
         drop-shadow(.5px .5px 0 #812B7F)`,
    },
    subtitle1: {
      color: "white",
      fontFamily: "HexFranklinBold",
      fontSize: 12,
    },
    subtitle2: {
      color: "white",
      fontFamily: "HexFranklinBold",
      fontSize: 10,
    },
    button: {
      color: "white",
      fontFamily: "HexFranklinSemibold",
      fontSize: 12,
    },
    description: {
      color: "white",
      fontFamily: "HexFranklinRegular",
      fontSize: 14,
    },
    body1: {
      color: "white",
      fontFamily: "HexFranklinSemibold",
      fontSize: 12,
      lineHeight: "12px",
    },
    body2: {
      color: "white",
      fontFamily: "HexFranklinMedium",
      fontSize: 10,
    },
    caption: {
      color: "white",
      fontFamily: "HexFranklinRegular",
      fontSize: 10,
    },
    caption2: {
      color: "white",
      fontFamily: "HexFranklinRegular",
      fontSize: 12,
    },
    overline: {
      color: "white",
      fontFamily: "HexFranklinSemibold",
      fontSize: 14,
      lineHeight: 1.2,
      textTransform: "none",
    },
    overline2: {
      color: "white",
      fontFamily: "HexFranklinSemibold",
      fontSize: 16,
    },
  },
});
