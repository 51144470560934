import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
};

export type AcceptInviteResponse = {
  __typename?: 'AcceptInviteResponse';
  error?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type AddPaymentInput = {
  card: SquareCardInput;
  sourceId: Scalars['String']['input'];
};

export type AddUserLedgerItemResponse = {
  __typename?: 'AddUserLedgerItemResponse';
  added: Scalars['Boolean']['output'];
  payload?: Maybe<UserLedger>;
};

export type AlbumSession = {
  __typename?: 'AlbumSession';
  albumId: Scalars['Int']['output'];
  elapsedTime?: Maybe<Scalars['Float']['output']>;
  endTime?: Maybe<Scalars['Float']['output']>;
  keyId: Scalars['String']['output'];
  keyType?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['Float']['output']>;
  sub: Scalars['String']['output'];
};

export type ArcadeSession = {
  __typename?: 'ArcadeSession';
  elapsedTime?: Maybe<Scalars['Float']['output']>;
  endTime?: Maybe<Scalars['Float']['output']>;
  gameId: Scalars['String']['output'];
  keyId: Scalars['String']['output'];
  keyType?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['Float']['output']>;
  sub: Scalars['String']['output'];
};

export type Cta = {
  __typename?: 'CTA';
  ctaLabel: Scalars['String']['output'];
  ctaPath: Scalars['String']['output'];
  ctaType: CtaTypes;
  ctaVariant: CtaVariants;
};

export enum CtaTypes {
  audioArcade = 'audioArcade',
  link = 'link',
  tickets = 'tickets'
}

export enum CtaVariants {
  bassnectar = 'bassnectar',
  jumbo = 'jumbo',
  jumboPadding = 'jumboPadding',
  secondary = 'secondary',
  text = 'text'
}

export type Card = {
  __typename?: 'Card';
  cardBrand?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  expMonth?: Maybe<Scalars['BigInt']['output']>;
  expYear?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
};

export type CognitoGroup = {
  __typename?: 'CognitoGroup';
  GroupName: Scalars['String']['output'];
};

export type CognitoUser = {
  __typename?: 'CognitoUser';
  Enabled?: Maybe<Scalars['Boolean']['output']>;
  Groups: Array<CognitoGroup>;
  UserAttributes?: Maybe<CognitoUserAttributes>;
  UserCreateDate?: Maybe<Scalars['Date']['output']>;
  UserStatus?: Maybe<Scalars['String']['output']>;
  Username: Scalars['String']['output'];
};

export type CognitoUserAttributes = {
  __typename?: 'CognitoUserAttributes';
  email?: Maybe<Scalars['String']['output']>;
  email_verified?: Maybe<Scalars['String']['output']>;
  preferred_username?: Maybe<Scalars['String']['output']>;
  sub?: Maybe<Scalars['String']['output']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  coupon: Scalars['String']['output'];
  sub?: Maybe<Scalars['String']['output']>;
};

export type DynamicRoute = {
  __typename?: 'DynamicRoute';
  privacy?: Maybe<DynamicRoutePrivacy>;
  skeletonVariant?: Maybe<SkeletonVariants>;
  slug: Scalars['String']['output'];
};

export enum DynamicRoutePrivacy {
  FREESTYLE = 'FREESTYLE',
  PUBLIC = 'PUBLIC',
  UNLOCKED = 'UNLOCKED'
}

export enum EventPrivacy {
  FREESTYLE = 'FREESTYLE',
  PUBLIC = 'PUBLIC',
  UNLOCKED = 'UNLOCKED'
}

export type EventScore = {
  __typename?: 'EventScore';
  event: Scalars['String']['output'];
  score: Scalars['Int']['output'];
};

export type HomeCard = {
  __typename?: 'HomeCard';
  cssId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ImageHero = {
  __typename?: 'ImageHero';
  artwork?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
};

export type InvitationResponse = {
  __typename?: 'InvitationResponse';
  error?: Maybe<Scalars['String']['output']>;
  invitationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ListCardsResponse = {
  __typename?: 'ListCardsResponse';
  cards: Array<Card>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept invite */
  acceptInvite: AcceptInviteResponse;
  /** Activate album session for current user */
  activateAlbumSession: AlbumSession;
  /** Activate arcade session for current user */
  activateArcadeSession: ArcadeSession;
  /** Change Credit Card on file */
  addPaymentMethod: Card;
  /** Add user ledger item */
  addUserLedgerItem?: Maybe<AddUserLedgerItemResponse>;
  /** Add user groups */
  adminAddUserGroup: Scalars['Boolean']['output'];
  /** Invite guest */
  adminInviteAccountRecovery: InvitationResponse;
  /** Invite guest */
  adminInviteVIP: InvitationResponse;
  /** Remove user groups */
  adminRemoveUserGroup: Scalars['Boolean']['output'];
  /** Apply a coupon to a user */
  applyCoupon: Scalars['Boolean']['output'];
  /** Cancel subscription */
  cancelSubscription: SquareSubscription;
  /** Disable Credit Card on File */
  disablePaymentMethod: Card;
  /** Invite guest */
  inviteGuest: InvitationResponse;
  /** Lets a user mark a notification as read */
  markNotificationAsRead: ReadNotificationsResponse;
  /** Process key purchase */
  processKeyPurchase: Scalars['Int']['output'];
  /** Process subscription */
  processSubscription: SquareSubscriptionResponse;
  /** Purchase guest pass */
  purchaseGuestPass: Scalars['Boolean']['output'];
  /** Seed 100 more coupons */
  seedCoupons: Scalars['Boolean']['output'];
  /** Upgrade subscription from monthly to annual */
  upgradeSubscription: SquareSubscription;
};


export type MutationAcceptInviteArgs = {
  invitationId: Scalars['String']['input'];
};


export type MutationActivateAlbumSessionArgs = {
  albumId: Scalars['Int']['input'];
};


export type MutationActivateArcadeSessionArgs = {
  gameId: Scalars['String']['input'];
};


export type MutationAddPaymentMethodArgs = {
  input: AddPaymentInput;
};


export type MutationAddUserLedgerItemArgs = {
  input: UserLedgerInput;
};


export type MutationAdminAddUserGroupArgs = {
  group: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationAdminInviteAccountRecoveryArgs = {
  email: Scalars['String']['input'];
};


export type MutationAdminInviteVipArgs = {
  email: Scalars['String']['input'];
};


export type MutationAdminRemoveUserGroupArgs = {
  group: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationApplyCouponArgs = {
  coupon: Scalars['String']['input'];
};


export type MutationDisablePaymentMethodArgs = {
  cardId: Scalars['String']['input'];
};


export type MutationInviteGuestArgs = {
  email: Scalars['String']['input'];
};


export type MutationMarkNotificationAsReadArgs = {
  key: Scalars['String']['input'];
};


export type MutationProcessKeyPurchaseArgs = {
  input: ProcessKeyPurchaseInput;
};


export type MutationProcessSubscriptionArgs = {
  input: ProcessSubscriptionInput;
};


export type MutationUpgradeSubscriptionArgs = {
  cardId: Scalars['String']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  status?: Maybe<NotificationStatus>;
  title?: Maybe<Scalars['String']['output']>;
  type: NotificationType;
};

export enum NotificationStatus {
  read = 'read',
  unread = 'unread'
}

export enum NotificationType {
  notification = 'notification',
  takeover = 'takeover'
}

export type NotificationsResponse = {
  __typename?: 'NotificationsResponse';
  notifications: Array<Notification>;
  unreadCount: Scalars['Int']['output'];
};

export type PlanDetail = {
  __typename?: 'PlanDetail';
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PlanDetailType>;
};

export enum PlanDetailType {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY'
}

export type ProcessKeyPurchaseInput = {
  cardId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type ProcessSubscriptionInput = {
  card?: InputMaybe<SquareCardInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  planType: SquareSubscriptionPlanType;
  sourceId: Scalars['String']['input'];
};

export type ProcessSubscriptionUpgradeInput = {
  cardId: Scalars['String']['input'];
  cvv: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** Return the guest pass balance for a given user */
  adminGetUserGuestPassBalance: Scalars['Int']['output'];
  /** Return the key balance for a given user */
  adminGetUserKeyBalance: Scalars['Int']['output'];
  getAlbum: StrapiAlbum;
  /** Return list of albums */
  getAlbums: Array<StrapiAlbum>;
  /** Find a list of all albums from a provided category */
  getAlbumsByCategory: StrapiPaginatedResults;
  /** Return all coupons */
  getAllCoupons: Array<Coupon>;
  getAppVersion: Scalars['String']['output'];
  /** Get the details for a given event */
  getArcadeDetails: StrapiArcade;
  /** Retriev arcade games matching the supplied game IDs */
  getArcadesByGameId: Array<StrapiArcade>;
  /** Return artist by id */
  getArtist: StrapiArtist;
  /** Return list of artists */
  getArtists: Array<StrapiArtist>;
  /** Get CCOF details for user */
  getCardsOnFile: ListCardsResponse;
  /** Return list of charities */
  getCharities: Array<StrapiCharity>;
  /** Return charity by id */
  getCharity: StrapiCharity;
  /** Return list of Cognito users */
  getCognitoUsers: Array<CognitoUser>;
  /** Return an index page */
  getDynamicPageBySlug: StrapiDynamicPage;
  /** Return all dynamic page slugs */
  getDynamicRoutes: StrapiDynamicRoutesResponse;
  /** Get the details for a given event */
  getEventDetails: StrapiEvent;
  getHomeCards: Array<HomeCard>;
  /** Return user wallet */
  getMyWallet: Array<UserLedger>;
  /** Get the stack for a given notification */
  getNotificationDetails: StrapiNotifications;
  /** Return all notifications for a user with an unread count */
  getNotifications: NotificationsResponse;
  /** Return list of plan details */
  getPlanDetails: Array<PlanDetail>;
  /** Return list of QAs */
  getQAs: Array<StrapiQa>;
  /** Return Square customer */
  getSquareCustomer?: Maybe<SquareCustomer>;
  /** Return list of Stripe subscriptions */
  getStripeSubscriptions: Array<StripeSubscription>;
  /** Get the artwork and other details for a takeover notification */
  getTakeover: StrapiTakeoverAlert;
  /** Return thought by id */
  getThoughtsLink: StrapiThoughtsLink;
  /** Return list of thoughts */
  getThoughtsLinks: Array<StrapiThoughtsLink>;
  /** Return a private url for a ticketmaster event */
  getTicketmasterPresaleCode: Scalars['String']['output'];
  /** Return the Tixr URL for a given user */
  getTixrPrivateUrl: Scalars['String']['output'];
  /** Return transmission by id */
  getTransmission: StrapiTransmission;
  /** Return list of transmissions */
  getTransmissions: Array<StrapiTransmission>;
  /** Return the treasure schedule for a given date. */
  getTreasureScheduleForDate: StrapiTreasureSchedule;
  /** Return an unused coupon */
  getUnusedCoupon: Scalars['String']['output'];
  /** Get the event that's happening in the next 24 hours */
  getUpcomingEvent?: Maybe<StrapiEvent>;
  /** Return user */
  getUser: User;
  /** Return the guest pass balance for a given user */
  getUserGuestPassBalance: Scalars['Int']['output'];
  /** Return the high scores for a given user */
  getUserHighScores: Array<EventScore>;
  /** Return the key balance for a given user */
  getUserKeyBalance: Scalars['Int']['output'];
  /** Return user ledger */
  getUserLedger: Array<UserLedger>;
  /** Return user ledger item */
  getUserLedgerItem?: Maybe<UserLedger>;
  /** Check if a user has a monthly subscription */
  isMonthlySub: Scalars['Boolean']['output'];
  /** Send a passwordless login link to a user */
  sendPasswordlessLoginLink: Scalars['Boolean']['output'];
  /** Verify existing album session */
  verifyAlbumSession: AlbumSession;
  /** Verify existing arcade session */
  verifyArcadeSession: ArcadeSession;
  /** Verify a coupon is valid without applying it. */
  verifyCoupon: Scalars['Boolean']['output'];
};


export type QueryAdminGetUserGuestPassBalanceArgs = {
  email: Scalars['String']['input'];
};


export type QueryAdminGetUserKeyBalanceArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetAlbumArgs = {
  albumId: Scalars['Int']['input'];
};


export type QueryGetAlbumsByCategoryArgs = {
  category: StrapiAlbumCategory;
  limit?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetArcadeDetailsArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetArcadesByGameIdArgs = {
  gameIds: Array<Scalars['String']['input']>;
};


export type QueryGetArtistArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCharityArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetDynamicPageBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryGetEventDetailsArgs = {
  eventId: Scalars['Int']['input'];
};


export type QueryGetNotificationDetailsArgs = {
  noteId: Scalars['Int']['input'];
};


export type QueryGetSquareCustomerArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetStripeSubscriptionsArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetTakeoverArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetThoughtsLinkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetTransmissionArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetTreasureScheduleForDateArgs = {
  date: Scalars['String']['input'];
};


export type QueryGetUserArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetUserLedgerItemArgs = {
  pk: Scalars['String']['input'];
  sk: Scalars['String']['input'];
};


export type QuerySendPasswordlessLoginLinkArgs = {
  email: Scalars['String']['input'];
};


export type QueryVerifyAlbumSessionArgs = {
  albumId: Scalars['Int']['input'];
};


export type QueryVerifyArcadeSessionArgs = {
  gameId: Scalars['String']['input'];
};


export type QueryVerifyCouponArgs = {
  coupon: Scalars['String']['input'];
};

export type ReadNotificationsResponse = {
  __typename?: 'ReadNotificationsResponse';
  status: Scalars['String']['output'];
};

export type SectionHero = {
  __typename?: 'SectionHero';
  background: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export enum SkeletonVariants {
  artists = 'artists',
  banner = 'banner',
  event = 'event',
  index = 'index',
  indexNoFeature = 'indexNoFeature',
  indexWithGrid = 'indexWithGrid',
  sectionIndex = 'sectionIndex',
  sectionItem = 'sectionItem',
  trackList = 'trackList'
}

export type SquareBillingContactInput = {
  addressLines?: InputMaybe<Array<Scalars['String']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type SquareCardInput = {
  billing?: InputMaybe<SquareBillingContactInput>;
  brand?: InputMaybe<Scalars['String']['input']>;
  cardType?: InputMaybe<Scalars['String']['input']>;
  expMonth?: InputMaybe<Scalars['Int']['input']>;
  expYear?: InputMaybe<Scalars['Int']['input']>;
  last4?: InputMaybe<Scalars['String']['input']>;
  prepaidType?: InputMaybe<Scalars['String']['input']>;
};

export type SquareCustomer = {
  __typename?: 'SquareCustomer';
  emailAddress?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  subscriptions?: Maybe<Array<SquareSubscription>>;
};

export type SquareMoney = {
  __typename?: 'SquareMoney';
  amount?: Maybe<Scalars['BigInt']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
};

export type SquarePlanVariation = {
  __typename?: 'SquarePlanVariation';
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  presentAtAllLocations?: Maybe<Scalars['Boolean']['output']>;
  subscriptionPlanVariationData?: Maybe<SquarePlanVariationData>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['BigInt']['output']>;
};

export type SquarePlanVariationData = {
  __typename?: 'SquarePlanVariationData';
  name?: Maybe<Scalars['String']['output']>;
  phases?: Maybe<Array<SquarePlanVariationDataPhase>>;
  subscriptionPlanId?: Maybe<Scalars['String']['output']>;
};

export type SquarePlanVariationDataPhase = {
  __typename?: 'SquarePlanVariationDataPhase';
  cadence?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['BigInt']['output']>;
  pricing?: Maybe<SquarePricing>;
  uid?: Maybe<Scalars['String']['output']>;
};

export type SquarePricing = {
  __typename?: 'SquarePricing';
  priceMoney?: Maybe<SquareMoney>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SquareSubscription = {
  __typename?: 'SquareSubscription';
  canceledDate?: Maybe<Scalars['String']['output']>;
  chargedThroughDate?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  planVariation?: Maybe<SquarePlanVariation>;
  planVariationId?: Maybe<Scalars['String']['output']>;
  priceOverrideMoney?: Maybe<SquareMoney>;
  source?: Maybe<SquareSubscriptionSource>;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export enum SquareSubscriptionPlanType {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY'
}

export type SquareSubscriptionResponse = {
  __typename?: 'SquareSubscriptionResponse';
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

export type SquareSubscriptionSource = {
  __typename?: 'SquareSubscriptionSource';
  name?: Maybe<Scalars['String']['output']>;
};

export type StrapiAlbum = {
  __typename?: 'StrapiAlbum';
  artist?: Maybe<Scalars['String']['output']>;
  category: StrapiAlbumCategory;
  clicksThrough: Scalars['Boolean']['output'];
  comingSoon: Scalars['Boolean']['output'];
  coverArt?: Maybe<Scalars['String']['output']>;
  cssId?: Maybe<Scalars['String']['output']>;
  gradient?: Maybe<StrapiGradient>;
  html?: Maybe<StrapiHtmlBlock>;
  id: Scalars['Int']['output'];
  keyPrice?: Maybe<Scalars['Int']['output']>;
  locked: Scalars['Boolean']['output'];
  rank?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  trackList: Array<StrapiTrack>;
};

export enum StrapiAlbumCategory {
  CONVERSATION = 'CONVERSATION',
  MIXTAPE = 'MIXTAPE',
  RELEASE = 'RELEASE',
  UNRELEASED = 'UNRELEASED'
}

export type StrapiArcade = {
  __typename?: 'StrapiArcade';
  badge?: Maybe<Scalars['String']['output']>;
  clicksThrough?: Maybe<Scalars['Boolean']['output']>;
  comingSoon?: Maybe<Scalars['Boolean']['output']>;
  cssId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gameId?: Maybe<Scalars['String']['output']>;
  gradient?: Maybe<StrapiGradient>;
  id: Scalars['Int']['output'];
  locked?: Maybe<Scalars['Boolean']['output']>;
  modalText?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pageHero?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type StrapiArtist = {
  __typename?: 'StrapiArtist';
  artwork?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  cssId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  donateUrl?: Maybe<Scalars['String']['output']>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  tikTokUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
  works: Array<StrapiArtistWork>;
};

export type StrapiArtistWork = {
  __typename?: 'StrapiArtistWork';
  id: Scalars['Int']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type StrapiCallToAction = {
  __typename?: 'StrapiCallToAction';
  ctaLabel?: Maybe<Scalars['String']['output']>;
  ctaPath?: Maybe<Scalars['String']['output']>;
  ctaType?: Maybe<Scalars['String']['output']>;
};

export type StrapiCharity = {
  __typename?: 'StrapiCharity';
  cssId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  donateUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pageHero?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
  works?: Maybe<Scalars['String']['output']>;
};

export type StrapiColor = {
  __typename?: 'StrapiColor';
  hexColor?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

export type StrapiDynamicPage = {
  __typename?: 'StrapiDynamicPage';
  cssId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  stack: Array<StrapiDynamicPageStack>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StrapiDynamicPageStack = Cta | ImageHero | SectionHero | StrapiFeaturedContent | StrapiHtmlBlock | StrapiTileGrid | StrapiTileList | Typography;

export type StrapiDynamicRoutesResponse = {
  __typename?: 'StrapiDynamicRoutesResponse';
  routes: Array<DynamicRoute>;
};

export type StrapiEvent = {
  __typename?: 'StrapiEvent';
  badge?: Maybe<Scalars['String']['output']>;
  callToAction?: Maybe<StrapiCallToAction>;
  clicksThrough?: Maybe<Scalars['Boolean']['output']>;
  comingSoon?: Maybe<Scalars['Boolean']['output']>;
  cssId?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  eventPrivacy?: Maybe<EventPrivacy>;
  gradient?: Maybe<StrapiGradient>;
  id: Scalars['Int']['output'];
  liveData?: Maybe<StrapiEventLiveData>;
  location?: Maybe<Scalars['String']['output']>;
  locked?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pageHero?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  surveys: Array<StrapiSurvey>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  ticketPortalInfo?: Maybe<StrapiTicketPortal>;
  trailer?: Maybe<Scalars['String']['output']>;
  type?: Maybe<StrapiEventType>;
  utcOffset?: Maybe<Scalars['Int']['output']>;
};

export type StrapiEventLiveData = {
  __typename?: 'StrapiEventLiveData';
  eventEndDate?: Maybe<Scalars['String']['output']>;
  eventStartDate?: Maybe<Scalars['String']['output']>;
  gameId?: Maybe<Scalars['String']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  preshowCloudflareId?: Maybe<Scalars['String']['output']>;
  vimeoId?: Maybe<Scalars['String']['output']>;
  vimeoUnlistedCode?: Maybe<Scalars['String']['output']>;
};

export enum StrapiEventType {
  LIVE_STREAM = 'LIVE_STREAM',
  MIXTAPE = 'MIXTAPE',
  RELIVE = 'RELIVE',
  SHOW = 'SHOW'
}

export type StrapiFeaturedContent = {
  __typename?: 'StrapiFeaturedContent';
  artwork?: Maybe<Scalars['String']['output']>;
  detailSlug?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StrapiGradient = {
  __typename?: 'StrapiGradient';
  angle?: Maybe<Scalars['Int']['output']>;
  colors: Array<StrapiColor>;
};

export type StrapiHtmlBlock = {
  __typename?: 'StrapiHTMLBlock';
  markup: Scalars['String']['output'];
};

export type StrapiNotifications = {
  __typename?: 'StrapiNotifications';
  group: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  stack: Array<StrapiDynamicPageStack>;
  title: Scalars['String']['output'];
};

export type StrapiPaginatedResults = {
  __typename?: 'StrapiPaginatedResults';
  pagination: StrapiPaginationByOffset;
  results: Array<StrapiAlbum>;
};

export type StrapiPaginationByOffset = {
  __typename?: 'StrapiPaginationByOffset';
  limit: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type StrapiQa = {
  __typename?: 'StrapiQA';
  body: Scalars['String']['output'];
  cssId?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type StrapiSurvey = {
  __typename?: 'StrapiSurvey';
  surveyId: Scalars['String']['output'];
  surveyTitle: Scalars['String']['output'];
};

export type StrapiTakeoverAlert = {
  __typename?: 'StrapiTakeoverAlert';
  artwork: Scalars['String']['output'];
  callToAction?: Maybe<StrapiCallToAction>;
  group: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type StrapiThoughtsLink = {
  __typename?: 'StrapiThoughtsLink';
  date?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Scalars['String']['output']>;
};

export enum StrapiTicketAuthType {
  CODE = 'CODE',
  LINK = 'LINK'
}

export type StrapiTicketPortal = {
  __typename?: 'StrapiTicketPortal';
  ticketAuthType?: Maybe<StrapiTicketAuthType>;
  ticketPortalBaseUrl?: Maybe<Scalars['String']['output']>;
  ticketProvider?: Maybe<StrapiTicketProvider>;
};

export enum StrapiTicketProvider {
  TICKETMASTER = 'TICKETMASTER',
  TIXR = 'TIXR'
}

export type StrapiTile = {
  __typename?: 'StrapiTile';
  clicksThrough?: Maybe<Scalars['Boolean']['output']>;
  comingSoon?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locked?: Maybe<Scalars['Boolean']['output']>;
  relationId?: Maybe<Scalars['Int']['output']>;
  relationType: StrapiTileRelationType;
  slug?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StrapiTileGrid = {
  __typename?: 'StrapiTileGrid';
  tiles: Array<StrapiTile>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StrapiTileList = {
  __typename?: 'StrapiTileList';
  detailLabel?: Maybe<Scalars['String']['output']>;
  detailSlug?: Maybe<Scalars['String']['output']>;
  tiles: Array<StrapiTile>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum StrapiTileRelationType {
  ALBUM = 'ALBUM',
  ARCADE = 'ARCADE',
  ARTIST = 'ARTIST',
  CHARITY = 'CHARITY',
  EVENT = 'EVENT'
}

export type StrapiTrack = {
  __typename?: 'StrapiTrack';
  artist?: Maybe<Scalars['String']['output']>;
  embedUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locked?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  visualCanvas?: Maybe<Scalars['String']['output']>;
};

export type StrapiTransmission = {
  __typename?: 'StrapiTransmission';
  body?: Maybe<Scalars['String']['output']>;
  cssId?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StrapiTreasureItem = {
  __typename?: 'StrapiTreasureItem';
  artwork?: Maybe<Scalars['String']['output']>;
  ctaLabel?: Maybe<Scalars['String']['output']>;
  ctaPath?: Maybe<Scalars['String']['output']>;
  ctaType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type StrapiTreasureSchedule = {
  __typename?: 'StrapiTreasureSchedule';
  id: Scalars['Int']['output'];
  scheduleDate?: Maybe<Scalars['String']['output']>;
  treasureItems: Array<StrapiTreasureItem>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  amount?: Maybe<Scalars['String']['output']>;
  createdAtUtc?: Maybe<Scalars['String']['output']>;
  currentPeriodEndUtc?: Maybe<Scalars['String']['output']>;
  currentPeriodStartUtc?: Maybe<Scalars['String']['output']>;
  customerDescription?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interval?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  startDateUtc?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Typography = {
  __typename?: 'Typography';
  body: Scalars['String']['output'];
  typeVariant: TypographyVariants;
};

export enum TypographyVariants {
  body = 'body',
  body2 = 'body2',
  button = 'button',
  caption = 'caption',
  caption2 = 'caption2',
  description = 'description',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  inherit = 'inherit',
  jumbo = 'jumbo',
  overline = 'overline',
  overline2 = 'overline2',
  subheading = 'subheading',
  subtitle = 'subtitle',
  subtitle2 = 'subtitle2',
  track_title = 'track_title'
}

export type User = {
  __typename?: 'User';
  cognitoUser?: Maybe<CognitoUser>;
  email: Scalars['String']['output'];
  ledger?: Maybe<Array<UserLedger>>;
  squareCustomer?: Maybe<SquareCustomer>;
  stripeSubscriptions?: Maybe<Array<StripeSubscription>>;
  treasure?: Maybe<UserTreasure>;
};

export type UserLedger = {
  __typename?: 'UserLedger';
  amount: Scalars['Int']['output'];
  details?: Maybe<Scalars['String']['output']>;
  pk: Scalars['String']['output'];
  sortKey: Scalars['String']['output'];
};

export type UserLedgerInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  credit?: InputMaybe<Scalars['Boolean']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sortKeyPrefix: Scalars['String']['input'];
  userSub: Scalars['String']['input'];
};

export type UserTreasure = {
  __typename?: 'UserTreasure';
  items: Array<StrapiTreasureItem>;
};

export type ActivateArcadeSessionMutationVariables = Exact<{
  gameId: Scalars['String']['input'];
}>;


export type ActivateArcadeSessionMutationGenerated = { __typename?: 'Mutation', activateArcadeSession: { __typename?: 'ArcadeSession', gameId: string, keyId: string, keyType?: string | undefined, sub: string } };

export type GetArcadeDetailsQueryVariables = Exact<{
  arcadeId: Scalars['Int']['input'];
}>;


export type GetArcadeDetailsQueryGenerated = { __typename?: 'Query', getArcadeDetails: { __typename?: 'StrapiArcade', clicksThrough?: boolean | undefined, comingSoon?: boolean | undefined, description?: string | undefined, gameId?: string | undefined, id: number, locked?: boolean | undefined, modalText?: string | undefined, name?: string | undefined, pageHero?: string | undefined, subtitle?: string | undefined, thumbnail?: string | undefined, cssId?: string | undefined } };

export type GetArcadesByGameIdQueryVariables = Exact<{
  gameIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetArcadesByGameIdQueryGenerated = { __typename?: 'Query', getArcadesByGameId: Array<{ __typename?: 'StrapiArcade', badge?: string | undefined, name?: string | undefined, gameId?: string | undefined }> };

export type VerifyArcadeSessionQueryVariables = Exact<{
  gameId: Scalars['String']['input'];
}>;


export type VerifyArcadeSessionQueryGenerated = { __typename?: 'Query', verifyArcadeSession: { __typename?: 'ArcadeSession', elapsedTime?: number | undefined, endTime?: number | undefined, gameId: string, keyId: string, keyType?: string | undefined, startTime?: number | undefined, sub: string } };

export type GetArtistQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetArtistQueryGenerated = { __typename?: 'Query', getArtist: { __typename?: 'StrapiArtist', artwork?: string | undefined, createdAt?: string | undefined, description?: string | undefined, donateUrl?: string | undefined, facebookUrl?: string | undefined, id: number, instagramUrl?: string | undefined, name?: string | undefined, publishedAt?: string | undefined, rank?: number | undefined, tikTokUrl?: string | undefined, title?: string | undefined, updatedAt?: string | undefined, websiteUrl?: string | undefined, cssId?: string | undefined, works: Array<{ __typename?: 'StrapiArtistWork', url?: string | undefined }> } };

export type GetArtistsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetArtistsQueryGenerated = { __typename?: 'Query', getArtists: Array<{ __typename?: 'StrapiArtist', artwork?: string | undefined, createdAt?: string | undefined, description?: string | undefined, donateUrl?: string | undefined, facebookUrl?: string | undefined, id: number, instagramUrl?: string | undefined, name?: string | undefined, publishedAt?: string | undefined, rank?: number | undefined, tikTokUrl?: string | undefined, title?: string | undefined, updatedAt?: string | undefined, websiteUrl?: string | undefined }> };

export type SendPasswordlessLoginLinkQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SendPasswordlessLoginLinkQueryGenerated = { __typename?: 'Query', sendPasswordlessLoginLink: boolean };

export type InviteGuestMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type InviteGuestMutationGenerated = { __typename?: 'Mutation', inviteGuest: { __typename?: 'InvitationResponse', success: boolean, invitationId?: string | undefined } };

export type AcceptInviteMutationVariables = Exact<{
  invitationId: Scalars['String']['input'];
}>;


export type AcceptInviteMutationGenerated = { __typename?: 'Mutation', acceptInvite: { __typename?: 'AcceptInviteResponse', success: boolean, username?: string | undefined, password?: string | undefined, error?: string | undefined } };

export type GetEventDetailsQueryVariables = Exact<{
  eventId: Scalars['Int']['input'];
}>;


export type GetEventDetailsQueryGenerated = { __typename?: 'Query', getEventDetails: { __typename?: 'StrapiEvent', clicksThrough?: boolean | undefined, comingSoon?: boolean | undefined, date?: string | undefined, description?: string | undefined, id: number, location?: string | undefined, locked?: boolean | undefined, name?: string | undefined, pageHero?: string | undefined, subtitle?: string | undefined, thumbnail?: string | undefined, trailer?: string | undefined, type?: StrapiEventType | undefined, utcOffset?: number | undefined, eventPrivacy?: EventPrivacy | undefined, cssId?: string | undefined, callToAction?: { __typename?: 'StrapiCallToAction', ctaLabel?: string | undefined, ctaPath?: string | undefined, ctaType?: string | undefined } | undefined, gradient?: { __typename?: 'StrapiGradient', angle?: number | undefined, colors: Array<{ __typename?: 'StrapiColor', hexColor?: string | undefined, position?: number | undefined }> } | undefined, liveData?: { __typename?: 'StrapiEventLiveData', eventEndDate?: string | undefined, eventStartDate?: string | undefined, gameId?: string | undefined, isTest?: boolean | undefined, name?: string | undefined, preshowCloudflareId?: string | undefined, vimeoId?: string | undefined, vimeoUnlistedCode?: string | undefined } | undefined, surveys: Array<{ __typename?: 'StrapiSurvey', surveyId: string, surveyTitle: string }>, ticketPortalInfo?: { __typename?: 'StrapiTicketPortal', ticketAuthType?: StrapiTicketAuthType | undefined, ticketPortalBaseUrl?: string | undefined, ticketProvider?: StrapiTicketProvider | undefined } | undefined } };

export type GetUpcomingEventQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUpcomingEventQueryGenerated = { __typename?: 'Query', getUpcomingEvent?: { __typename?: 'StrapiEvent', badge?: string | undefined, clicksThrough?: boolean | undefined, comingSoon?: boolean | undefined, date?: string | undefined, description?: string | undefined, id: number, location?: string | undefined, locked?: boolean | undefined, name?: string | undefined, pageHero?: string | undefined, subtitle?: string | undefined, thumbnail?: string | undefined, trailer?: string | undefined, type?: StrapiEventType | undefined, utcOffset?: number | undefined, callToAction?: { __typename?: 'StrapiCallToAction', ctaLabel?: string | undefined, ctaPath?: string | undefined, ctaType?: string | undefined } | undefined, gradient?: { __typename?: 'StrapiGradient', angle?: number | undefined, colors: Array<{ __typename?: 'StrapiColor', hexColor?: string | undefined, position?: number | undefined }> } | undefined, liveData?: { __typename?: 'StrapiEventLiveData', eventEndDate?: string | undefined, eventStartDate?: string | undefined, gameId?: string | undefined, isTest?: boolean | undefined, name?: string | undefined, preshowCloudflareId?: string | undefined, vimeoId?: string | undefined, vimeoUnlistedCode?: string | undefined } | undefined, surveys: Array<{ __typename?: 'StrapiSurvey', surveyId: string, surveyTitle: string }>, ticketPortalInfo?: { __typename?: 'StrapiTicketPortal', ticketAuthType?: StrapiTicketAuthType | undefined, ticketPortalBaseUrl?: string | undefined, ticketProvider?: StrapiTicketProvider | undefined } | undefined } | undefined };

export type GetAppVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppVersionQueryGenerated = { __typename?: 'Query', getAppVersion: string };

export type GetDynamicRoutesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDynamicRoutesQueryGenerated = { __typename?: 'Query', getDynamicRoutes: { __typename?: 'StrapiDynamicRoutesResponse', routes: Array<{ __typename?: 'DynamicRoute', slug: string, skeletonVariant?: SkeletonVariants | undefined, privacy?: DynamicRoutePrivacy | undefined }> } };

export type GetDynamicPageBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type GetDynamicPageBySlugQueryGenerated = { __typename?: 'Query', getDynamicPageBySlug: { __typename?: 'StrapiDynamicPage', description?: string | undefined, id: number, slug?: string | undefined, cssId?: string | undefined, title?: string | undefined, stack: Array<{ __typename?: 'CTA', ctaType: CtaTypes, ctaVariant: CtaVariants, ctaPath: string, ctaLabel: string } | { __typename?: 'ImageHero', headline?: string | undefined, artwork?: string | undefined } | { __typename?: 'SectionHero', title?: string | undefined, background: string } | { __typename: 'StrapiFeaturedContent', artwork?: string | undefined, detailSlug?: string | undefined, headline?: string | undefined, id: number, subtitle?: string | undefined, title?: string | undefined } | { __typename: 'StrapiHTMLBlock', markup: string } | { __typename: 'StrapiTileGrid', title?: string | undefined, tiles: Array<{ __typename?: 'StrapiTile', clicksThrough?: boolean | undefined, comingSoon?: boolean | undefined, description?: string | undefined, id: number, locked?: boolean | undefined, relationId?: number | undefined, relationType: StrapiTileRelationType, slug?: string | undefined, thumbnail?: string | undefined, title?: string | undefined }> } | { __typename: 'StrapiTileList', detailLabel?: string | undefined, detailSlug?: string | undefined, title?: string | undefined, tiles: Array<{ __typename?: 'StrapiTile', clicksThrough?: boolean | undefined, comingSoon?: boolean | undefined, description?: string | undefined, id: number, locked?: boolean | undefined, relationId?: number | undefined, relationType: StrapiTileRelationType, slug?: string | undefined, thumbnail?: string | undefined, title?: string | undefined }> } | { __typename?: 'Typography', typeVariant: TypographyVariants, body: string }> } };

export type GetHomeCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomeCardsQueryGenerated = { __typename?: 'Query', getHomeCards: Array<{ __typename?: 'HomeCard', id?: string | undefined, image?: string | undefined, title?: string | undefined, url?: string | undefined, cssId?: string | undefined, order: number }> };

export type ActivateAlbumSessionMutationVariables = Exact<{
  albumId: Scalars['Int']['input'];
}>;


export type ActivateAlbumSessionMutationGenerated = { __typename?: 'Mutation', activateAlbumSession: { __typename?: 'AlbumSession', albumId: number, keyId: string, keyType?: string | undefined, sub: string } };

export type GetAlbumQueryVariables = Exact<{
  albumId: Scalars['Int']['input'];
}>;


export type GetAlbumQueryGenerated = { __typename?: 'Query', getAlbum: { __typename?: 'StrapiAlbum', artist?: string | undefined, category: StrapiAlbumCategory, clicksThrough: boolean, comingSoon: boolean, coverArt?: string | undefined, id: number, locked: boolean, rank?: number | undefined, title?: string | undefined, keyPrice?: number | undefined, cssId?: string | undefined, gradient?: { __typename?: 'StrapiGradient', angle?: number | undefined, colors: Array<{ __typename?: 'StrapiColor', hexColor?: string | undefined, position?: number | undefined }> } | undefined, trackList: Array<{ __typename?: 'StrapiTrack', artist?: string | undefined, embedUrl?: string | undefined, id: number, locked?: boolean | undefined, notes?: string | undefined, title?: string | undefined, visualCanvas?: string | undefined }>, html?: { __typename: 'StrapiHTMLBlock', markup: string } | undefined } };

export type GetAlbumsByCategoryQueryVariables = Exact<{
  category: StrapiAlbumCategory;
  start?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetAlbumsByCategoryQueryGenerated = { __typename?: 'Query', getAlbumsByCategory: { __typename?: 'StrapiPaginatedResults', results: Array<{ __typename?: 'StrapiAlbum', artist?: string | undefined, category: StrapiAlbumCategory, clicksThrough: boolean, comingSoon: boolean, coverArt?: string | undefined, id: number, locked: boolean, rank?: number | undefined, title?: string | undefined, keyPrice?: number | undefined }>, pagination: { __typename?: 'StrapiPaginationByOffset', start: number, limit: number, total: number } } };

export type VerifyAlbumSessionQueryVariables = Exact<{
  albumId: Scalars['Int']['input'];
}>;


export type VerifyAlbumSessionQueryGenerated = { __typename?: 'Query', verifyAlbumSession: { __typename?: 'AlbumSession', elapsedTime?: number | undefined, endTime?: number | undefined, albumId: number, keyId: string, keyType?: string | undefined, startTime?: number | undefined, sub: string } };

export type GetNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationsQueryGenerated = { __typename?: 'Query', getNotifications: { __typename?: 'NotificationsResponse', unreadCount: number, notifications: Array<{ __typename?: 'Notification', key: string, id: number, title?: string | undefined, type: NotificationType, status?: NotificationStatus | undefined }> } };

export type GetNotificationDetailsQueryVariables = Exact<{
  noteId: Scalars['Int']['input'];
}>;


export type GetNotificationDetailsQueryGenerated = { __typename?: 'Query', getNotificationDetails: { __typename?: 'StrapiNotifications', id: number, group: string, title: string, stack: Array<{ __typename?: 'CTA', ctaType: CtaTypes, ctaVariant: CtaVariants, ctaPath: string, ctaLabel: string } | { __typename?: 'ImageHero', headline?: string | undefined, artwork?: string | undefined } | { __typename?: 'SectionHero', title?: string | undefined, background: string } | { __typename: 'StrapiFeaturedContent', artwork?: string | undefined, detailSlug?: string | undefined, headline?: string | undefined, id: number, subtitle?: string | undefined, title?: string | undefined } | { __typename: 'StrapiHTMLBlock', markup: string } | { __typename: 'StrapiTileGrid', title?: string | undefined, tiles: Array<{ __typename?: 'StrapiTile', clicksThrough?: boolean | undefined, comingSoon?: boolean | undefined, description?: string | undefined, id: number, locked?: boolean | undefined, relationId?: number | undefined, relationType: StrapiTileRelationType, slug?: string | undefined, thumbnail?: string | undefined, title?: string | undefined }> } | { __typename: 'StrapiTileList', detailLabel?: string | undefined, detailSlug?: string | undefined, tiles: Array<{ __typename?: 'StrapiTile', clicksThrough?: boolean | undefined, comingSoon?: boolean | undefined, description?: string | undefined, id: number, locked?: boolean | undefined, relationId?: number | undefined, relationType: StrapiTileRelationType, slug?: string | undefined, thumbnail?: string | undefined, title?: string | undefined }> } | { __typename?: 'Typography', typeVariant: TypographyVariants, body: string }> } };

export type GetTakeoverQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetTakeoverQueryGenerated = { __typename?: 'Query', getTakeover: { __typename?: 'StrapiTakeoverAlert', artwork: string, group: string, id: number, callToAction?: { __typename?: 'StrapiCallToAction', ctaLabel?: string | undefined, ctaPath?: string | undefined, ctaType?: string | undefined } | undefined } };

export type MarkNotificationAsReadMutationVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type MarkNotificationAsReadMutationGenerated = { __typename?: 'Mutation', markNotificationAsRead: { __typename?: 'ReadNotificationsResponse', status: string } };

export type GetTicketmasterPresaleCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTicketmasterPresaleCodeQueryGenerated = { __typename?: 'Query', getTicketmasterPresaleCode: string };

export type GetTixrPrivateUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTixrPrivateUrlQueryGenerated = { __typename?: 'Query', getTixrPrivateUrl: string };

export type GetTransmissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTransmissionsQueryGenerated = { __typename?: 'Query', getTransmissions: Array<{ __typename?: 'StrapiTransmission', body?: string | undefined, date?: string | undefined, id: number, image?: string | undefined, title?: string | undefined, cssId?: string | undefined }> };

export type GetThoughtsLinksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetThoughtsLinksQueryGenerated = { __typename?: 'Query', getThoughtsLinks: Array<{ __typename?: 'StrapiThoughtsLink', date?: string | undefined, description?: string | undefined, id: number, title?: string | undefined, url?: string | undefined, variant?: string | undefined }> };

export type GetQAsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQAsQueryGenerated = { __typename?: 'Query', getQAs: Array<{ __typename?: 'StrapiQA', id: number, body: string, date?: string | undefined, title: string, cssId?: string | undefined }> };

export type GetCharitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCharitiesQueryGenerated = { __typename?: 'Query', getCharities: Array<{ __typename?: 'StrapiCharity', description?: string | undefined, donateUrl?: string | undefined, id: number, instagramUrl?: string | undefined, name?: string | undefined, pageHero?: string | undefined, rank?: number | undefined, thumbnail?: string | undefined, title?: string | undefined, websiteUrl?: string | undefined, works?: string | undefined }> };

export type GetCharityQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetCharityQueryGenerated = { __typename?: 'Query', getCharity: { __typename?: 'StrapiCharity', description?: string | undefined, donateUrl?: string | undefined, id: number, instagramUrl?: string | undefined, name?: string | undefined, pageHero?: string | undefined, rank?: number | undefined, thumbnail?: string | undefined, title?: string | undefined, websiteUrl?: string | undefined, works?: string | undefined, cssId?: string | undefined } };

export type GetSubscriptionsQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetSubscriptionsQueryGenerated = { __typename?: 'Query', getUser: { __typename?: 'User', squareCustomer?: { __typename?: 'SquareCustomer', emailAddress?: string | undefined, familyName?: string | undefined, givenName?: string | undefined, id?: string | undefined, subscriptions?: Array<{ __typename?: 'SquareSubscription', canceledDate?: string | undefined, chargedThroughDate?: string | undefined, createdAt?: string | undefined, customerId?: string | undefined, id?: string | undefined, locationId?: string | undefined, planVariationId?: string | undefined, startDate?: string | undefined, status?: string | undefined, timezone?: string | undefined }> | undefined } | undefined, stripeSubscriptions?: Array<{ __typename?: 'StripeSubscription', amount?: string | undefined, createdAtUtc?: string | undefined, currentPeriodEndUtc?: string | undefined, currentPeriodStartUtc?: string | undefined, customerDescription?: string | undefined, customerId?: string | undefined, customerName?: string | undefined, email?: string | undefined, id?: string | undefined, interval?: string | undefined, plan?: string | undefined, quantity?: string | undefined, startDateUtc?: string | undefined, status?: string | undefined }> | undefined } };

export type GetTreasureQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetTreasureQueryGenerated = { __typename?: 'Query', getUser: { __typename?: 'User', treasure?: { __typename?: 'UserTreasure', items: Array<{ __typename?: 'StrapiTreasureItem', id: number, name?: string | undefined, description?: string | undefined, artwork?: string | undefined, ctaPath?: string | undefined, ctaLabel?: string | undefined, ctaType?: string | undefined, hidden?: boolean | undefined }> } | undefined } };

export type GetUserCardsOnFileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserCardsOnFileQueryGenerated = { __typename?: 'Query', getCardsOnFile: { __typename?: 'ListCardsResponse', cards: Array<{ __typename?: 'Card', id?: string | undefined, last4?: string | undefined, expMonth?: any | undefined, expYear?: any | undefined, cardBrand?: string | undefined, enabled?: boolean | undefined }> } };

export type GetUserKeyBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserKeyBalanceQueryGenerated = { __typename?: 'Query', getUserKeyBalance: number };

export type GetUserGuestPassBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserGuestPassBalanceQueryGenerated = { __typename?: 'Query', getUserGuestPassBalance: number };

export type GetUserHighScoresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserHighScoresQueryGenerated = { __typename?: 'Query', getUserHighScores: Array<{ __typename?: 'EventScore', event: string, score: number }> };

export type ProcessSubscriptionMutationVariables = Exact<{
  input: ProcessSubscriptionInput;
}>;


export type ProcessSubscriptionMutationGenerated = { __typename?: 'Mutation', processSubscription: { __typename?: 'SquareSubscriptionResponse', subscriptionId?: string | undefined } };

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscriptionMutationGenerated = { __typename?: 'Mutation', cancelSubscription: { __typename?: 'SquareSubscription', canceledDate?: string | undefined, chargedThroughDate?: string | undefined, createdAt?: string | undefined, customerId?: string | undefined, id?: string | undefined, locationId?: string | undefined, startDate?: string | undefined, status?: string | undefined, timezone?: string | undefined } };

export type AddPaymentMethodMutationVariables = Exact<{
  input: AddPaymentInput;
}>;


export type AddPaymentMethodMutationGenerated = { __typename?: 'Mutation', addPaymentMethod: { __typename?: 'Card', id?: string | undefined, last4?: string | undefined, expYear?: any | undefined, expMonth?: any | undefined, cardBrand?: string | undefined, enabled?: boolean | undefined } };

export type DisablePaymentMethodMutationVariables = Exact<{
  cardId: Scalars['String']['input'];
}>;


export type DisablePaymentMethodMutationGenerated = { __typename?: 'Mutation', disablePaymentMethod: { __typename?: 'Card', id?: string | undefined } };

export type ProcessKeyPurchaseMutationVariables = Exact<{
  input: ProcessKeyPurchaseInput;
}>;


export type ProcessKeyPurchaseMutationGenerated = { __typename?: 'Mutation', processKeyPurchase: number };

export type PurchaseGuestPassMutationVariables = Exact<{ [key: string]: never; }>;


export type PurchaseGuestPassMutationGenerated = { __typename?: 'Mutation', purchaseGuestPass: boolean };

export type IsMonthlySubQueryVariables = Exact<{ [key: string]: never; }>;


export type IsMonthlySubQueryGenerated = { __typename?: 'Query', isMonthlySub: boolean };

export type UpgradeSubscriptionMutationVariables = Exact<{
  cardId: Scalars['String']['input'];
}>;


export type UpgradeSubscriptionMutationGenerated = { __typename?: 'Mutation', upgradeSubscription: { __typename?: 'SquareSubscription', id?: string | undefined } };

export type GetPlanDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlanDetailsQueryGenerated = { __typename?: 'Query', getPlanDetails: Array<{ __typename?: 'PlanDetail', id?: string | undefined, title?: string | undefined, type?: PlanDetailType | undefined }> };

export type GetMyWalletQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyWalletQueryGenerated = { __typename?: 'Query', getMyWallet: Array<{ __typename?: 'UserLedger', pk: string, amount: number, sortKey: string, details?: string | undefined }> };


export const ActivateArcadeSessionDocumentGenerated = gql`
    mutation ActivateArcadeSession($gameId: String!) {
  activateArcadeSession(gameId: $gameId) {
    gameId
    keyId
    keyType
    sub
  }
}
    `;
export type ActivateArcadeSessionMutationFn = Apollo.MutationFunction<ActivateArcadeSessionMutationGenerated, ActivateArcadeSessionMutationVariables>;

/**
 * __useActivateArcadeSessionMutationGenerated__
 *
 * To run a mutation, you first call `useActivateArcadeSessionMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateArcadeSessionMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateArcadeSessionMutationGenerated, { data, loading, error }] = useActivateArcadeSessionMutationGenerated({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useActivateArcadeSessionMutationGenerated(baseOptions?: Apollo.MutationHookOptions<ActivateArcadeSessionMutationGenerated, ActivateArcadeSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateArcadeSessionMutationGenerated, ActivateArcadeSessionMutationVariables>(ActivateArcadeSessionDocumentGenerated, options);
      }
export type ActivateArcadeSessionMutationGeneratedHookResult = ReturnType<typeof useActivateArcadeSessionMutationGenerated>;
export type ActivateArcadeSessionMutationResult = Apollo.MutationResult<ActivateArcadeSessionMutationGenerated>;
export type ActivateArcadeSessionMutationOptions = Apollo.BaseMutationOptions<ActivateArcadeSessionMutationGenerated, ActivateArcadeSessionMutationVariables>;
export const GetArcadeDetailsDocumentGenerated = gql`
    query GetArcadeDetails($arcadeId: Int!) {
  getArcadeDetails(id: $arcadeId) {
    clicksThrough
    comingSoon
    description
    gameId
    id
    locked
    modalText
    name
    pageHero
    subtitle
    thumbnail
    cssId
  }
}
    `;

/**
 * __useGetArcadeDetailsQueryGenerated__
 *
 * To run a query within a React component, call `useGetArcadeDetailsQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetArcadeDetailsQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArcadeDetailsQueryGenerated({
 *   variables: {
 *      arcadeId: // value for 'arcadeId'
 *   },
 * });
 */
export function useGetArcadeDetailsQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetArcadeDetailsQueryGenerated, GetArcadeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArcadeDetailsQueryGenerated, GetArcadeDetailsQueryVariables>(GetArcadeDetailsDocumentGenerated, options);
      }
export function useGetArcadeDetailsLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetArcadeDetailsQueryGenerated, GetArcadeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArcadeDetailsQueryGenerated, GetArcadeDetailsQueryVariables>(GetArcadeDetailsDocumentGenerated, options);
        }
export function useGetArcadeDetailsSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArcadeDetailsQueryGenerated, GetArcadeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArcadeDetailsQueryGenerated, GetArcadeDetailsQueryVariables>(GetArcadeDetailsDocumentGenerated, options);
        }
export type GetArcadeDetailsQueryGeneratedHookResult = ReturnType<typeof useGetArcadeDetailsQueryGenerated>;
export type GetArcadeDetailsLazyQueryGeneratedHookResult = ReturnType<typeof useGetArcadeDetailsLazyQueryGenerated>;
export type GetArcadeDetailsSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetArcadeDetailsSuspenseQueryGenerated>;
export type GetArcadeDetailsQueryResult = Apollo.QueryResult<GetArcadeDetailsQueryGenerated, GetArcadeDetailsQueryVariables>;
export const GetArcadesByGameIdDocumentGenerated = gql`
    query GetArcadesByGameId($gameIds: [String!]!) {
  getArcadesByGameId(gameIds: $gameIds) {
    badge
    name
    gameId
  }
}
    `;

/**
 * __useGetArcadesByGameIdQueryGenerated__
 *
 * To run a query within a React component, call `useGetArcadesByGameIdQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetArcadesByGameIdQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArcadesByGameIdQueryGenerated({
 *   variables: {
 *      gameIds: // value for 'gameIds'
 *   },
 * });
 */
export function useGetArcadesByGameIdQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetArcadesByGameIdQueryGenerated, GetArcadesByGameIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArcadesByGameIdQueryGenerated, GetArcadesByGameIdQueryVariables>(GetArcadesByGameIdDocumentGenerated, options);
      }
export function useGetArcadesByGameIdLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetArcadesByGameIdQueryGenerated, GetArcadesByGameIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArcadesByGameIdQueryGenerated, GetArcadesByGameIdQueryVariables>(GetArcadesByGameIdDocumentGenerated, options);
        }
export function useGetArcadesByGameIdSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArcadesByGameIdQueryGenerated, GetArcadesByGameIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArcadesByGameIdQueryGenerated, GetArcadesByGameIdQueryVariables>(GetArcadesByGameIdDocumentGenerated, options);
        }
export type GetArcadesByGameIdQueryGeneratedHookResult = ReturnType<typeof useGetArcadesByGameIdQueryGenerated>;
export type GetArcadesByGameIdLazyQueryGeneratedHookResult = ReturnType<typeof useGetArcadesByGameIdLazyQueryGenerated>;
export type GetArcadesByGameIdSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetArcadesByGameIdSuspenseQueryGenerated>;
export type GetArcadesByGameIdQueryResult = Apollo.QueryResult<GetArcadesByGameIdQueryGenerated, GetArcadesByGameIdQueryVariables>;
export const VerifyArcadeSessionDocumentGenerated = gql`
    query VerifyArcadeSession($gameId: String!) {
  verifyArcadeSession(gameId: $gameId) {
    elapsedTime
    endTime
    gameId
    keyId
    keyType
    startTime
    sub
  }
}
    `;

/**
 * __useVerifyArcadeSessionQueryGenerated__
 *
 * To run a query within a React component, call `useVerifyArcadeSessionQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useVerifyArcadeSessionQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyArcadeSessionQueryGenerated({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useVerifyArcadeSessionQueryGenerated(baseOptions: Apollo.QueryHookOptions<VerifyArcadeSessionQueryGenerated, VerifyArcadeSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyArcadeSessionQueryGenerated, VerifyArcadeSessionQueryVariables>(VerifyArcadeSessionDocumentGenerated, options);
      }
export function useVerifyArcadeSessionLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<VerifyArcadeSessionQueryGenerated, VerifyArcadeSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyArcadeSessionQueryGenerated, VerifyArcadeSessionQueryVariables>(VerifyArcadeSessionDocumentGenerated, options);
        }
export function useVerifyArcadeSessionSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<VerifyArcadeSessionQueryGenerated, VerifyArcadeSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerifyArcadeSessionQueryGenerated, VerifyArcadeSessionQueryVariables>(VerifyArcadeSessionDocumentGenerated, options);
        }
export type VerifyArcadeSessionQueryGeneratedHookResult = ReturnType<typeof useVerifyArcadeSessionQueryGenerated>;
export type VerifyArcadeSessionLazyQueryGeneratedHookResult = ReturnType<typeof useVerifyArcadeSessionLazyQueryGenerated>;
export type VerifyArcadeSessionSuspenseQueryGeneratedHookResult = ReturnType<typeof useVerifyArcadeSessionSuspenseQueryGenerated>;
export type VerifyArcadeSessionQueryResult = Apollo.QueryResult<VerifyArcadeSessionQueryGenerated, VerifyArcadeSessionQueryVariables>;
export const GetArtistDocumentGenerated = gql`
    query GetArtist($id: Int!) {
  getArtist(id: $id) {
    artwork
    createdAt
    description
    donateUrl
    facebookUrl
    id
    instagramUrl
    name
    publishedAt
    rank
    tikTokUrl
    title
    updatedAt
    websiteUrl
    works {
      url
    }
    cssId
  }
}
    `;

/**
 * __useGetArtistQueryGenerated__
 *
 * To run a query within a React component, call `useGetArtistQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistQueryGenerated({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtistQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetArtistQueryGenerated, GetArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistQueryGenerated, GetArtistQueryVariables>(GetArtistDocumentGenerated, options);
      }
export function useGetArtistLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistQueryGenerated, GetArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistQueryGenerated, GetArtistQueryVariables>(GetArtistDocumentGenerated, options);
        }
export function useGetArtistSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistQueryGenerated, GetArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistQueryGenerated, GetArtistQueryVariables>(GetArtistDocumentGenerated, options);
        }
export type GetArtistQueryGeneratedHookResult = ReturnType<typeof useGetArtistQueryGenerated>;
export type GetArtistLazyQueryGeneratedHookResult = ReturnType<typeof useGetArtistLazyQueryGenerated>;
export type GetArtistSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetArtistSuspenseQueryGenerated>;
export type GetArtistQueryResult = Apollo.QueryResult<GetArtistQueryGenerated, GetArtistQueryVariables>;
export const GetArtistsDocumentGenerated = gql`
    query GetArtists {
  getArtists {
    artwork
    createdAt
    description
    donateUrl
    facebookUrl
    id
    instagramUrl
    name
    publishedAt
    rank
    tikTokUrl
    title
    updatedAt
    websiteUrl
  }
}
    `;

/**
 * __useGetArtistsQueryGenerated__
 *
 * To run a query within a React component, call `useGetArtistsQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetArtistsQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetArtistsQueryGenerated, GetArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistsQueryGenerated, GetArtistsQueryVariables>(GetArtistsDocumentGenerated, options);
      }
export function useGetArtistsLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistsQueryGenerated, GetArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistsQueryGenerated, GetArtistsQueryVariables>(GetArtistsDocumentGenerated, options);
        }
export function useGetArtistsSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistsQueryGenerated, GetArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistsQueryGenerated, GetArtistsQueryVariables>(GetArtistsDocumentGenerated, options);
        }
export type GetArtistsQueryGeneratedHookResult = ReturnType<typeof useGetArtistsQueryGenerated>;
export type GetArtistsLazyQueryGeneratedHookResult = ReturnType<typeof useGetArtistsLazyQueryGenerated>;
export type GetArtistsSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetArtistsSuspenseQueryGenerated>;
export type GetArtistsQueryResult = Apollo.QueryResult<GetArtistsQueryGenerated, GetArtistsQueryVariables>;
export const SendPasswordlessLoginLinkDocumentGenerated = gql`
    query SendPasswordlessLoginLink($email: String!) {
  sendPasswordlessLoginLink(email: $email)
}
    `;

/**
 * __useSendPasswordlessLoginLinkQueryGenerated__
 *
 * To run a query within a React component, call `useSendPasswordlessLoginLinkQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordlessLoginLinkQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendPasswordlessLoginLinkQueryGenerated({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordlessLoginLinkQueryGenerated(baseOptions: Apollo.QueryHookOptions<SendPasswordlessLoginLinkQueryGenerated, SendPasswordlessLoginLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendPasswordlessLoginLinkQueryGenerated, SendPasswordlessLoginLinkQueryVariables>(SendPasswordlessLoginLinkDocumentGenerated, options);
      }
export function useSendPasswordlessLoginLinkLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<SendPasswordlessLoginLinkQueryGenerated, SendPasswordlessLoginLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendPasswordlessLoginLinkQueryGenerated, SendPasswordlessLoginLinkQueryVariables>(SendPasswordlessLoginLinkDocumentGenerated, options);
        }
export function useSendPasswordlessLoginLinkSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<SendPasswordlessLoginLinkQueryGenerated, SendPasswordlessLoginLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SendPasswordlessLoginLinkQueryGenerated, SendPasswordlessLoginLinkQueryVariables>(SendPasswordlessLoginLinkDocumentGenerated, options);
        }
export type SendPasswordlessLoginLinkQueryGeneratedHookResult = ReturnType<typeof useSendPasswordlessLoginLinkQueryGenerated>;
export type SendPasswordlessLoginLinkLazyQueryGeneratedHookResult = ReturnType<typeof useSendPasswordlessLoginLinkLazyQueryGenerated>;
export type SendPasswordlessLoginLinkSuspenseQueryGeneratedHookResult = ReturnType<typeof useSendPasswordlessLoginLinkSuspenseQueryGenerated>;
export type SendPasswordlessLoginLinkQueryResult = Apollo.QueryResult<SendPasswordlessLoginLinkQueryGenerated, SendPasswordlessLoginLinkQueryVariables>;
export const InviteGuestDocumentGenerated = gql`
    mutation InviteGuest($email: String!) {
  inviteGuest(email: $email) {
    success
    invitationId
  }
}
    `;
export type InviteGuestMutationFn = Apollo.MutationFunction<InviteGuestMutationGenerated, InviteGuestMutationVariables>;

/**
 * __useInviteGuestMutationGenerated__
 *
 * To run a mutation, you first call `useInviteGuestMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteGuestMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteGuestMutationGenerated, { data, loading, error }] = useInviteGuestMutationGenerated({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteGuestMutationGenerated(baseOptions?: Apollo.MutationHookOptions<InviteGuestMutationGenerated, InviteGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteGuestMutationGenerated, InviteGuestMutationVariables>(InviteGuestDocumentGenerated, options);
      }
export type InviteGuestMutationGeneratedHookResult = ReturnType<typeof useInviteGuestMutationGenerated>;
export type InviteGuestMutationResult = Apollo.MutationResult<InviteGuestMutationGenerated>;
export type InviteGuestMutationOptions = Apollo.BaseMutationOptions<InviteGuestMutationGenerated, InviteGuestMutationVariables>;
export const AcceptInviteDocumentGenerated = gql`
    mutation AcceptInvite($invitationId: String!) {
  acceptInvite(invitationId: $invitationId) {
    success
    username
    password
    error
  }
}
    `;
export type AcceptInviteMutationFn = Apollo.MutationFunction<AcceptInviteMutationGenerated, AcceptInviteMutationVariables>;

/**
 * __useAcceptInviteMutationGenerated__
 *
 * To run a mutation, you first call `useAcceptInviteMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutationGenerated, { data, loading, error }] = useAcceptInviteMutationGenerated({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useAcceptInviteMutationGenerated(baseOptions?: Apollo.MutationHookOptions<AcceptInviteMutationGenerated, AcceptInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInviteMutationGenerated, AcceptInviteMutationVariables>(AcceptInviteDocumentGenerated, options);
      }
export type AcceptInviteMutationGeneratedHookResult = ReturnType<typeof useAcceptInviteMutationGenerated>;
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInviteMutationGenerated>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<AcceptInviteMutationGenerated, AcceptInviteMutationVariables>;
export const GetEventDetailsDocumentGenerated = gql`
    query GetEventDetails($eventId: Int!) {
  getEventDetails(eventId: $eventId) {
    callToAction {
      ctaLabel
      ctaPath
      ctaType
    }
    clicksThrough
    comingSoon
    date
    description
    gradient {
      angle
      colors {
        hexColor
        position
      }
    }
    id
    liveData {
      eventEndDate
      eventStartDate
      gameId
      isTest
      name
      preshowCloudflareId
      vimeoId
      vimeoUnlistedCode
    }
    location
    locked
    name
    pageHero
    subtitle
    surveys {
      surveyId
      surveyTitle
    }
    thumbnail
    ticketPortalInfo {
      ticketAuthType
      ticketPortalBaseUrl
      ticketProvider
    }
    trailer
    type
    utcOffset
    eventPrivacy
    cssId
  }
}
    `;

/**
 * __useGetEventDetailsQueryGenerated__
 *
 * To run a query within a React component, call `useGetEventDetailsQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetEventDetailsQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventDetailsQueryGenerated({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetEventDetailsQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetEventDetailsQueryGenerated, GetEventDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventDetailsQueryGenerated, GetEventDetailsQueryVariables>(GetEventDetailsDocumentGenerated, options);
      }
export function useGetEventDetailsLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetEventDetailsQueryGenerated, GetEventDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventDetailsQueryGenerated, GetEventDetailsQueryVariables>(GetEventDetailsDocumentGenerated, options);
        }
export function useGetEventDetailsSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEventDetailsQueryGenerated, GetEventDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventDetailsQueryGenerated, GetEventDetailsQueryVariables>(GetEventDetailsDocumentGenerated, options);
        }
export type GetEventDetailsQueryGeneratedHookResult = ReturnType<typeof useGetEventDetailsQueryGenerated>;
export type GetEventDetailsLazyQueryGeneratedHookResult = ReturnType<typeof useGetEventDetailsLazyQueryGenerated>;
export type GetEventDetailsSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetEventDetailsSuspenseQueryGenerated>;
export type GetEventDetailsQueryResult = Apollo.QueryResult<GetEventDetailsQueryGenerated, GetEventDetailsQueryVariables>;
export const GetUpcomingEventDocumentGenerated = gql`
    query GetUpcomingEvent {
  getUpcomingEvent {
    badge
    callToAction {
      ctaLabel
      ctaPath
      ctaType
    }
    clicksThrough
    comingSoon
    date
    description
    gradient {
      angle
      colors {
        hexColor
        position
      }
    }
    id
    liveData {
      eventEndDate
      eventStartDate
      gameId
      isTest
      name
      preshowCloudflareId
      vimeoId
      vimeoUnlistedCode
    }
    location
    locked
    name
    pageHero
    subtitle
    surveys {
      surveyId
      surveyTitle
    }
    thumbnail
    ticketPortalInfo {
      ticketAuthType
      ticketPortalBaseUrl
      ticketProvider
    }
    trailer
    type
    utcOffset
  }
}
    `;

/**
 * __useGetUpcomingEventQueryGenerated__
 *
 * To run a query within a React component, call `useGetUpcomingEventQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingEventQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingEventQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetUpcomingEventQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetUpcomingEventQueryGenerated, GetUpcomingEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpcomingEventQueryGenerated, GetUpcomingEventQueryVariables>(GetUpcomingEventDocumentGenerated, options);
      }
export function useGetUpcomingEventLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetUpcomingEventQueryGenerated, GetUpcomingEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpcomingEventQueryGenerated, GetUpcomingEventQueryVariables>(GetUpcomingEventDocumentGenerated, options);
        }
export function useGetUpcomingEventSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUpcomingEventQueryGenerated, GetUpcomingEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUpcomingEventQueryGenerated, GetUpcomingEventQueryVariables>(GetUpcomingEventDocumentGenerated, options);
        }
export type GetUpcomingEventQueryGeneratedHookResult = ReturnType<typeof useGetUpcomingEventQueryGenerated>;
export type GetUpcomingEventLazyQueryGeneratedHookResult = ReturnType<typeof useGetUpcomingEventLazyQueryGenerated>;
export type GetUpcomingEventSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetUpcomingEventSuspenseQueryGenerated>;
export type GetUpcomingEventQueryResult = Apollo.QueryResult<GetUpcomingEventQueryGenerated, GetUpcomingEventQueryVariables>;
export const GetAppVersionDocumentGenerated = gql`
    query GetAppVersion {
  getAppVersion
}
    `;

/**
 * __useGetAppVersionQueryGenerated__
 *
 * To run a query within a React component, call `useGetAppVersionQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetAppVersionQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppVersionQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetAppVersionQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetAppVersionQueryGenerated, GetAppVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppVersionQueryGenerated, GetAppVersionQueryVariables>(GetAppVersionDocumentGenerated, options);
      }
export function useGetAppVersionLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetAppVersionQueryGenerated, GetAppVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppVersionQueryGenerated, GetAppVersionQueryVariables>(GetAppVersionDocumentGenerated, options);
        }
export function useGetAppVersionSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAppVersionQueryGenerated, GetAppVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAppVersionQueryGenerated, GetAppVersionQueryVariables>(GetAppVersionDocumentGenerated, options);
        }
export type GetAppVersionQueryGeneratedHookResult = ReturnType<typeof useGetAppVersionQueryGenerated>;
export type GetAppVersionLazyQueryGeneratedHookResult = ReturnType<typeof useGetAppVersionLazyQueryGenerated>;
export type GetAppVersionSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetAppVersionSuspenseQueryGenerated>;
export type GetAppVersionQueryResult = Apollo.QueryResult<GetAppVersionQueryGenerated, GetAppVersionQueryVariables>;
export const GetDynamicRoutesDocumentGenerated = gql`
    query GetDynamicRoutes {
  getDynamicRoutes {
    routes {
      slug
      skeletonVariant
      privacy
    }
  }
}
    `;

/**
 * __useGetDynamicRoutesQueryGenerated__
 *
 * To run a query within a React component, call `useGetDynamicRoutesQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicRoutesQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicRoutesQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetDynamicRoutesQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetDynamicRoutesQueryGenerated, GetDynamicRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDynamicRoutesQueryGenerated, GetDynamicRoutesQueryVariables>(GetDynamicRoutesDocumentGenerated, options);
      }
export function useGetDynamicRoutesLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetDynamicRoutesQueryGenerated, GetDynamicRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDynamicRoutesQueryGenerated, GetDynamicRoutesQueryVariables>(GetDynamicRoutesDocumentGenerated, options);
        }
export function useGetDynamicRoutesSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDynamicRoutesQueryGenerated, GetDynamicRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDynamicRoutesQueryGenerated, GetDynamicRoutesQueryVariables>(GetDynamicRoutesDocumentGenerated, options);
        }
export type GetDynamicRoutesQueryGeneratedHookResult = ReturnType<typeof useGetDynamicRoutesQueryGenerated>;
export type GetDynamicRoutesLazyQueryGeneratedHookResult = ReturnType<typeof useGetDynamicRoutesLazyQueryGenerated>;
export type GetDynamicRoutesSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetDynamicRoutesSuspenseQueryGenerated>;
export type GetDynamicRoutesQueryResult = Apollo.QueryResult<GetDynamicRoutesQueryGenerated, GetDynamicRoutesQueryVariables>;
export const GetDynamicPageBySlugDocumentGenerated = gql`
    query GetDynamicPageBySlug($slug: String!) {
  getDynamicPageBySlug(slug: $slug) {
    description
    id
    slug
    cssId
    stack {
      ... on StrapiFeaturedContent {
        __typename
        artwork
        detailSlug
        headline
        id
        subtitle
        title
      }
      ... on StrapiHTMLBlock {
        __typename
        markup
      }
      ... on CTA {
        ctaType
        ctaVariant
        ctaPath
        ctaLabel
      }
      ... on Typography {
        typeVariant
        body
      }
      ... on SectionHero {
        title
        background
      }
      ... on ImageHero {
        headline
        artwork
      }
      ... on StrapiTileGrid {
        __typename
        tiles {
          clicksThrough
          comingSoon
          description
          id
          locked
          relationId
          relationType
          slug
          thumbnail
          title
        }
        title
      }
      ... on StrapiTileList {
        __typename
        detailLabel
        detailSlug
        tiles {
          clicksThrough
          comingSoon
          description
          id
          locked
          relationId
          relationType
          slug
          thumbnail
          title
        }
        title
      }
    }
    title
  }
}
    `;

/**
 * __useGetDynamicPageBySlugQueryGenerated__
 *
 * To run a query within a React component, call `useGetDynamicPageBySlugQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicPageBySlugQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicPageBySlugQueryGenerated({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetDynamicPageBySlugQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetDynamicPageBySlugQueryGenerated, GetDynamicPageBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDynamicPageBySlugQueryGenerated, GetDynamicPageBySlugQueryVariables>(GetDynamicPageBySlugDocumentGenerated, options);
      }
export function useGetDynamicPageBySlugLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetDynamicPageBySlugQueryGenerated, GetDynamicPageBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDynamicPageBySlugQueryGenerated, GetDynamicPageBySlugQueryVariables>(GetDynamicPageBySlugDocumentGenerated, options);
        }
export function useGetDynamicPageBySlugSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDynamicPageBySlugQueryGenerated, GetDynamicPageBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDynamicPageBySlugQueryGenerated, GetDynamicPageBySlugQueryVariables>(GetDynamicPageBySlugDocumentGenerated, options);
        }
export type GetDynamicPageBySlugQueryGeneratedHookResult = ReturnType<typeof useGetDynamicPageBySlugQueryGenerated>;
export type GetDynamicPageBySlugLazyQueryGeneratedHookResult = ReturnType<typeof useGetDynamicPageBySlugLazyQueryGenerated>;
export type GetDynamicPageBySlugSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetDynamicPageBySlugSuspenseQueryGenerated>;
export type GetDynamicPageBySlugQueryResult = Apollo.QueryResult<GetDynamicPageBySlugQueryGenerated, GetDynamicPageBySlugQueryVariables>;
export const GetHomeCardsDocumentGenerated = gql`
    query GetHomeCards {
  getHomeCards {
    id
    image
    title
    url
    cssId
    order
  }
}
    `;

/**
 * __useGetHomeCardsQueryGenerated__
 *
 * To run a query within a React component, call `useGetHomeCardsQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeCardsQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeCardsQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeCardsQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetHomeCardsQueryGenerated, GetHomeCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomeCardsQueryGenerated, GetHomeCardsQueryVariables>(GetHomeCardsDocumentGenerated, options);
      }
export function useGetHomeCardsLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetHomeCardsQueryGenerated, GetHomeCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomeCardsQueryGenerated, GetHomeCardsQueryVariables>(GetHomeCardsDocumentGenerated, options);
        }
export function useGetHomeCardsSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHomeCardsQueryGenerated, GetHomeCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHomeCardsQueryGenerated, GetHomeCardsQueryVariables>(GetHomeCardsDocumentGenerated, options);
        }
export type GetHomeCardsQueryGeneratedHookResult = ReturnType<typeof useGetHomeCardsQueryGenerated>;
export type GetHomeCardsLazyQueryGeneratedHookResult = ReturnType<typeof useGetHomeCardsLazyQueryGenerated>;
export type GetHomeCardsSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetHomeCardsSuspenseQueryGenerated>;
export type GetHomeCardsQueryResult = Apollo.QueryResult<GetHomeCardsQueryGenerated, GetHomeCardsQueryVariables>;
export const ActivateAlbumSessionDocumentGenerated = gql`
    mutation ActivateAlbumSession($albumId: Int!) {
  activateAlbumSession(albumId: $albumId) {
    albumId
    keyId
    keyType
    sub
  }
}
    `;
export type ActivateAlbumSessionMutationFn = Apollo.MutationFunction<ActivateAlbumSessionMutationGenerated, ActivateAlbumSessionMutationVariables>;

/**
 * __useActivateAlbumSessionMutationGenerated__
 *
 * To run a mutation, you first call `useActivateAlbumSessionMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAlbumSessionMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAlbumSessionMutationGenerated, { data, loading, error }] = useActivateAlbumSessionMutationGenerated({
 *   variables: {
 *      albumId: // value for 'albumId'
 *   },
 * });
 */
export function useActivateAlbumSessionMutationGenerated(baseOptions?: Apollo.MutationHookOptions<ActivateAlbumSessionMutationGenerated, ActivateAlbumSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateAlbumSessionMutationGenerated, ActivateAlbumSessionMutationVariables>(ActivateAlbumSessionDocumentGenerated, options);
      }
export type ActivateAlbumSessionMutationGeneratedHookResult = ReturnType<typeof useActivateAlbumSessionMutationGenerated>;
export type ActivateAlbumSessionMutationResult = Apollo.MutationResult<ActivateAlbumSessionMutationGenerated>;
export type ActivateAlbumSessionMutationOptions = Apollo.BaseMutationOptions<ActivateAlbumSessionMutationGenerated, ActivateAlbumSessionMutationVariables>;
export const GetAlbumDocumentGenerated = gql`
    query GetAlbum($albumId: Int!) {
  getAlbum(albumId: $albumId) {
    artist
    category
    clicksThrough
    comingSoon
    coverArt
    gradient {
      angle
      colors {
        hexColor
        position
      }
    }
    id
    locked
    rank
    title
    trackList {
      artist
      embedUrl
      id
      locked
      notes
      title
      visualCanvas
    }
    html {
      __typename
      markup
    }
    keyPrice
    cssId
  }
}
    `;

/**
 * __useGetAlbumQueryGenerated__
 *
 * To run a query within a React component, call `useGetAlbumQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetAlbumQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlbumQueryGenerated({
 *   variables: {
 *      albumId: // value for 'albumId'
 *   },
 * });
 */
export function useGetAlbumQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetAlbumQueryGenerated, GetAlbumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlbumQueryGenerated, GetAlbumQueryVariables>(GetAlbumDocumentGenerated, options);
      }
export function useGetAlbumLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetAlbumQueryGenerated, GetAlbumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlbumQueryGenerated, GetAlbumQueryVariables>(GetAlbumDocumentGenerated, options);
        }
export function useGetAlbumSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAlbumQueryGenerated, GetAlbumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAlbumQueryGenerated, GetAlbumQueryVariables>(GetAlbumDocumentGenerated, options);
        }
export type GetAlbumQueryGeneratedHookResult = ReturnType<typeof useGetAlbumQueryGenerated>;
export type GetAlbumLazyQueryGeneratedHookResult = ReturnType<typeof useGetAlbumLazyQueryGenerated>;
export type GetAlbumSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetAlbumSuspenseQueryGenerated>;
export type GetAlbumQueryResult = Apollo.QueryResult<GetAlbumQueryGenerated, GetAlbumQueryVariables>;
export const GetAlbumsByCategoryDocumentGenerated = gql`
    query GetAlbumsByCategory($category: StrapiAlbumCategory!, $start: Int, $limit: Int) {
  getAlbumsByCategory(category: $category, start: $start, limit: $limit) {
    results {
      artist
      category
      clicksThrough
      comingSoon
      coverArt
      id
      locked
      rank
      title
      keyPrice
    }
    pagination {
      start
      limit
      total
    }
  }
}
    `;

/**
 * __useGetAlbumsByCategoryQueryGenerated__
 *
 * To run a query within a React component, call `useGetAlbumsByCategoryQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetAlbumsByCategoryQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlbumsByCategoryQueryGenerated({
 *   variables: {
 *      category: // value for 'category'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAlbumsByCategoryQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetAlbumsByCategoryQueryGenerated, GetAlbumsByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlbumsByCategoryQueryGenerated, GetAlbumsByCategoryQueryVariables>(GetAlbumsByCategoryDocumentGenerated, options);
      }
export function useGetAlbumsByCategoryLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetAlbumsByCategoryQueryGenerated, GetAlbumsByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlbumsByCategoryQueryGenerated, GetAlbumsByCategoryQueryVariables>(GetAlbumsByCategoryDocumentGenerated, options);
        }
export function useGetAlbumsByCategorySuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAlbumsByCategoryQueryGenerated, GetAlbumsByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAlbumsByCategoryQueryGenerated, GetAlbumsByCategoryQueryVariables>(GetAlbumsByCategoryDocumentGenerated, options);
        }
export type GetAlbumsByCategoryQueryGeneratedHookResult = ReturnType<typeof useGetAlbumsByCategoryQueryGenerated>;
export type GetAlbumsByCategoryLazyQueryGeneratedHookResult = ReturnType<typeof useGetAlbumsByCategoryLazyQueryGenerated>;
export type GetAlbumsByCategorySuspenseQueryGeneratedHookResult = ReturnType<typeof useGetAlbumsByCategorySuspenseQueryGenerated>;
export type GetAlbumsByCategoryQueryResult = Apollo.QueryResult<GetAlbumsByCategoryQueryGenerated, GetAlbumsByCategoryQueryVariables>;
export const VerifyAlbumSessionDocumentGenerated = gql`
    query VerifyAlbumSession($albumId: Int!) {
  verifyAlbumSession(albumId: $albumId) {
    elapsedTime
    endTime
    albumId
    keyId
    keyType
    startTime
    sub
  }
}
    `;

/**
 * __useVerifyAlbumSessionQueryGenerated__
 *
 * To run a query within a React component, call `useVerifyAlbumSessionQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useVerifyAlbumSessionQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyAlbumSessionQueryGenerated({
 *   variables: {
 *      albumId: // value for 'albumId'
 *   },
 * });
 */
export function useVerifyAlbumSessionQueryGenerated(baseOptions: Apollo.QueryHookOptions<VerifyAlbumSessionQueryGenerated, VerifyAlbumSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyAlbumSessionQueryGenerated, VerifyAlbumSessionQueryVariables>(VerifyAlbumSessionDocumentGenerated, options);
      }
export function useVerifyAlbumSessionLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<VerifyAlbumSessionQueryGenerated, VerifyAlbumSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyAlbumSessionQueryGenerated, VerifyAlbumSessionQueryVariables>(VerifyAlbumSessionDocumentGenerated, options);
        }
export function useVerifyAlbumSessionSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<VerifyAlbumSessionQueryGenerated, VerifyAlbumSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerifyAlbumSessionQueryGenerated, VerifyAlbumSessionQueryVariables>(VerifyAlbumSessionDocumentGenerated, options);
        }
export type VerifyAlbumSessionQueryGeneratedHookResult = ReturnType<typeof useVerifyAlbumSessionQueryGenerated>;
export type VerifyAlbumSessionLazyQueryGeneratedHookResult = ReturnType<typeof useVerifyAlbumSessionLazyQueryGenerated>;
export type VerifyAlbumSessionSuspenseQueryGeneratedHookResult = ReturnType<typeof useVerifyAlbumSessionSuspenseQueryGenerated>;
export type VerifyAlbumSessionQueryResult = Apollo.QueryResult<VerifyAlbumSessionQueryGenerated, VerifyAlbumSessionQueryVariables>;
export const GetNotificationsDocumentGenerated = gql`
    query getNotifications {
  getNotifications {
    notifications {
      key
      id
      title
      type
      status
    }
    unreadCount
  }
}
    `;

/**
 * __useGetNotificationsQueryGenerated__
 *
 * To run a query within a React component, call `useGetNotificationsQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQueryGenerated, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQueryGenerated, GetNotificationsQueryVariables>(GetNotificationsDocumentGenerated, options);
      }
export function useGetNotificationsLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQueryGenerated, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQueryGenerated, GetNotificationsQueryVariables>(GetNotificationsDocumentGenerated, options);
        }
export function useGetNotificationsSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNotificationsQueryGenerated, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNotificationsQueryGenerated, GetNotificationsQueryVariables>(GetNotificationsDocumentGenerated, options);
        }
export type GetNotificationsQueryGeneratedHookResult = ReturnType<typeof useGetNotificationsQueryGenerated>;
export type GetNotificationsLazyQueryGeneratedHookResult = ReturnType<typeof useGetNotificationsLazyQueryGenerated>;
export type GetNotificationsSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetNotificationsSuspenseQueryGenerated>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQueryGenerated, GetNotificationsQueryVariables>;
export const GetNotificationDetailsDocumentGenerated = gql`
    query GetNotificationDetails($noteId: Int!) {
  getNotificationDetails(noteId: $noteId) {
    id
    group
    title
    stack {
      ... on StrapiFeaturedContent {
        __typename
        artwork
        detailSlug
        headline
        id
        subtitle
        title
      }
      ... on StrapiHTMLBlock {
        __typename
        markup
      }
      ... on CTA {
        ctaType
        ctaVariant
        ctaPath
        ctaLabel
      }
      ... on Typography {
        typeVariant
        body
      }
      ... on SectionHero {
        title
        background
      }
      ... on ImageHero {
        headline
        artwork
      }
      ... on StrapiTileGrid {
        __typename
        tiles {
          clicksThrough
          comingSoon
          description
          id
          locked
          relationId
          relationType
          slug
          thumbnail
          title
        }
        title
      }
      ... on StrapiTileList {
        __typename
        detailLabel
        detailSlug
        tiles {
          clicksThrough
          comingSoon
          description
          id
          locked
          relationId
          relationType
          slug
          thumbnail
          title
        }
      }
    }
  }
}
    `;

/**
 * __useGetNotificationDetailsQueryGenerated__
 *
 * To run a query within a React component, call `useGetNotificationDetailsQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationDetailsQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationDetailsQueryGenerated({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useGetNotificationDetailsQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetNotificationDetailsQueryGenerated, GetNotificationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationDetailsQueryGenerated, GetNotificationDetailsQueryVariables>(GetNotificationDetailsDocumentGenerated, options);
      }
export function useGetNotificationDetailsLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationDetailsQueryGenerated, GetNotificationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationDetailsQueryGenerated, GetNotificationDetailsQueryVariables>(GetNotificationDetailsDocumentGenerated, options);
        }
export function useGetNotificationDetailsSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNotificationDetailsQueryGenerated, GetNotificationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNotificationDetailsQueryGenerated, GetNotificationDetailsQueryVariables>(GetNotificationDetailsDocumentGenerated, options);
        }
export type GetNotificationDetailsQueryGeneratedHookResult = ReturnType<typeof useGetNotificationDetailsQueryGenerated>;
export type GetNotificationDetailsLazyQueryGeneratedHookResult = ReturnType<typeof useGetNotificationDetailsLazyQueryGenerated>;
export type GetNotificationDetailsSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetNotificationDetailsSuspenseQueryGenerated>;
export type GetNotificationDetailsQueryResult = Apollo.QueryResult<GetNotificationDetailsQueryGenerated, GetNotificationDetailsQueryVariables>;
export const GetTakeoverDocumentGenerated = gql`
    query GetTakeover($id: Int!) {
  getTakeover(id: $id) {
    artwork
    callToAction {
      ctaLabel
      ctaPath
      ctaType
    }
    group
    id
  }
}
    `;

/**
 * __useGetTakeoverQueryGenerated__
 *
 * To run a query within a React component, call `useGetTakeoverQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetTakeoverQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTakeoverQueryGenerated({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTakeoverQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetTakeoverQueryGenerated, GetTakeoverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTakeoverQueryGenerated, GetTakeoverQueryVariables>(GetTakeoverDocumentGenerated, options);
      }
export function useGetTakeoverLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetTakeoverQueryGenerated, GetTakeoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTakeoverQueryGenerated, GetTakeoverQueryVariables>(GetTakeoverDocumentGenerated, options);
        }
export function useGetTakeoverSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTakeoverQueryGenerated, GetTakeoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTakeoverQueryGenerated, GetTakeoverQueryVariables>(GetTakeoverDocumentGenerated, options);
        }
export type GetTakeoverQueryGeneratedHookResult = ReturnType<typeof useGetTakeoverQueryGenerated>;
export type GetTakeoverLazyQueryGeneratedHookResult = ReturnType<typeof useGetTakeoverLazyQueryGenerated>;
export type GetTakeoverSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetTakeoverSuspenseQueryGenerated>;
export type GetTakeoverQueryResult = Apollo.QueryResult<GetTakeoverQueryGenerated, GetTakeoverQueryVariables>;
export const MarkNotificationAsReadDocumentGenerated = gql`
    mutation markNotificationAsRead($key: String!) {
  markNotificationAsRead(key: $key) {
    status
  }
}
    `;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<MarkNotificationAsReadMutationGenerated, MarkNotificationAsReadMutationVariables>;

/**
 * __useMarkNotificationAsReadMutationGenerated__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutationGenerated, { data, loading, error }] = useMarkNotificationAsReadMutationGenerated({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutationGenerated(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsReadMutationGenerated, MarkNotificationAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationAsReadMutationGenerated, MarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocumentGenerated, options);
      }
export type MarkNotificationAsReadMutationGeneratedHookResult = ReturnType<typeof useMarkNotificationAsReadMutationGenerated>;
export type MarkNotificationAsReadMutationResult = Apollo.MutationResult<MarkNotificationAsReadMutationGenerated>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsReadMutationGenerated, MarkNotificationAsReadMutationVariables>;
export const GetTicketmasterPresaleCodeDocumentGenerated = gql`
    query getTicketmasterPresaleCode {
  getTicketmasterPresaleCode
}
    `;

/**
 * __useGetTicketmasterPresaleCodeQueryGenerated__
 *
 * To run a query within a React component, call `useGetTicketmasterPresaleCodeQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketmasterPresaleCodeQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketmasterPresaleCodeQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetTicketmasterPresaleCodeQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetTicketmasterPresaleCodeQueryGenerated, GetTicketmasterPresaleCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketmasterPresaleCodeQueryGenerated, GetTicketmasterPresaleCodeQueryVariables>(GetTicketmasterPresaleCodeDocumentGenerated, options);
      }
export function useGetTicketmasterPresaleCodeLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketmasterPresaleCodeQueryGenerated, GetTicketmasterPresaleCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketmasterPresaleCodeQueryGenerated, GetTicketmasterPresaleCodeQueryVariables>(GetTicketmasterPresaleCodeDocumentGenerated, options);
        }
export function useGetTicketmasterPresaleCodeSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTicketmasterPresaleCodeQueryGenerated, GetTicketmasterPresaleCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTicketmasterPresaleCodeQueryGenerated, GetTicketmasterPresaleCodeQueryVariables>(GetTicketmasterPresaleCodeDocumentGenerated, options);
        }
export type GetTicketmasterPresaleCodeQueryGeneratedHookResult = ReturnType<typeof useGetTicketmasterPresaleCodeQueryGenerated>;
export type GetTicketmasterPresaleCodeLazyQueryGeneratedHookResult = ReturnType<typeof useGetTicketmasterPresaleCodeLazyQueryGenerated>;
export type GetTicketmasterPresaleCodeSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetTicketmasterPresaleCodeSuspenseQueryGenerated>;
export type GetTicketmasterPresaleCodeQueryResult = Apollo.QueryResult<GetTicketmasterPresaleCodeQueryGenerated, GetTicketmasterPresaleCodeQueryVariables>;
export const GetTixrPrivateUrlDocumentGenerated = gql`
    query getTixrPrivateUrl {
  getTixrPrivateUrl
}
    `;

/**
 * __useGetTixrPrivateUrlQueryGenerated__
 *
 * To run a query within a React component, call `useGetTixrPrivateUrlQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetTixrPrivateUrlQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTixrPrivateUrlQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetTixrPrivateUrlQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetTixrPrivateUrlQueryGenerated, GetTixrPrivateUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTixrPrivateUrlQueryGenerated, GetTixrPrivateUrlQueryVariables>(GetTixrPrivateUrlDocumentGenerated, options);
      }
export function useGetTixrPrivateUrlLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetTixrPrivateUrlQueryGenerated, GetTixrPrivateUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTixrPrivateUrlQueryGenerated, GetTixrPrivateUrlQueryVariables>(GetTixrPrivateUrlDocumentGenerated, options);
        }
export function useGetTixrPrivateUrlSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTixrPrivateUrlQueryGenerated, GetTixrPrivateUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTixrPrivateUrlQueryGenerated, GetTixrPrivateUrlQueryVariables>(GetTixrPrivateUrlDocumentGenerated, options);
        }
export type GetTixrPrivateUrlQueryGeneratedHookResult = ReturnType<typeof useGetTixrPrivateUrlQueryGenerated>;
export type GetTixrPrivateUrlLazyQueryGeneratedHookResult = ReturnType<typeof useGetTixrPrivateUrlLazyQueryGenerated>;
export type GetTixrPrivateUrlSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetTixrPrivateUrlSuspenseQueryGenerated>;
export type GetTixrPrivateUrlQueryResult = Apollo.QueryResult<GetTixrPrivateUrlQueryGenerated, GetTixrPrivateUrlQueryVariables>;
export const GetTransmissionsDocumentGenerated = gql`
    query GetTransmissions {
  getTransmissions {
    body
    date
    id
    image
    title
    cssId
  }
}
    `;

/**
 * __useGetTransmissionsQueryGenerated__
 *
 * To run a query within a React component, call `useGetTransmissionsQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetTransmissionsQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransmissionsQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetTransmissionsQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetTransmissionsQueryGenerated, GetTransmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransmissionsQueryGenerated, GetTransmissionsQueryVariables>(GetTransmissionsDocumentGenerated, options);
      }
export function useGetTransmissionsLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetTransmissionsQueryGenerated, GetTransmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransmissionsQueryGenerated, GetTransmissionsQueryVariables>(GetTransmissionsDocumentGenerated, options);
        }
export function useGetTransmissionsSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTransmissionsQueryGenerated, GetTransmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTransmissionsQueryGenerated, GetTransmissionsQueryVariables>(GetTransmissionsDocumentGenerated, options);
        }
export type GetTransmissionsQueryGeneratedHookResult = ReturnType<typeof useGetTransmissionsQueryGenerated>;
export type GetTransmissionsLazyQueryGeneratedHookResult = ReturnType<typeof useGetTransmissionsLazyQueryGenerated>;
export type GetTransmissionsSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetTransmissionsSuspenseQueryGenerated>;
export type GetTransmissionsQueryResult = Apollo.QueryResult<GetTransmissionsQueryGenerated, GetTransmissionsQueryVariables>;
export const GetThoughtsLinksDocumentGenerated = gql`
    query GetThoughtsLinks {
  getThoughtsLinks {
    date
    description
    id
    title
    url
    variant
  }
}
    `;

/**
 * __useGetThoughtsLinksQueryGenerated__
 *
 * To run a query within a React component, call `useGetThoughtsLinksQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetThoughtsLinksQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThoughtsLinksQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetThoughtsLinksQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetThoughtsLinksQueryGenerated, GetThoughtsLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThoughtsLinksQueryGenerated, GetThoughtsLinksQueryVariables>(GetThoughtsLinksDocumentGenerated, options);
      }
export function useGetThoughtsLinksLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetThoughtsLinksQueryGenerated, GetThoughtsLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThoughtsLinksQueryGenerated, GetThoughtsLinksQueryVariables>(GetThoughtsLinksDocumentGenerated, options);
        }
export function useGetThoughtsLinksSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetThoughtsLinksQueryGenerated, GetThoughtsLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetThoughtsLinksQueryGenerated, GetThoughtsLinksQueryVariables>(GetThoughtsLinksDocumentGenerated, options);
        }
export type GetThoughtsLinksQueryGeneratedHookResult = ReturnType<typeof useGetThoughtsLinksQueryGenerated>;
export type GetThoughtsLinksLazyQueryGeneratedHookResult = ReturnType<typeof useGetThoughtsLinksLazyQueryGenerated>;
export type GetThoughtsLinksSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetThoughtsLinksSuspenseQueryGenerated>;
export type GetThoughtsLinksQueryResult = Apollo.QueryResult<GetThoughtsLinksQueryGenerated, GetThoughtsLinksQueryVariables>;
export const GetQAsDocumentGenerated = gql`
    query GetQAs {
  getQAs {
    id
    body
    date
    title
    cssId
  }
}
    `;

/**
 * __useGetQAsQueryGenerated__
 *
 * To run a query within a React component, call `useGetQAsQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetQAsQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQAsQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetQAsQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetQAsQueryGenerated, GetQAsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQAsQueryGenerated, GetQAsQueryVariables>(GetQAsDocumentGenerated, options);
      }
export function useGetQAsLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetQAsQueryGenerated, GetQAsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQAsQueryGenerated, GetQAsQueryVariables>(GetQAsDocumentGenerated, options);
        }
export function useGetQAsSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetQAsQueryGenerated, GetQAsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQAsQueryGenerated, GetQAsQueryVariables>(GetQAsDocumentGenerated, options);
        }
export type GetQAsQueryGeneratedHookResult = ReturnType<typeof useGetQAsQueryGenerated>;
export type GetQAsLazyQueryGeneratedHookResult = ReturnType<typeof useGetQAsLazyQueryGenerated>;
export type GetQAsSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetQAsSuspenseQueryGenerated>;
export type GetQAsQueryResult = Apollo.QueryResult<GetQAsQueryGenerated, GetQAsQueryVariables>;
export const GetCharitiesDocumentGenerated = gql`
    query GetCharities {
  getCharities {
    description
    donateUrl
    id
    instagramUrl
    name
    pageHero
    rank
    thumbnail
    title
    websiteUrl
    works
  }
}
    `;

/**
 * __useGetCharitiesQueryGenerated__
 *
 * To run a query within a React component, call `useGetCharitiesQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetCharitiesQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCharitiesQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetCharitiesQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetCharitiesQueryGenerated, GetCharitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCharitiesQueryGenerated, GetCharitiesQueryVariables>(GetCharitiesDocumentGenerated, options);
      }
export function useGetCharitiesLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetCharitiesQueryGenerated, GetCharitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCharitiesQueryGenerated, GetCharitiesQueryVariables>(GetCharitiesDocumentGenerated, options);
        }
export function useGetCharitiesSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCharitiesQueryGenerated, GetCharitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCharitiesQueryGenerated, GetCharitiesQueryVariables>(GetCharitiesDocumentGenerated, options);
        }
export type GetCharitiesQueryGeneratedHookResult = ReturnType<typeof useGetCharitiesQueryGenerated>;
export type GetCharitiesLazyQueryGeneratedHookResult = ReturnType<typeof useGetCharitiesLazyQueryGenerated>;
export type GetCharitiesSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetCharitiesSuspenseQueryGenerated>;
export type GetCharitiesQueryResult = Apollo.QueryResult<GetCharitiesQueryGenerated, GetCharitiesQueryVariables>;
export const GetCharityDocumentGenerated = gql`
    query GetCharity($id: Int!) {
  getCharity(id: $id) {
    description
    donateUrl
    id
    instagramUrl
    name
    pageHero
    rank
    thumbnail
    title
    websiteUrl
    works
    cssId
  }
}
    `;

/**
 * __useGetCharityQueryGenerated__
 *
 * To run a query within a React component, call `useGetCharityQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetCharityQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCharityQueryGenerated({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCharityQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetCharityQueryGenerated, GetCharityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCharityQueryGenerated, GetCharityQueryVariables>(GetCharityDocumentGenerated, options);
      }
export function useGetCharityLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetCharityQueryGenerated, GetCharityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCharityQueryGenerated, GetCharityQueryVariables>(GetCharityDocumentGenerated, options);
        }
export function useGetCharitySuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCharityQueryGenerated, GetCharityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCharityQueryGenerated, GetCharityQueryVariables>(GetCharityDocumentGenerated, options);
        }
export type GetCharityQueryGeneratedHookResult = ReturnType<typeof useGetCharityQueryGenerated>;
export type GetCharityLazyQueryGeneratedHookResult = ReturnType<typeof useGetCharityLazyQueryGenerated>;
export type GetCharitySuspenseQueryGeneratedHookResult = ReturnType<typeof useGetCharitySuspenseQueryGenerated>;
export type GetCharityQueryResult = Apollo.QueryResult<GetCharityQueryGenerated, GetCharityQueryVariables>;
export const GetSubscriptionsDocumentGenerated = gql`
    query getSubscriptions($email: String!) {
  getUser(email: $email) {
    squareCustomer {
      emailAddress
      familyName
      givenName
      id
      subscriptions {
        canceledDate
        chargedThroughDate
        createdAt
        customerId
        id
        locationId
        planVariationId
        startDate
        status
        timezone
      }
    }
    stripeSubscriptions {
      amount
      createdAtUtc
      currentPeriodEndUtc
      currentPeriodStartUtc
      customerDescription
      customerId
      customerName
      email
      id
      interval
      plan
      quantity
      startDateUtc
      status
    }
  }
}
    `;

/**
 * __useGetSubscriptionsQueryGenerated__
 *
 * To run a query within a React component, call `useGetSubscriptionsQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQueryGenerated({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetSubscriptionsQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetSubscriptionsQueryGenerated, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQueryGenerated, GetSubscriptionsQueryVariables>(GetSubscriptionsDocumentGenerated, options);
      }
export function useGetSubscriptionsLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQueryGenerated, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQueryGenerated, GetSubscriptionsQueryVariables>(GetSubscriptionsDocumentGenerated, options);
        }
export function useGetSubscriptionsSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSubscriptionsQueryGenerated, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubscriptionsQueryGenerated, GetSubscriptionsQueryVariables>(GetSubscriptionsDocumentGenerated, options);
        }
export type GetSubscriptionsQueryGeneratedHookResult = ReturnType<typeof useGetSubscriptionsQueryGenerated>;
export type GetSubscriptionsLazyQueryGeneratedHookResult = ReturnType<typeof useGetSubscriptionsLazyQueryGenerated>;
export type GetSubscriptionsSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetSubscriptionsSuspenseQueryGenerated>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQueryGenerated, GetSubscriptionsQueryVariables>;
export const GetTreasureDocumentGenerated = gql`
    query getTreasure($email: String!) {
  getUser(email: $email) {
    treasure {
      items {
        id
        name
        description
        artwork
        ctaPath
        ctaLabel
        ctaType
        hidden
      }
    }
  }
}
    `;

/**
 * __useGetTreasureQueryGenerated__
 *
 * To run a query within a React component, call `useGetTreasureQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetTreasureQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTreasureQueryGenerated({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetTreasureQueryGenerated(baseOptions: Apollo.QueryHookOptions<GetTreasureQueryGenerated, GetTreasureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTreasureQueryGenerated, GetTreasureQueryVariables>(GetTreasureDocumentGenerated, options);
      }
export function useGetTreasureLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetTreasureQueryGenerated, GetTreasureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTreasureQueryGenerated, GetTreasureQueryVariables>(GetTreasureDocumentGenerated, options);
        }
export function useGetTreasureSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTreasureQueryGenerated, GetTreasureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTreasureQueryGenerated, GetTreasureQueryVariables>(GetTreasureDocumentGenerated, options);
        }
export type GetTreasureQueryGeneratedHookResult = ReturnType<typeof useGetTreasureQueryGenerated>;
export type GetTreasureLazyQueryGeneratedHookResult = ReturnType<typeof useGetTreasureLazyQueryGenerated>;
export type GetTreasureSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetTreasureSuspenseQueryGenerated>;
export type GetTreasureQueryResult = Apollo.QueryResult<GetTreasureQueryGenerated, GetTreasureQueryVariables>;
export const GetUserCardsOnFileDocumentGenerated = gql`
    query GetUserCardsOnFile {
  getCardsOnFile {
    cards {
      id
      last4
      expMonth
      expYear
      cardBrand
      enabled
    }
  }
}
    `;

/**
 * __useGetUserCardsOnFileQueryGenerated__
 *
 * To run a query within a React component, call `useGetUserCardsOnFileQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCardsOnFileQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCardsOnFileQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetUserCardsOnFileQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetUserCardsOnFileQueryGenerated, GetUserCardsOnFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCardsOnFileQueryGenerated, GetUserCardsOnFileQueryVariables>(GetUserCardsOnFileDocumentGenerated, options);
      }
export function useGetUserCardsOnFileLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCardsOnFileQueryGenerated, GetUserCardsOnFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCardsOnFileQueryGenerated, GetUserCardsOnFileQueryVariables>(GetUserCardsOnFileDocumentGenerated, options);
        }
export function useGetUserCardsOnFileSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserCardsOnFileQueryGenerated, GetUserCardsOnFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserCardsOnFileQueryGenerated, GetUserCardsOnFileQueryVariables>(GetUserCardsOnFileDocumentGenerated, options);
        }
export type GetUserCardsOnFileQueryGeneratedHookResult = ReturnType<typeof useGetUserCardsOnFileQueryGenerated>;
export type GetUserCardsOnFileLazyQueryGeneratedHookResult = ReturnType<typeof useGetUserCardsOnFileLazyQueryGenerated>;
export type GetUserCardsOnFileSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetUserCardsOnFileSuspenseQueryGenerated>;
export type GetUserCardsOnFileQueryResult = Apollo.QueryResult<GetUserCardsOnFileQueryGenerated, GetUserCardsOnFileQueryVariables>;
export const GetUserKeyBalanceDocumentGenerated = gql`
    query GetUserKeyBalance {
  getUserKeyBalance
}
    `;

/**
 * __useGetUserKeyBalanceQueryGenerated__
 *
 * To run a query within a React component, call `useGetUserKeyBalanceQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetUserKeyBalanceQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserKeyBalanceQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetUserKeyBalanceQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetUserKeyBalanceQueryGenerated, GetUserKeyBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserKeyBalanceQueryGenerated, GetUserKeyBalanceQueryVariables>(GetUserKeyBalanceDocumentGenerated, options);
      }
export function useGetUserKeyBalanceLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetUserKeyBalanceQueryGenerated, GetUserKeyBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserKeyBalanceQueryGenerated, GetUserKeyBalanceQueryVariables>(GetUserKeyBalanceDocumentGenerated, options);
        }
export function useGetUserKeyBalanceSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserKeyBalanceQueryGenerated, GetUserKeyBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserKeyBalanceQueryGenerated, GetUserKeyBalanceQueryVariables>(GetUserKeyBalanceDocumentGenerated, options);
        }
export type GetUserKeyBalanceQueryGeneratedHookResult = ReturnType<typeof useGetUserKeyBalanceQueryGenerated>;
export type GetUserKeyBalanceLazyQueryGeneratedHookResult = ReturnType<typeof useGetUserKeyBalanceLazyQueryGenerated>;
export type GetUserKeyBalanceSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetUserKeyBalanceSuspenseQueryGenerated>;
export type GetUserKeyBalanceQueryResult = Apollo.QueryResult<GetUserKeyBalanceQueryGenerated, GetUserKeyBalanceQueryVariables>;
export const GetUserGuestPassBalanceDocumentGenerated = gql`
    query GetUserGuestPassBalance {
  getUserGuestPassBalance
}
    `;

/**
 * __useGetUserGuestPassBalanceQueryGenerated__
 *
 * To run a query within a React component, call `useGetUserGuestPassBalanceQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGuestPassBalanceQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGuestPassBalanceQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetUserGuestPassBalanceQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetUserGuestPassBalanceQueryGenerated, GetUserGuestPassBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserGuestPassBalanceQueryGenerated, GetUserGuestPassBalanceQueryVariables>(GetUserGuestPassBalanceDocumentGenerated, options);
      }
export function useGetUserGuestPassBalanceLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetUserGuestPassBalanceQueryGenerated, GetUserGuestPassBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserGuestPassBalanceQueryGenerated, GetUserGuestPassBalanceQueryVariables>(GetUserGuestPassBalanceDocumentGenerated, options);
        }
export function useGetUserGuestPassBalanceSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserGuestPassBalanceQueryGenerated, GetUserGuestPassBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserGuestPassBalanceQueryGenerated, GetUserGuestPassBalanceQueryVariables>(GetUserGuestPassBalanceDocumentGenerated, options);
        }
export type GetUserGuestPassBalanceQueryGeneratedHookResult = ReturnType<typeof useGetUserGuestPassBalanceQueryGenerated>;
export type GetUserGuestPassBalanceLazyQueryGeneratedHookResult = ReturnType<typeof useGetUserGuestPassBalanceLazyQueryGenerated>;
export type GetUserGuestPassBalanceSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetUserGuestPassBalanceSuspenseQueryGenerated>;
export type GetUserGuestPassBalanceQueryResult = Apollo.QueryResult<GetUserGuestPassBalanceQueryGenerated, GetUserGuestPassBalanceQueryVariables>;
export const GetUserHighScoresDocumentGenerated = gql`
    query GetUserHighScores {
  getUserHighScores {
    event
    score
  }
}
    `;

/**
 * __useGetUserHighScoresQueryGenerated__
 *
 * To run a query within a React component, call `useGetUserHighScoresQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetUserHighScoresQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserHighScoresQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetUserHighScoresQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetUserHighScoresQueryGenerated, GetUserHighScoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserHighScoresQueryGenerated, GetUserHighScoresQueryVariables>(GetUserHighScoresDocumentGenerated, options);
      }
export function useGetUserHighScoresLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetUserHighScoresQueryGenerated, GetUserHighScoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserHighScoresQueryGenerated, GetUserHighScoresQueryVariables>(GetUserHighScoresDocumentGenerated, options);
        }
export function useGetUserHighScoresSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserHighScoresQueryGenerated, GetUserHighScoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserHighScoresQueryGenerated, GetUserHighScoresQueryVariables>(GetUserHighScoresDocumentGenerated, options);
        }
export type GetUserHighScoresQueryGeneratedHookResult = ReturnType<typeof useGetUserHighScoresQueryGenerated>;
export type GetUserHighScoresLazyQueryGeneratedHookResult = ReturnType<typeof useGetUserHighScoresLazyQueryGenerated>;
export type GetUserHighScoresSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetUserHighScoresSuspenseQueryGenerated>;
export type GetUserHighScoresQueryResult = Apollo.QueryResult<GetUserHighScoresQueryGenerated, GetUserHighScoresQueryVariables>;
export const ProcessSubscriptionDocumentGenerated = gql`
    mutation ProcessSubscription($input: ProcessSubscriptionInput!) {
  processSubscription(input: $input) {
    subscriptionId
  }
}
    `;
export type ProcessSubscriptionMutationFn = Apollo.MutationFunction<ProcessSubscriptionMutationGenerated, ProcessSubscriptionMutationVariables>;

/**
 * __useProcessSubscriptionMutationGenerated__
 *
 * To run a mutation, you first call `useProcessSubscriptionMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessSubscriptionMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processSubscriptionMutationGenerated, { data, loading, error }] = useProcessSubscriptionMutationGenerated({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessSubscriptionMutationGenerated(baseOptions?: Apollo.MutationHookOptions<ProcessSubscriptionMutationGenerated, ProcessSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessSubscriptionMutationGenerated, ProcessSubscriptionMutationVariables>(ProcessSubscriptionDocumentGenerated, options);
      }
export type ProcessSubscriptionMutationGeneratedHookResult = ReturnType<typeof useProcessSubscriptionMutationGenerated>;
export type ProcessSubscriptionMutationResult = Apollo.MutationResult<ProcessSubscriptionMutationGenerated>;
export type ProcessSubscriptionMutationOptions = Apollo.BaseMutationOptions<ProcessSubscriptionMutationGenerated, ProcessSubscriptionMutationVariables>;
export const CancelSubscriptionDocumentGenerated = gql`
    mutation CancelSubscription {
  cancelSubscription {
    canceledDate
    chargedThroughDate
    createdAt
    customerId
    id
    locationId
    startDate
    status
    timezone
  }
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutationGenerated, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutationGenerated__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutationGenerated, { data, loading, error }] = useCancelSubscriptionMutationGenerated({
 *   variables: {
 *   },
 * });
 */
export function useCancelSubscriptionMutationGenerated(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutationGenerated, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutationGenerated, CancelSubscriptionMutationVariables>(CancelSubscriptionDocumentGenerated, options);
      }
export type CancelSubscriptionMutationGeneratedHookResult = ReturnType<typeof useCancelSubscriptionMutationGenerated>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutationGenerated>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutationGenerated, CancelSubscriptionMutationVariables>;
export const AddPaymentMethodDocumentGenerated = gql`
    mutation AddPaymentMethod($input: AddPaymentInput!) {
  addPaymentMethod(input: $input) {
    id
    last4
    expYear
    expMonth
    cardBrand
    enabled
  }
}
    `;
export type AddPaymentMethodMutationFn = Apollo.MutationFunction<AddPaymentMethodMutationGenerated, AddPaymentMethodMutationVariables>;

/**
 * __useAddPaymentMethodMutationGenerated__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutationGenerated, { data, loading, error }] = useAddPaymentMethodMutationGenerated({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentMethodMutationGenerated(baseOptions?: Apollo.MutationHookOptions<AddPaymentMethodMutationGenerated, AddPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPaymentMethodMutationGenerated, AddPaymentMethodMutationVariables>(AddPaymentMethodDocumentGenerated, options);
      }
export type AddPaymentMethodMutationGeneratedHookResult = ReturnType<typeof useAddPaymentMethodMutationGenerated>;
export type AddPaymentMethodMutationResult = Apollo.MutationResult<AddPaymentMethodMutationGenerated>;
export type AddPaymentMethodMutationOptions = Apollo.BaseMutationOptions<AddPaymentMethodMutationGenerated, AddPaymentMethodMutationVariables>;
export const DisablePaymentMethodDocumentGenerated = gql`
    mutation DisablePaymentMethod($cardId: String!) {
  disablePaymentMethod(cardId: $cardId) {
    id
  }
}
    `;
export type DisablePaymentMethodMutationFn = Apollo.MutationFunction<DisablePaymentMethodMutationGenerated, DisablePaymentMethodMutationVariables>;

/**
 * __useDisablePaymentMethodMutationGenerated__
 *
 * To run a mutation, you first call `useDisablePaymentMethodMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePaymentMethodMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePaymentMethodMutationGenerated, { data, loading, error }] = useDisablePaymentMethodMutationGenerated({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useDisablePaymentMethodMutationGenerated(baseOptions?: Apollo.MutationHookOptions<DisablePaymentMethodMutationGenerated, DisablePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisablePaymentMethodMutationGenerated, DisablePaymentMethodMutationVariables>(DisablePaymentMethodDocumentGenerated, options);
      }
export type DisablePaymentMethodMutationGeneratedHookResult = ReturnType<typeof useDisablePaymentMethodMutationGenerated>;
export type DisablePaymentMethodMutationResult = Apollo.MutationResult<DisablePaymentMethodMutationGenerated>;
export type DisablePaymentMethodMutationOptions = Apollo.BaseMutationOptions<DisablePaymentMethodMutationGenerated, DisablePaymentMethodMutationVariables>;
export const ProcessKeyPurchaseDocumentGenerated = gql`
    mutation ProcessKeyPurchase($input: ProcessKeyPurchaseInput!) {
  processKeyPurchase(input: $input)
}
    `;
export type ProcessKeyPurchaseMutationFn = Apollo.MutationFunction<ProcessKeyPurchaseMutationGenerated, ProcessKeyPurchaseMutationVariables>;

/**
 * __useProcessKeyPurchaseMutationGenerated__
 *
 * To run a mutation, you first call `useProcessKeyPurchaseMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessKeyPurchaseMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processKeyPurchaseMutationGenerated, { data, loading, error }] = useProcessKeyPurchaseMutationGenerated({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessKeyPurchaseMutationGenerated(baseOptions?: Apollo.MutationHookOptions<ProcessKeyPurchaseMutationGenerated, ProcessKeyPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessKeyPurchaseMutationGenerated, ProcessKeyPurchaseMutationVariables>(ProcessKeyPurchaseDocumentGenerated, options);
      }
export type ProcessKeyPurchaseMutationGeneratedHookResult = ReturnType<typeof useProcessKeyPurchaseMutationGenerated>;
export type ProcessKeyPurchaseMutationResult = Apollo.MutationResult<ProcessKeyPurchaseMutationGenerated>;
export type ProcessKeyPurchaseMutationOptions = Apollo.BaseMutationOptions<ProcessKeyPurchaseMutationGenerated, ProcessKeyPurchaseMutationVariables>;
export const PurchaseGuestPassDocumentGenerated = gql`
    mutation PurchaseGuestPass {
  purchaseGuestPass
}
    `;
export type PurchaseGuestPassMutationFn = Apollo.MutationFunction<PurchaseGuestPassMutationGenerated, PurchaseGuestPassMutationVariables>;

/**
 * __usePurchaseGuestPassMutationGenerated__
 *
 * To run a mutation, you first call `usePurchaseGuestPassMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseGuestPassMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseGuestPassMutationGenerated, { data, loading, error }] = usePurchaseGuestPassMutationGenerated({
 *   variables: {
 *   },
 * });
 */
export function usePurchaseGuestPassMutationGenerated(baseOptions?: Apollo.MutationHookOptions<PurchaseGuestPassMutationGenerated, PurchaseGuestPassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchaseGuestPassMutationGenerated, PurchaseGuestPassMutationVariables>(PurchaseGuestPassDocumentGenerated, options);
      }
export type PurchaseGuestPassMutationGeneratedHookResult = ReturnType<typeof usePurchaseGuestPassMutationGenerated>;
export type PurchaseGuestPassMutationResult = Apollo.MutationResult<PurchaseGuestPassMutationGenerated>;
export type PurchaseGuestPassMutationOptions = Apollo.BaseMutationOptions<PurchaseGuestPassMutationGenerated, PurchaseGuestPassMutationVariables>;
export const IsMonthlySubDocumentGenerated = gql`
    query IsMonthlySub {
  isMonthlySub
}
    `;

/**
 * __useIsMonthlySubQueryGenerated__
 *
 * To run a query within a React component, call `useIsMonthlySubQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useIsMonthlySubQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsMonthlySubQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useIsMonthlySubQueryGenerated(baseOptions?: Apollo.QueryHookOptions<IsMonthlySubQueryGenerated, IsMonthlySubQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsMonthlySubQueryGenerated, IsMonthlySubQueryVariables>(IsMonthlySubDocumentGenerated, options);
      }
export function useIsMonthlySubLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<IsMonthlySubQueryGenerated, IsMonthlySubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsMonthlySubQueryGenerated, IsMonthlySubQueryVariables>(IsMonthlySubDocumentGenerated, options);
        }
export function useIsMonthlySubSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<IsMonthlySubQueryGenerated, IsMonthlySubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsMonthlySubQueryGenerated, IsMonthlySubQueryVariables>(IsMonthlySubDocumentGenerated, options);
        }
export type IsMonthlySubQueryGeneratedHookResult = ReturnType<typeof useIsMonthlySubQueryGenerated>;
export type IsMonthlySubLazyQueryGeneratedHookResult = ReturnType<typeof useIsMonthlySubLazyQueryGenerated>;
export type IsMonthlySubSuspenseQueryGeneratedHookResult = ReturnType<typeof useIsMonthlySubSuspenseQueryGenerated>;
export type IsMonthlySubQueryResult = Apollo.QueryResult<IsMonthlySubQueryGenerated, IsMonthlySubQueryVariables>;
export const UpgradeSubscriptionDocumentGenerated = gql`
    mutation UpgradeSubscription($cardId: String!) {
  upgradeSubscription(cardId: $cardId) {
    id
  }
}
    `;
export type UpgradeSubscriptionMutationFn = Apollo.MutationFunction<UpgradeSubscriptionMutationGenerated, UpgradeSubscriptionMutationVariables>;

/**
 * __useUpgradeSubscriptionMutationGenerated__
 *
 * To run a mutation, you first call `useUpgradeSubscriptionMutationGenerated` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeSubscriptionMutationGenerated` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeSubscriptionMutationGenerated, { data, loading, error }] = useUpgradeSubscriptionMutationGenerated({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useUpgradeSubscriptionMutationGenerated(baseOptions?: Apollo.MutationHookOptions<UpgradeSubscriptionMutationGenerated, UpgradeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpgradeSubscriptionMutationGenerated, UpgradeSubscriptionMutationVariables>(UpgradeSubscriptionDocumentGenerated, options);
      }
export type UpgradeSubscriptionMutationGeneratedHookResult = ReturnType<typeof useUpgradeSubscriptionMutationGenerated>;
export type UpgradeSubscriptionMutationResult = Apollo.MutationResult<UpgradeSubscriptionMutationGenerated>;
export type UpgradeSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpgradeSubscriptionMutationGenerated, UpgradeSubscriptionMutationVariables>;
export const GetPlanDetailsDocumentGenerated = gql`
    query GetPlanDetails {
  getPlanDetails {
    id
    title
    type
  }
}
    `;

/**
 * __useGetPlanDetailsQueryGenerated__
 *
 * To run a query within a React component, call `useGetPlanDetailsQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanDetailsQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanDetailsQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanDetailsQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetPlanDetailsQueryGenerated, GetPlanDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanDetailsQueryGenerated, GetPlanDetailsQueryVariables>(GetPlanDetailsDocumentGenerated, options);
      }
export function useGetPlanDetailsLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanDetailsQueryGenerated, GetPlanDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanDetailsQueryGenerated, GetPlanDetailsQueryVariables>(GetPlanDetailsDocumentGenerated, options);
        }
export function useGetPlanDetailsSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPlanDetailsQueryGenerated, GetPlanDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPlanDetailsQueryGenerated, GetPlanDetailsQueryVariables>(GetPlanDetailsDocumentGenerated, options);
        }
export type GetPlanDetailsQueryGeneratedHookResult = ReturnType<typeof useGetPlanDetailsQueryGenerated>;
export type GetPlanDetailsLazyQueryGeneratedHookResult = ReturnType<typeof useGetPlanDetailsLazyQueryGenerated>;
export type GetPlanDetailsSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetPlanDetailsSuspenseQueryGenerated>;
export type GetPlanDetailsQueryResult = Apollo.QueryResult<GetPlanDetailsQueryGenerated, GetPlanDetailsQueryVariables>;
export const GetMyWalletDocumentGenerated = gql`
    query GetMyWallet {
  getMyWallet {
    pk
    amount
    sortKey
    details
  }
}
    `;

/**
 * __useGetMyWalletQueryGenerated__
 *
 * To run a query within a React component, call `useGetMyWalletQueryGenerated` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWalletQueryGenerated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWalletQueryGenerated({
 *   variables: {
 *   },
 * });
 */
export function useGetMyWalletQueryGenerated(baseOptions?: Apollo.QueryHookOptions<GetMyWalletQueryGenerated, GetMyWalletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyWalletQueryGenerated, GetMyWalletQueryVariables>(GetMyWalletDocumentGenerated, options);
      }
export function useGetMyWalletLazyQueryGenerated(baseOptions?: Apollo.LazyQueryHookOptions<GetMyWalletQueryGenerated, GetMyWalletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyWalletQueryGenerated, GetMyWalletQueryVariables>(GetMyWalletDocumentGenerated, options);
        }
export function useGetMyWalletSuspenseQueryGenerated(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyWalletQueryGenerated, GetMyWalletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyWalletQueryGenerated, GetMyWalletQueryVariables>(GetMyWalletDocumentGenerated, options);
        }
export type GetMyWalletQueryGeneratedHookResult = ReturnType<typeof useGetMyWalletQueryGenerated>;
export type GetMyWalletLazyQueryGeneratedHookResult = ReturnType<typeof useGetMyWalletLazyQueryGenerated>;
export type GetMyWalletSuspenseQueryGeneratedHookResult = ReturnType<typeof useGetMyWalletSuspenseQueryGenerated>;
export type GetMyWalletQueryResult = Apollo.QueryResult<GetMyWalletQueryGenerated, GetMyWalletQueryVariables>;