import { StripeSubscription } from "./stripe/StripeSubscription";
import { SquareSubscription } from "./square/SquareSubscription";
import { withLogicHook } from "../../utils/withLogicHook";
import {
  SubscriptionPageProps,
  useSubscriptionPageLogic,
} from "./useSubscriptionPageLogic";
import LoadingIcons from "react-loading-icons";

const SubscriptionPageComponent = ({
  loading,
  error,
  stripeSubscription,
  squareSubscription,
  onSquareSubscriptionCanceled,
}: SubscriptionPageProps) => {
  return (
    <div className="flex flex-col pb-4">
      {loading && (
        <div className="flex h-full items-center justify-center">
          <LoadingIcons.Bars width="200px" />
        </div>
      )}
      {error && <div>{`${error}`}</div>}
      {!loading && stripeSubscription && (
        <StripeSubscription subscription={stripeSubscription} />
      )}
      {!loading && squareSubscription && (
        <SquareSubscription
          subscription={squareSubscription}
          onSubscriptionCanceled={onSquareSubscriptionCanceled}
        />
      )}
    </div>
  );
};

export const SubscriptionPage = withLogicHook(
  SubscriptionPageComponent,
  useSubscriptionPageLogic
);
