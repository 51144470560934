import {Typography} from "@mui/material"
import {useEffect, useState} from "react"
import { differenceInSeconds, parseISO } from 'date-fns';

type CountdownProps = {
  variant: "h1" | "gold";
  time: string;
}

export const Countdown = ({ variant, time }: CountdownProps) => {
  const [display, setDisplay] = useState("");
  const [distance, setDistance] = useState<number | null>(null);

  useEffect(() => {
    const exp = parseISO(`${time}Z`);

    const timer = setInterval(() => {
      const now = new Date();
      const distance = differenceInSeconds(exp, now);
      const hours = Math.floor(distance % (60 * 60 * 24) / (60 * 60));
      const minutes = Math.floor(distance % (60 * 60) / 60);
      const seconds = Math.floor(distance % 60);
      setDistance(distance);
      setDisplay(
        `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
          seconds < 10 ? `0${seconds}` : seconds}`
      );
    }, 1000);

    return () => clearInterval(timer);
  }, [time])

  return (
    <div className="div w-full flex justify-center items-center">
      { distance && distance <= 0 ? (
        <Typography variant="h5">Expiring Guest Pass...</Typography>
      ) : (
        <Typography variant={variant}>
          {display}
        </Typography>
      )}
    </div>
  );
}
