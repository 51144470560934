import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function InfoFooter() {
  return (
    <div className="mb-4 mt-4 flex flex-col px-8">
      <div className="flex w-full flex-col items-center text-[12px] font-[400] text-white">
        <div className="mt-4">
          <Typography variant="body2" className="text-center">
            <b>WARNING:</b> This website includes flashing lights that may
            potentially be harmful for people with photosensitive epilepsy.
            Viewer discretion is advised.
          </Typography>
        </div>

        <div className="mt-4 flex w-full flex-row items-center justify-center">
          <Link to={"/terms"} className="">
            <Typography variant="body1" className="uppercase">
              Terms & Conditions
            </Typography>
          </Link>

          <div className="mx-5 mt-[5px] h-[14px] w-[1px] bg-white"></div>

          <Link to={"/privacy"} className="">
            <Typography variant="body1" className="uppercase">
              Privacy Policy
            </Typography>
          </Link>
        </div>

        <div className="mt-4 flex flex-col text-center">
          <Typography variant="caption">© 2024 ZFM, LLC</Typography>
          <Typography variant="caption">© 2024 Amorphous Music</Typography>
          <Typography variant="caption">All rights reserved</Typography>
        </div>
      </div>
    </div>
  );
}
