import { Typography } from "@mui/material";

import { GetThoughtsLinksQueryGenerated } from "../types/generated";

type ThoughtLink = GetThoughtsLinksQueryGenerated["getThoughtsLinks"][0];

interface Props {
  link: ThoughtLink;
}

export function YoutubeLink({
  link: { date, title, description, url, variant },
}: Props) {
  return (
    <div className="mb-8 flex flex-col gap-4">
      {variant === "title-top" && (
        <div className="flex flex-col gap-2">
          <Typography variant="caption" className="opacity-50">
            {date}
          </Typography>
          <Typography variant="overline">{title}</Typography>
          <Typography variant="description">{description}</Typography>
        </div>
      )}
      <iframe
        width="100%"
        height="315px"
        src={url ?? undefined}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      {variant === "title-top" && <div className="divider" />}
      {variant === "title-bottom" && (
        <div className="flex items-end justify-between">
          <Typography variant="h5">{title}</Typography>
          <Typography variant="h6">{description}</Typography>
        </div>
      )}
    </div>
  );
}
