import { SquareSubscription } from "../types/generated";

export const getStatus = (subscription: SquareSubscription): string => {
  if (subscription.status === "ACTIVE") {
    if (subscription.canceledDate) {
      return "Canceled";
    }
    return "Active";
  }
  return "Expired";
};
