import { Link, useLocation } from "react-router-dom";
// import expore from "../assets/nav-logo.svg";
// import search from "../assets/search-icon.svg";
import explore from "../assets/explore.svg";
import community from "../assets/community-icon.svg";
import listen from "../assets/listen.svg";
import profile from "../assets/profile.svg";
import HelpBlock from "../assets/help-block.svg";
import { Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import { useContext } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { usePicture } from "../utils/hooks";
import { NotificationsContext } from "../Profile/NotificationsContext";
import {userIsGold} from "../Auth/utils";
// import OtherSide from "./OtherSide";

interface props {
  barNavigationRemoveUrls?: string[];
}

const BarNavigation = ({ barNavigationRemoveUrls = [] }: props) => {
  const { user, loggedIn } = useContext(AuthContext);
  const { unreadCount } = useContext(NotificationsContext);

  const picture = usePicture(user);

  const abbrevName = (arg: any) => {
    if (arg) {
      const result = arg.substring(0, 1);
      return result;
    }
  };

  const bgClass = userIsGold(user) ? 'bg-gold' : 'bg-black';
  const borderClass = userIsGold(user) ? 'border-white' : 'border-slate-500';

  const currentUrl = useLocation().pathname;

  if (!barNavigationRemoveUrls.includes(currentUrl)) {
    return (
      <nav className={`nav-bar ${bgClass} shadow-lg`}>
        <ul className="flex max-h-[86px] justify-around">
          <li className="w-1/5 py-3">
            <Link
              to={"/"}
              className={`flex h-full flex-col items-center justify-around border-r ${borderClass} text-white`}
            >
              <img src={explore} alt="" className="max-w-[35px]" />{" "}
              <Typography variant="caption">Explore</Typography>
            </Link>
          </li>
          <li className="w-1/5 py-3">
            <Link
              to={"/community/interact"}
              className={`flex h-full flex-col items-center justify-around border-r ${borderClass} text-white`}
            >
              <img src={community} alt="" className="max-w-[35px]" />{" "}
              <Typography variant="caption">Interact</Typography>
            </Link>
          </li>
          <li className="w-1/5 py-3">
            <Link
              to={"/listen"}
              className={`flex h-full flex-col items-center justify-around border-r ${borderClass} text-white`}
            >
              <img src={listen} alt="" className="max-w-[35px]" />{" "}
              <Typography variant="caption">Listen</Typography>
            </Link>
          </li>
          <li className="w-1/5 py-3">
            <Link
              to={"/support"}
              className={`flex h-full flex-col items-center justify-around border-r ${borderClass} text-white`}
            >
              <Badge badgeContent="!" color="primary" className="flex flex-col">
                <img
                  src={HelpBlock}
                  alt=""
                  className="max-h-[30px] max-w-[35px]"
                />{" "}
                <Typography variant="caption">Support</Typography>
              </Badge>
            </Link>
          </li>
          <li className="w-1/5 py-3">
            <Link
              to={loggedIn ? "/profile" : "/auth/signin"}
              className="flex h-full flex-col items-center justify-around text-white"
            >
              {loggedIn && user ? (
                <Badge
                  badgeContent={unreadCount}
                  color="error"
                  className="flex flex-col"
                >
                  <div
                    className="relative flex h-[35px] w-[33px] flex-col items-center justify-center rounded-full bg-white text-center"
                    style={
                      picture
                        ? {
                            backgroundImage: `url(${picture})`,
                            backgroundSize: "cover",
                          }
                        : undefined
                    }
                  >
                    {!picture && (
                      <div className="absolute uppercase text-black">
                        {abbrevName(user.username)}
                      </div>
                    )}
                  </div>
                  <Typography variant="caption">Profile</Typography>
                </Badge>
              ) : (
                <>
                  <img src={profile} alt="avatar" className="w-[35px]" />
                  <Typography variant="caption">Profile</Typography>
                </>
              )}
            </Link>
          </li>
        </ul>
      </nav>
    );
  } else {
    return null;
  }
};

export default BarNavigation;

/*
 *
 */
