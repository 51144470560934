import { SectionIndex } from "../Listen/SectionList";
import { ListenDetails } from "./Details";
import { Route } from "react-router-dom";
import Listen from ".";
import { SkeletonVariants, StrapiAlbumCategory } from "../types/generated";
import { Suspense } from "react";
import { Skeleton } from "../ui/Skeleton";

export function useListenRoutes() {
  return (
    <>
      <Route path="/listen" element={<Listen />} />

      <Route
        path="/listen/:contentType/:contentId"
        element={
          <Suspense
            fallback={<Skeleton variant={SkeletonVariants.trackList} />}
          >
            <ListenDetails />
          </Suspense>
        }
      />

      <Route
        path="/listen/discography"
        element={
          <Suspense
            fallback={<Skeleton variant={SkeletonVariants.sectionIndex} />}
          >
            <SectionIndex
              title="Discography"
              type={StrapiAlbumCategory.RELEASE}
            />
          </Suspense>
        }
      />

      <Route
        path="/listen/mixtapes"
        element={
          <Suspense
            fallback={<Skeleton variant={SkeletonVariants.sectionIndex} />}
          >
            <SectionIndex title="Mixtapes" type={StrapiAlbumCategory.MIXTAPE} />
          </Suspense>
        }
      />

      <Route
        path="/listen/unreleased"
        element={
          <Suspense
            fallback={<Skeleton variant={SkeletonVariants.sectionIndex} />}
          >
            <SectionIndex
              title="Unreleased"
              type={StrapiAlbumCategory.UNRELEASED}
            />
          </Suspense>
        }
      />

      <Route
        path="/listen/conversations"
        element={
          <Suspense
            fallback={<Skeleton variant={SkeletonVariants.sectionIndex} />}
          >
            <SectionIndex
              title="Conversations"
              type={StrapiAlbumCategory.CONVERSATION}
            />
          </Suspense>
        }
      />
    </>
  );
}
