import env from "react-dotenv";
import { useContext, useEffect } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import LoadingIcons from "react-loading-icons";

export const DiscordRedirect = () => {
  const { getIdToken } = useContext(AuthContext);

  useEffect(() => {
    const loadToken = async () => {
      const idToken = await getIdToken();

      const url = `https://discord.com/oauth2/authorize?client_id=1238256833102348419&response_type=code&redirect_uri=${encodeURIComponent(
        `${env.GQL_API_URL}/join-discord`
      )}&scope=identify+guilds.join&state=${idToken}`;

      window.location.href = url;
    };

    loadToken();
  }, []);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <LoadingIcons.Bars width="200px" />
    </div>
  );
};
