import React from "react";
import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import "../styles.css";

export interface TileListProps {
  title: string;
  seeAll?: boolean;
  seeAllAction?: () => void;
  seeAllLabel?: string;
  variant?: "album" | "video" | "artist" | "charity";
  direction?: "row" | "column";
}

export function TileList({
  children,
  title,
  seeAll,
  seeAllAction,
  seeAllLabel = "View More",
  variant = "album",
  direction = "row",
}: PropsWithChildren<TileListProps>) {
  let flex = "inline-flex";
  if (direction === "column") {
    flex = "";
  }
  return (
    <div className="z-10 mb-[26px] flex w-full flex-col lg:min-w-[1150px] lg:max-w-[1150px]">
      <div className="mb-2 flex w-full justify-between px-4">
        <Typography variant="h5">{title}</Typography>
        {seeAll && seeAllAction && (
          <div onClick={seeAllAction} className="cursor-pointer">
            <Typography variant="body1">{seeAllLabel}</Typography>
          </div>
        )}
      </div>
      <div
        className={`flex w-full items-start justify-start overflow-auto lg:max-w-[1150px]`}
      >
        {
          React.Children.toArray(children).map(
            (
              child,
              i //child
            ) => (
              <div
                key={`${title}-tile-${i}`}
                className={`tile h-fit
              ${
                variant === "video"
                  ? "min-w-[200px]"
                  : variant === "artist"
                  ? "min-w-[300px]"
                  : "sm:min-w-[120px] lg:min-w-[300px]"
              }
              ${
                variant === "video"
                  ? "max-w-[200px]"
                  : variant === "artist"
                  ? ""
                  : "sm:max-w-[120px] lg:min-w-[300px]"
              }
            mx-3`}
              >
                {child}
              </div>
            )
          )
          //.filter((c, index) => index < 1)
        }
      </div>
    </div>
  );
}
