import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthProvider";
import {userIsAGuest} from "./utils";
import {parseISO} from "date-fns";
import {Button, Typography} from "@mui/material";
import {Link} from "react-router-dom";

export const GuestPassGuard = () => {
  const [waitingForDowngrade, setWaitingForDowngrade] = useState(false);
  const { user, signOut, waitForUserGroup } = useContext(AuthContext);

  useEffect(() => {
    let interval:NodeJS.Timeout;
    const exp = localStorage.getItem("guestPassExpiration");

    if(exp && userIsAGuest(user)) {
      interval = setInterval(async () => {
        const time = parseISO(`${exp}Z`);
        if(time.valueOf() < new Date().valueOf()) {
          setWaitingForDowngrade(true);
          localStorage.removeItem("guestPassExpiration");
          await waitForUserGroup("guest-pass", true);
          signOut();
          setWaitingForDowngrade(false);
        }
      }, 1000)
    } else {
      if(userIsAGuest(user) && user?.guestPassExpiration) {
        localStorage.setItem('guestPassExpiration', user.guestPassExpiration);
        interval = setInterval(async () => {
          if(new Date(user.guestPassExpiration!).valueOf() < new Date().valueOf()) {
            setWaitingForDowngrade(true);
            localStorage.removeItem("guestPassExpiration");
            await waitForUserGroup("guest-pass", true);
            signOut();
            setWaitingForDowngrade(false);
          }
        }, 1000)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    };
  }, [signOut, user, user?.groups, user?.guestPassExpiration, waitForUserGroup]);

  return waitingForDowngrade ? (
    <div className="h-screen w-screen flex flex-col justify-center items-center fixed top-0 left-0 z-[999] bg-black bg-opacity-50">
      <div className="tos-body-bkg rounded-lg mx-4 p-4 text-center flex flex-col gap-4">
        <Typography variant="h4">Your guest pass has expired</Typography>
        <Typography variant="body1">We hope you enjoyed what The Other Side has to offer. You may still log into your Freestyle account and upgrade if you want to continue to experience the benefits of an unlocked membership.</Typography>
        <Typography variant="body2">You will be redirected to the login screen momentarily.</Typography>
        {/*
        <Link to="/auth/signin">
          <Button variant="bassnectar">Sign In</Button>
        </Link>
        */}
      </div>
    </div>
  ) : (
    <div />
  )
}
