import { Typography } from "@mui/material";

interface SectionHeroProps {
  title: string;
  background?: string;
}

export function SectionHero({ title, background }: SectionHeroProps) {
  // const bgClass = `bg-${background}`;
  return (
    <div className="relative flex min-h-[160px] w-full items-center justify-center">
      <div className="absolute left-0 top-0 z-0 flex h-[160px] w-full items-center">
        <div className="h-[140px] w-screen bg-frequency" />
      </div>
      <div className="z-10">
        <Typography variant="h1">{title}</Typography>
      </div>
    </div>
  );
}
