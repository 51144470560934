import { useContext, useState } from "react";
import { flushSync } from "react-dom";
import { MediaPlayerContext } from "./MediaPlayerProvider";
import { gradientString } from "../ui/Gradient";
import AlbumCard from "../Listen/AlbumCard";
import { FullScreenPlayerControls } from "./FullScreenPlayerControls";
import { FullScreenPlayerHeader } from "./FullScreenPlayerHeader";
import MediaQuery from "react-responsive";
import { Typography } from "@mui/material";

interface Props {
  onDismiss: () => void;
}

export function FullScreenPlayer({ onDismiss }: Props) {
  const [video, setVideo] = useState(true);
  const { nowPlayingAlbum, nowPlayingIndex, videoRef } =
    useContext(MediaPlayerContext);

  const visualCanvas =
    nowPlayingAlbum.trackList[nowPlayingIndex].visualCanvas ||
    nowPlayingAlbum.visualCanvas;

  const displayVideo = video && visualCanvas;

  return (
    <div
      className="pointer-events-auto top-0 z-[999] max-w-[100vw]"
      style={{ background: gradientString(nowPlayingAlbum.gradient) }}
    >
      <MediaQuery minWidth={1024}>
        <FullScreenPlayerHeader onDismiss={onDismiss} />
        <div className="h-screen w-screen">
          {displayVideo ? (
            <div className="z-1 absolute left-0 top-0 -mt-[100vh] h-[200vh] w-screen overflow-hidden">
              <video
                className="video-canvas"
                width="100%"
                height="100%"
                ref={videoRef}
                src={visualCanvas}
                playsInline
                loop
                muted
                autoPlay
              />
            </div>
          ) : (
            <div className="relative left-4 top-[100px] flex w-1/2 justify-center">
              <div className="px-36">
                <AlbumCard
                  artwork={nowPlayingAlbum.coverArt}
                  title={nowPlayingAlbum.trackList[nowPlayingIndex].title}
                  info={nowPlayingAlbum.artist}
                  variant="large"
                  hideImage={displayVideo}
                />
              </div>
            </div>
          )}
          <div className="z-2 fs-controls-bkg absolute bottom-0 left-0 flex w-screen">
            <div className="w-1/2 px-4">
              {displayVideo && (
                <div className="flex flex-col">
                  <Typography variant="h4">
                    {nowPlayingAlbum.trackList[nowPlayingIndex].title}
                  </Typography>
                  <Typography variant="h5">
                    {nowPlayingAlbum.trackList[nowPlayingIndex].artist ||
                      nowPlayingAlbum.artist}
                  </Typography>
                </div>
              )}
              <FullScreenPlayerControls
                onVideoClick={() => {
                  flushSync(() => {
                    setVideo(!video);
                  });
                  console.log(
                    "video url",
                    nowPlayingAlbum.trackList[nowPlayingIndex].visualCanvas
                  );
                  if (!video) {
                    videoRef.current?.play();
                  }
                }}
              />
            </div>
            <div className="flex w-1/2 flex-col px-4">
              {nowPlayingAlbum.trackList[nowPlayingIndex].notes !== null && (
                <section className="absolute bottom-8">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: nowPlayingAlbum.trackList[nowPlayingIndex].notes,
                    }}
                  />
                </section>
              )}
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1023}>
        <div className="relative top-0 flex flex-col">
          <FullScreenPlayerHeader onDismiss={onDismiss} />
          <div className="mt-[100px] px-4">
            {displayVideo && (
              <div className="z-1 absolute left-0 top-0 h-full w-full">
                <video
                  className="video-canvas"
                  width="100%"
                  height="100%"
                  ref={videoRef}
                  src={nowPlayingAlbum.trackList[nowPlayingIndex].visualCanvas}
                  playsInline
                  loop
                  muted
                  autoPlay
                />
              </div>
            )}
            <AlbumCard
              artwork={nowPlayingAlbum.coverArt}
              title={nowPlayingAlbum.trackList[nowPlayingIndex].title}
              info={nowPlayingAlbum.trackList[nowPlayingIndex].artist || nowPlayingAlbum.artist}
              variant="large"
              hideImage={displayVideo}
            />

            <FullScreenPlayerControls
              onVideoClick={() => {
                flushSync(() => {
                  setVideo(!video);
                });
                console.log(
                  "video url",
                  nowPlayingAlbum.trackList[nowPlayingIndex].visualCanvas
                );
                if (!video) {
                  videoRef.current?.play();
                }
              }}
            />

            {nowPlayingAlbum.trackList[nowPlayingIndex].notes !== null && (
              <section className="relative mb-9 w-full">
                <div
                  dangerouslySetInnerHTML={{
                    __html: nowPlayingAlbum.trackList[nowPlayingIndex].notes,
                  }}
                />
              </section>
            )}
          </div>
          {/* <TileList
          title="Up Next"
          seeAll={true}
          seeAllLabel={"View More"}
          seeAllAction={navigateToType(nowPlayingType)}
        >
          {data?.getContentByType.map((item) => (
            <AlbumCard
              info={item.artist ?? undefined}
              title={item.title ?? ""}
              artwork={item.artwork ?? undefined}
              key={item.id}
              onClick={navigateToAlbum(item.id, nowPlayingType)}
            />
          ))}
        </TileList> */}
        </div>
      </MediaQuery>
    </div>
  );
}
