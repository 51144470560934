import { Route, Navigate } from "react-router-dom";
import Thoughts from "../Thoughts";
import { Transmissions } from "../Thoughts/Transmissions";
import { TransmissionDetails } from "../Thoughts/TransmissionDetails";
import { QA } from "../Thoughts/QA";
import { QADetails } from '../Thoughts/QADetails';
import { Links } from "../Thoughts/Links";

export function useThoughtsRoutes() {
  return (
    <>
      <Route path="/thoughts" element={<Thoughts />}>
        <Route index element={<Navigate to="links" replace />} />
        <Route path="transmissions" element={<Transmissions />} />
        <Route
          path="transmissions/:transmissionId"
          element={<TransmissionDetails />}
        />
        <Route path="qa" element={<QA />} />
        <Route
          path="qa/:qaId"
          element={<QADetails />}
        />
        <Route path="links" element={<Links />} />
      </Route>
    </>
  );
}
