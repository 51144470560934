import { User } from "../types/User";
import { StrapiAlbum } from "../types/generated";

export const userGetsFreeContent = (user?: User | null) => 
    (user?.groups?.includes("admin") ||
    user?.groups?.includes("vip")) ??
  false

export const userIsAdmin = (user?: User | null) =>
  user?.groups?.includes("admin");

export const userIsVip = (user?: User | null) =>
  user?.groups?.includes("vip");

export const userIsGold = (user?: User | null) => 
  user?.groups?.includes("gold");

export const userIsUnlocked = (user?: User | null) =>
  user?.groups?.includes("unlocked");

export const userIsAGuest = (user?: User | null) =>
  user?.groups?.includes("guest-pass");

export const userIsFreestyle = (user?: User | null) =>
  user?.groups?.includes("freestyle");

export const userHasLinkedDiscord = (user?: User | null) => (
  user?.discordId !== undefined && user?.discordId !== null
);

export const getCssId = (user?: User | null) => {
  if (userIsGold(user)) {
    return "gold";
  }
  if (userIsUnlocked(user)) {
    return "unlocked";
  }
  if (user?.groups?.includes("freestyle")) {
    return "freestyle";
  }
  return "public";
};

export const userCanPlayTrack = (song: any, user?: User | null) => {
  return userIsUnlocked(user) || !song.Locked;
};

export const userCanPlayAlbum = (album?: StrapiAlbum, user?: User | null) =>
  (album?.trackList.filter((track) => userCanPlayTrack(track, user)).length ??
    0) > 0;
