import { Typography, TypographyVariant } from "@mui/material";
import { TypographyVariants } from "../../types/generated";

type TCProps = {
  body: string;
  variant: TypographyVariants;
};

export function TypographyComponent({
  body,
  variant = TypographyVariants.body,
}: TCProps) {
  return (
    <div className="px-4 lg:max-w-[900px]">
      <Typography variant={variant.valueOf() as TypographyVariant}>
        {body}
      </Typography>
    </div>
  );
}
