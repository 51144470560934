import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  from,
} from "@apollo/client";
import { AuthContext } from "./AuthProvider";
import { useContext, useEffect, useState } from "react";
import env from "react-dotenv";
import { setContext } from "@apollo/client/link/context";
import { mergePaginated } from "../utils/apollo";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { getCssId } from "./utils";

interface Props {
  children: React.ReactNode;
}

export const AuthedApolloProvider = ({ children }: Props) => {
  const { user, loggedIn, getIdToken } = useContext(AuthContext);
  const [token, setToken] = useState<string | undefined>(undefined);

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const [cssId, setCssId] = useState<string>();

  const buildClient = async (token: string | undefined) => {
    const httpLink = createHttpLink({
      uri: env.GQL_API_URL,
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `bearer ${token}` : "",
        },
      };
    });

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getAlbumsByCategory: {
              keyArgs: false,
              merge: mergePaginated,
            },
          },
        },
      },
    });

    await persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
    });

    setClient(
      new ApolloClient({
        uri: env.GQL_API_URL,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "cache-and-network",
          },
        },
        cache,
        link: from([authLink, httpLink]),
      })
    );
  };

  useEffect(() => {
    buildClient(token);
  }, [token]);

  useEffect(() => {
    const getToken = async () => {
      const token = await getIdToken();
      setToken(token);
      setCssId(getCssId(user));
    };
    getToken();
  }, [user, loggedIn, getIdToken]);

  if (!client) {
    return null;
  }

  return (
    <div id={cssId} className="flex">
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </div>
  );
};
