import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const Plan = () => {
  return (
    <div className="flex flex-col justify-center items-center pt-16 px-6">
      <h1 className="text-white font-bold text-xl my-4">My plan</h1>

      <div className="flex flex-col mb-6 w-full">
        <Link
          to={"/profile/plan"}
          className="flex justify-between items-center bg-black py-4 rounded-md bg-grey text-white px-4 border border-white mt-3"
        >
          <span>
            UNLOCKED{" "}
            <span className="text-gray-400 text-base">$100 per year</span>
          </span>
          <FaArrowRight />
        </Link>
      </div>

      <div className="flex flex-col mb-6 mt-4 w-full">
        <label
          htmlFor="username"
          className="text-white text-center text-2xl font-bold"
        >
          Payment history
        </label>
      </div>

      <Link
        to={"/profile"}
        className="py-2 rounded-md bg-gray-300 text-gray-700 text-center font-bold flex justify-center items-center px-4 mt-6 w-full"
      >
        <span>DONE</span>
      </Link>
    </div>
  );
};

export default Plan;
