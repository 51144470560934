import MediaQuery from "react-responsive";
import { SkeletonVariants } from "../types/generated";
import { FiftyFifty } from "./FiftyFifty";

import "./skeleton.css";
import "./styles.css";

type SkeletonProps = { variant: SkeletonVariants };

type SkeletonSizeVariant = "large";

const SkeletonTile = () => {
  return (
    <div className={`skeleton mr-4 aspect-square h-[120px] lg:h-[280px]`} />
  );
};

const SkeletonArtistImage = () => (
  <div className="skeleton aspect-[3/2] w-full" />
);

const SkeletonArtistTile = () => (
  <div className="flex w-full flex-col gap-4">
    <SkeletonArtistImage />
    <SkeletonHeadline />
    <SkeletonTitle />
  </div>
);

const SkeletonTitle = ({ variant }: { variant?: SkeletonSizeVariant }) => (
  <div
    className={`skeleton ${variant === "large" ? "h-[46px]" : "h-[20px]"} ${
      variant === "large" ? "w-full" : "w-[180px]"
    }`}
  />
);
const SkeletonHeadline = () => <div className="skeleton h-[32px] w-full" />;
const SkeletonDescription = () => (
  <div className="skeleton h-[12px] w-[140px]" />
);
const SkeletonTextButton = () => <div className="skeleton h-[20px] w-[60px]" />;

const SkeletonTrack = () => (
  <div className="flex flex-col gap-4">
    <SkeletonTitle />
    <SkeletonDescription />
  </div>
);

const SkeletonFeature = () => (
  <div className="skeleton flex h-[320px] w-screen" />
);

const SkeletonGridTile = () => (
  <div className="mb-4 flex w-full flex-col px-4 lg:w-1/4 lg:px-2">
    <div className="skeleton h-[180px] w-full" />
    <div className="my-2 flex flex-col gap-2">
      <SkeletonTitle />
      <SkeletonDescription />
    </div>
  </div>
);

const SkeletonTileList = () => (
  <div className="flex flex-col gap-4 lg:items-center">
    <div className="flex w-full items-baseline justify-between lg:max-w-[1150px]">
      <SkeletonTitle />
      <SkeletonTextButton />
    </div>
    <div className="inline-flex w-full overflow-x-hidden lg:max-w-[1150px]">
      <SkeletonTile />
      <SkeletonTile />
      <SkeletonTile />
      <SkeletonTile />
    </div>
  </div>
);

const SkeletonTileGrid = () => (
  <div className="flex w-screen lg:justify-center">
    <div className="flex flex-wrap lg:min-w-[1150px] lg:max-w-[1150px]">
      {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
        <SkeletonGridTile />
      ))}
    </div>
  </div>
);

type SkeletonIndexProps = {
  hideHero?: boolean;
  noFeature?: boolean;
  withGrid?: boolean;
};

const SkeletonIndex = ({
  hideHero,
  noFeature,
  withGrid,
}: SkeletonIndexProps) => (
  <div className="tos-body-bkg flex flex-col gap-8">
    {!hideHero && (
      <div className="bg-waveform h-[140px] w-screen mix-blend-overlay" />
    )}
    <div className="flex flex-col gap-8">
      {!noFeature && <SkeletonFeature />}
      {!withGrid && (
        <div className="flex flex-col gap-8 px-4">
          <SkeletonTileList />
          <SkeletonTileList />
          <SkeletonTileList />
        </div>
      )}
      {withGrid && <SkeletonTileGrid />}
    </div>
  </div>
);

const SkeletonArtists = () => (
  <div className="tos-body-bkg flex w-full flex-col gap-8">
    <div>
      <div className="bg-waveform h-[140px] w-screen mix-blend-overlay" />
    </div>
    <div className="flex flex-col gap-8 px-4">
      <SkeletonArtistTile />
      <SkeletonArtistTile />
      <SkeletonArtistTile />
      <SkeletonArtistTile />
      <SkeletonArtistTile />
    </div>
  </div>
);

const SkeletonSectionListItem = () => (
  <div className="tos-body-bkg flex lg:min-w-[820px] lg:max-w-[820px]">
    <SkeletonTile />
    <div className="align-center flex min-w-[50%] flex-col justify-center gap-2">
      <SkeletonHeadline />
      <SkeletonTitle />
    </div>
  </div>
);

const SkeletonSectionIndex = () => (
  <div className="tos-body-bkg flex w-full flex-col gap-8">
    <div>
      <div className="bg-waveform h-[140px] w-screen mix-blend-overlay" />
    </div>
    <div className="flex flex-col gap-8 lg:items-center">
      <SkeletonFeature />
      <div className="flex flex-col gap-4 px-4">
        <SkeletonSectionListItem />
        <SkeletonSectionListItem />
        <SkeletonSectionListItem />
      </div>
    </div>
  </div>
);

const SkeletonTrackList = () => (
  <div className="tos-body-bkg">
    <MediaQuery maxWidth={1023}>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-8 px-4">
          <SkeletonFeature />
          <SkeletonTrack />
          <SkeletonTrack />
          <SkeletonTrack />
        </div>
      </div>
    </MediaQuery>
    <MediaQuery minWidth={1024}>
      <FiftyFifty>
        <SkeletonFeature />
        <>
          <SkeletonTrack />
          <SkeletonTrack />
          <SkeletonTrack />
          <SkeletonTrack />
          <SkeletonTrack />
          <SkeletonTrack />
        </>
      </FiftyFifty>
    </MediaQuery>
  </div>
);

const SkeletonBanner = () => (
  <div className="tos-body-bkg w-full">
    <div className="flex w-full flex-col items-center justify-center gap-2 pb-1">
      <SkeletonTitle variant="large" />
      <SkeletonDescription />
    </div>
  </div>
);

export function Skeleton({ variant }: SkeletonProps) {
  switch (variant) {
    case "index":
    default:
      return <SkeletonIndex />;
    case "indexWithGrid":
      return <SkeletonIndex withGrid />;
    case "indexNoFeature":
      return <SkeletonIndex noFeature />;
    case "sectionIndex":
      return <SkeletonSectionIndex />;
    case "sectionItem":
      return <SkeletonSectionListItem />;
    case "artists":
      return <SkeletonArtists />;
    case "trackList":
      return <SkeletonTrackList />;
    case "banner":
      return <SkeletonBanner />;
    case "event":
      return <SkeletonIndex hideHero />;
  }
}
