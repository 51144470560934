import { Box, Slider, IconButton } from "@mui/material";
import {
  VideocamRounded,
  PauseCircle,
  PlayCircle,
  FastForward,
  FastRewind,
} from "@mui/icons-material";
import { Dispatch, SetStateAction, useContext } from "react";
import { MediaPlayerContext } from "./MediaPlayerProvider";
import { format } from "../utils/formatSecondsInMinutes";

interface ControlProps {
  onVideoClick: Dispatch<SetStateAction<void>>;
}

export function FullScreenPlayerControls({ onVideoClick }: ControlProps) {
  const {
    duration,
    nowPlayingIndex,
    nowPlayingAlbum,
    progress,
    nextSong,
    previousSong,
    setIsPlaying,
    setProgress,
    isPlaying,
    setIsSeeking,
    playerRef,
    started,
  } = useContext(MediaPlayerContext);

  const handleOnMouseUpSeekBar = (newValue: number) => {
    setIsSeeking(false);
    return playerRef.current?.seekTo(newValue);
  };

  return (
    <div className="relative z-[980] mb-10 mt-3 w-full lg:mb-0">
      <Slider
        value={progress.played}
        min={0}
        max={0.999999}
        step={0.000001}
        onChangeCommitted={(_, value) => handleOnMouseUpSeekBar(Number(value))}
        onChange={(_, value) =>
          setProgress({ ...progress, played: Number(value) })
        }
        sx={{
          color: "#fff",
          height: 8,
          "& .MuiSlider-thumb": {
            width: 8,
            height: 8,
            transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
          },
          "& .MuiSlider-rail": {
            opacity: 0.28,
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: -3,
        }}
      >
        <div className="pt-2 text-sm font-medium text-white">
          <span>{format(progress.playedSeconds)}</span>
        </div>
        <div className="ml-auto pt-2 text-sm font-medium text-white ">
          <span>{format(duration)}</span>
        </div>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 1,
        }}
      >
        <Box className="w-1/3">
          {nowPlayingAlbum.trackList[nowPlayingIndex].visualCanvas && (
            <IconButton onClick={() => onVideoClick()}>
              <VideocamRounded sx={{ color: "white" }} />
            </IconButton>
          )}
        </Box>

        <Box className="flex items-center">
          <IconButton onClick={previousSong}>
            <FastRewind sx={{ color: "white" }} fontSize="large" />
          </IconButton>
          <IconButton onClick={() => setIsPlaying(!isPlaying)}>
            {isPlaying && started ? (
              <PauseCircle sx={{ color: "white", fontSize: "70px" }} />
            ) : (
              <PlayCircle sx={{ color: "white", fontSize: "70px" }} />
            )}
          </IconButton>
          <IconButton onClick={nextSong}>
            <FastForward sx={{ color: "white" }} fontSize="large" />
          </IconButton>
        </Box>
        <Box className="w-1/3"></Box>
      </Box>
    </div>
  );
}
