import { StrapiEventType } from "../types/generated";
import { VimeoPlayer } from "../ui/VimeoPlayer";

type TrailerParams = {
  videoId: string;
  videoTitle: string;
};

type Props = {
  eventType?: StrapiEventType;
};

export function ShowTrailer({ eventType }: Props) {
  const eventTrailerMap: Record<StrapiEventType, TrailerParams[]> = {
    RELIVE: [
      {
        videoId: "832447490",
        videoTitle: "Relive 2 Preview - Underworld",
      },
    ],
    MIXTAPE: [
      {
        videoId: "832447727",
        videoTitle: "Enjoy The Ride",
      },
    ],
    SHOW: [
      {
        videoId: "832464649",
        videoTitle: "DestinationONE",
      },
    ],
    LIVE_STREAM: [
      {
        videoId: "832464649",
        videoTitle: "DestinationONE",
      },
    ],
  };

  return (
    <>
      {eventType &&
        eventTrailerMap[eventType].map((s) => (
          <div className="mt-5">
            <VimeoPlayer key={s.videoId} id={s.videoId} />
          </div>
        ))}
    </>
  );
}
