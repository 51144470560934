import { Join } from "../Sales/Join";
import { About } from "../Sales/About";
import { Subscribe } from "../Sales/Subscribe";
import { Route } from "react-router-dom";

export function useSalesRoutes() {
  return (
    <>
      {/* <Route path="/join" element={<Join />} />
      <Route path="/about" element={<About />} /> */}
      {/* <Route path="/subscribe" element={<Subscribe />} /> */}
    </>
  );
}
