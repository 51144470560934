function pad(value: number): string {
  return `0${value}`.slice(-2);
}

export function format(seconds: number): string {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

export function formatMilliseconds(mils: number): string {
  const hours = Math.floor((mils % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((mils % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((mils % (1000 * 60)) / 1000);
  return `${hours}:${pad(minutes)}:${pad(seconds)}`;
}
