import {
  createContext,
  PropsWithChildren,
  useRef,
  createRef,
  useState,
  useCallback,
  useContext,
} from "react";
import { flushSync } from "react-dom";
import ReactPlayer from "react-player";
import { AuthContext } from "../Auth/AuthProvider";
import { userCanPlayTrack } from "../Auth/utils";
import { StrapiAlbumCategory } from "../types/generated";

interface Progress {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

interface MediaPlayerContextParams {
  playerRef: React.RefObject<ReactPlayer>;
  videoRef: React.RefObject<HTMLVideoElement>;
  isPlaying: boolean;
  isPaused: boolean;
  progress: Progress;
  duration: number;
  nowPlayingAlbum: any;
  nowPlayingIndex: number;
  nowPlayingType: StrapiAlbumCategory | undefined;
  playerType: string;
  setIsSeeking: (isSeeking: boolean) => void;
  setIsPlaying: (isPlaying: boolean) => void;
  setDuration: (duration: number) => void;
  setProgress: (progress: Progress) => void;
  setURL: (url: string) => void;
  updatePlayer: (
    category: StrapiAlbumCategory | undefined,
    index: number,
    album: any,
    playPause: boolean
  ) => void;
  previousSong: () => void;
  nextSong: () => void;
  isBuffering: boolean;
  loaded: boolean;
  started: boolean;
  playbackError: boolean;
}

export const MediaPlayerContext = createContext<MediaPlayerContextParams>({
  playerRef: createRef(),
  videoRef: createRef(),
  isPlaying: false,
  isPaused: false,
  progress: { played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0 },
  duration: 0,
  nowPlayingAlbum: {},
  nowPlayingIndex: -1,
  nowPlayingType: StrapiAlbumCategory.RELEASE,
  playerType: "",
  setIsSeeking: (isSeeking) => {},
  setIsPlaying: (isPlaying) => {},
  setDuration: (duration) => {},
  setProgress: (progress) => {},
  setURL: (url) => {},
  updatePlayer: (index, album, playPause) => {},
  previousSong: () => {},
  nextSong: () => {},
  isBuffering: false,
  loaded: false,
  started: false,
  playbackError: false,
});

export function MediaPlayerProvider({ children }: PropsWithChildren) {
  const [isBuffering, setIsBuffering] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [started, setStarted] = useState(false);
  const [playbackError, setPlaybackError] = useState(false);
  const [nowPlayingIndex, setNowPlayingIndex] = useState(-1);
  const [nowPlayingType, setNowPlayingType] = useState(
    StrapiAlbumCategory.RELEASE
  );
  const [URL, setURL] = useState(" ");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [duration, setDuration] = useState(0);
  const [nowPlayingAlbum, setNowPlayingAlbum] = useState({
    id: 1,
    trackList: [],
    Song_List: [],
  });
  const [progress, setProgress] = useState<Progress>({
    loaded: 0,
    loadedSeconds: 0,
    played: 0,
    playedSeconds: 0,
  });
  const [isSeeking, setIsSeeking] = useState(false);
  const [playerType, setPlayerType] = useState("");
  const playerRef = useRef<ReactPlayer>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleDuration = useCallback(() => {
    setDuration(playerRef.current ? playerRef.current?.getDuration() : 0);
  }, []);

  const handleBufferStart = () => {
    setIsBuffering(true);
  };

  const handleBufferEnd = () => {
    setLoaded(true);
    setIsBuffering(false);
  };

  const handleError = () => {
    setPlaybackError(true);
  };

  const handleStart = () => {
    setStarted(true);
  };

  const onPlay = () => {
    console.log("onPlay");
    setIsPlaying(true);
  };

  const handleReady = useCallback(() => {
    console.log("player is ready");
  }, []);

  const { user } = useContext(AuthContext);

  const handlePausePlayer = useCallback(() => {
    // if(playerType === 'Mixcloud'){
    //   setIsPlaying(false)
    //   setTimeout(() => {
    //     setIsPlaying(true)
    //     setPlayerType('');
    //   }, 100);
    // } else {
    // outgoingMusicData(type, currentIndex, album, false);
    setIsPlaying(false);
    setIsPaused(true);
    // }
  }, []);

  const getArtworkMetadata = (artwork: any) => {
    return [
      {
        src: artwork,
        sizes: `200x200`,
        type: "png",
      },
    ];
  };

  const setMetadata = useCallback((album: any, index: number) => {
    const song = album.trackList[index];
    const artwork = getArtworkMetadata(album.coverArt);

    const meta = new MediaMetadata({
      title: song.Song_Name,
      artist: song.Artist || album.Artist,
      album: album.Title,
      artwork,
    });
    navigator.mediaSession.metadata = meta;
  }, []);

  const updatePlayer = useCallback(
    (
      category: StrapiAlbumCategory | undefined,
      index: number,
      album: any,
      playPause: boolean
    ) => {
      if (userCanPlayTrack(album.trackList[index], user!)) {
        const url = album.trackList[index].embedUrl;

        if (url.toLowerCase().indexOf("mixcloud.com") > -1) {
          setPlayerType("Mixcloud");
        } else {
          setPlayerType("");
        }

        if (
          album.id !== nowPlayingAlbum.id ||
          (album.id === nowPlayingAlbum.id && index !== nowPlayingIndex)
        ) {
          setNowPlayingAlbum(album);

          setNowPlayingType(category ?? StrapiAlbumCategory.RELEASE);

          setDuration(0);
          setProgress({
            loaded: 0,
            loadedSeconds: 0,
            played: 0,
            playedSeconds: 0,
          });
          setLoaded(false);
          setIsBuffering(false);
          setPlaybackError(false);
          setStarted(false);
        }

        flushSync(() => {
          setMetadata(album, index);
          setIsPlaying(playPause);
          setIsPaused(!playPause);
          setNowPlayingIndex(index);
          if (url !== URL) {
            setURL(url);
          }
        });
      } else {
        updatePlayer(category, index + 1, album, playPause);
      }
    },
    [URL, nowPlayingAlbum.id, nowPlayingIndex, setMetadata, user]
  );

  const handleProgress = useCallback(
    (newProgress: Progress) => {
      if (!isSeeking) {
        setProgress(newProgress);
      }
    },
    [isSeeking]
  );

  const nextSong = useCallback(() => {
    let nextIndex = nowPlayingIndex + 1;
    if (nextIndex >= nowPlayingAlbum.Song_List?.length) {
      nextIndex = 0;
    }
    while (!userCanPlayTrack(nowPlayingAlbum.trackList[nextIndex], user)) {
      nextIndex += 1;
      if (nextIndex >= nowPlayingAlbum.Song_List?.length) {
        nextIndex = 0;
      }
    }
    updatePlayer(nowPlayingType, nextIndex, nowPlayingAlbum, true);
  }, [nowPlayingIndex, nowPlayingType, nowPlayingAlbum, updatePlayer, user]);

  const previousSong = useCallback(() => {
    let previousIndex = nowPlayingIndex - 1;
    if (previousIndex < 0) {
      previousIndex = nowPlayingAlbum.Song_List?.length - 1;
    }
    while (!userCanPlayTrack(nowPlayingAlbum.trackList[previousIndex], user)) {
      previousIndex -= 1;
      if (previousIndex < 0) {
        previousIndex = nowPlayingAlbum.Song_List?.length - 1;
      }
    }
    updatePlayer(nowPlayingType, previousIndex, nowPlayingAlbum, true);
  }, [nowPlayingIndex, nowPlayingType, nowPlayingAlbum, updatePlayer, user]);

  return (
    <MediaPlayerContext.Provider
      value={{
        playerRef,
        videoRef,
        isPlaying,
        isPaused,
        duration,
        progress,
        nowPlayingAlbum,
        nowPlayingIndex,
        nowPlayingType,
        playerType,
        updatePlayer,
        setURL: (url) => {
          setURL(url);
        },
        setIsSeeking: (isSeeking) => {
          setIsSeeking(isSeeking);
        },
        setIsPlaying: (isPlaying) => {
          setIsPlaying(isPlaying);
        },
        setDuration: (duration) => {
          setDuration(duration);
        },
        setProgress: (progress) => {
          setProgress(progress);
        },
        nextSong,
        previousSong,
        loaded,
        started,
        isBuffering,
        playbackError,
      }}
    >
      {children}
      <ReactPlayer
        ref={playerRef}
        className={`absolute bottom-[2.3rem] z-[999]`}
        width={playerType === "Mixcloud" ? "100%" : "0%"}
        height={playerType === "Mixcloud" ? "5.5rem" : "0%"}
        onReady={handleReady}
        url={URL}
        playing={isPlaying}
        //volume={volumePlayer}
        onDuration={handleDuration}
        onPlay={onPlay}
        onPause={handlePausePlayer}
        onProgress={handleProgress}
        onEnded={nextSong}
        onBuffer={handleBufferStart}
        onBufferEnd={handleBufferEnd}
        onError={handleError}
        onStart={handleStart}
      />
    </MediaPlayerContext.Provider>
  );
}
