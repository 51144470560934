import { SectionHero } from "../ui/components/SectionHero";
import { Typography } from "@mui/material";
import LoadingIcons from "react-loading-icons";
import {
  useGetArcadesByGameIdLazyQueryGenerated,
  useGetUserHighScoresQueryGenerated,
} from "../types/generated";
import { useEffect, useState } from "react";

interface Cell {
  name: string;
  score: number;
  badge?: string;
}

export function Scores() {
  const { data, loading: isLoading } = useGetUserHighScoresQueryGenerated();

  const [cells, setCells] = useState<Cell[]>([]);

  const [getMatchingArcades, { data: arcades, loading, error }] =
    useGetArcadesByGameIdLazyQueryGenerated();

  useEffect(() => {
    if (data?.getUserHighScores) {
      getMatchingArcades({
        variables: {
          gameIds: data.getUserHighScores.map((d) => d.event),
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (data?.getUserHighScores && arcades?.getArcadesByGameId) {
      const cells: Cell[] = data.getUserHighScores.map((score) => {
        const arcade = arcades.getArcadesByGameId.find(
          (a) => a.gameId === score.event
        );
        return {
          name: arcade?.name || "",
          score: score.score,
          badge: arcade?.badge || "",
        };
      });

      setCells(cells);
    }
  }, [data, arcades]);
  return (
    <div id="scores-page">
      <SectionHero title="High Scores" />
      {isLoading || loading ? (
        <div className="flex flex-col items-center">
          <div style={{ margin: "0 auto", width: "200px" }}>
            <LoadingIcons.Bars width="200px" />
          </div>
          <Typography variant="h6">Loading your high scores</Typography>
        </div>
      ) : (
        <div className="flex min-w-[100vw] flex-col lg:items-center">
          <div className="flex flex-col gap-4 px-4 py-4 lg:max-w-[750px]">
            {cells.map((cell, index) => (
              <div
                key={index}
                className="flex w-full items-center justify-between"
              >
                <div className="flex items-center justify-start">
                  <img
                    className="mr-4"
                    src={cell.badge}
                    alt={cell.name}
                    style={{ height: 50 }}
                  />
                  <Typography variant="h6">{cell.name}</Typography>
                </div>
                <Typography variant="h5">{cell.score}</Typography>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
