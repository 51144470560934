import { Button, IconButton, Typography, Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GuestPass from '../assets/guest.svg';
import Keys from '../assets/wallet-key.svg';
import {PurchaseKeysForm} from "./PurchaseKeysForm";
import {
  useProcessKeyPurchaseMutationGenerated,
  usePurchaseGuestPassMutationGenerated,
} from "../types/generated";
import LoadingIcons from "react-loading-icons";

interface PurchaseGuestPassModalProps {
  open: boolean;
  keyBalance: number;
  onDismiss: () => void;
}

export const PurchaseGuestPassModal = ({ open, onDismiss, keyBalance }: PurchaseGuestPassModalProps) => {
  const [purchasePass, { loading, error }] = usePurchaseGuestPassMutationGenerated( {
    refetchQueries: ['GetMyWallet'],
  });

  const [purchaseKeys, { loading: loadingKeys, error: keysError }] = useProcessKeyPurchaseMutationGenerated();

  const handleReloadSubmit = async (cardId: string, quantity: number) => {
    try {
      const { data } = await purchaseKeys({ variables: { input: { cardId, quantity }}});
      const { data: passData } = await purchasePass();
      if(data?.processKeyPurchase && passData?.purchaseGuestPass) {
        onDismiss();
      }
    } catch (err) {
      console.log("Couldn't purchase keys");
    }
  };

  const handleSubmit = async () => {
    await purchasePass();   
    onDismiss();
  };

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex justify-center items-center"
    >
      <div className=" flex h-full w-full lg:max-w-[760px] items-center justify-center">
        <Box className="h-{350px} bg-light-gold relative mx-6 w-full rounded-2xl p-6">
          <div className="flex items-center justify-between">
            <div className="z-0 my-4 flex items-center gap-4">
              <img src={GuestPass} alt="keys-icon" style={{ height: 50 }} />
              <Typography variant="h3" sx={{ color: '#c69c6d' }}>Purchase A Guest Pass</Typography>
            </div>
            <IconButton onClick={onDismiss} sx={{ color: "#000" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="flex flex-col gap-2 mt-4">
            {(error || keysError) && (
              <div className="w-full py-2 px-4 bg-red-700 rounded-md">
                {error && (
                  <Typography variant="h6" color="white">{error.message}</Typography>
                )}
                {keysError && (
                  <Typography variant="h6" color="white">{keysError.message}</Typography>
                )}
              </div>
            )}
            {loading || loadingKeys ? (
              <div className="flex flex-col items-center gap-2 my-4">
                <LoadingIcons.Bars fill='#c69c6d' />
                <Typography variant="body1" sx={{ color: '#c69c6d' }}>
                  Purchasing Guest Pass...
                </Typography>
              </div>
            ) : (
              <>
                <div className="flex gap-4 items-center">
                  <img src={Keys} alt="keys-icon" style={{ height: 25 }} />
                  <Typography variant="h6" color="black">Price: 25 Keys</Typography>
                </div>
                <div className="flex gap-4 items-center">
                  <img src={Keys} alt="keys-icon" style={{ height: 25 }} />
                  <Typography variant="h6" color="black">
                    {`Your Balance: ${keyBalance} Key(s)`}
                  </Typography>
                </div>
                {keyBalance >= 25 ? (
                  <Button 
                    variant="bassnectarDarkGold"
                    onClick={handleSubmit}
                    sx={{ marginTop: 2 }}
                  >
                    Purchase A Guest Pass
                  </Button>
                ) : ( 
                  <div className="flex flex-col gap-2 mt-4">
                    <Typography variant="h5" color="black">Reload Key Balance</Typography>
                    <Typography variant="h6" color="black">
                      In Order to Purchase a Guest Pass, you will have to purchase a key bundle.
                    </Typography>
                    <PurchaseKeysForm
                      handleSubmit={handleReloadSubmit}
                      submitLabel="Add Keys & Purchase Guest Pass"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </Box>
      </div>
    </Modal>
  )
}
