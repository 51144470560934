// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";

import Swiper, {
  EffectCoverflow,
  Keyboard,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper as ReactSwiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { MediaPlayerContext } from "../Media/MediaPlayerProvider";
import { NavigationContext } from "../Navigation/NavigationProvider";
import { AuthContext } from "../Auth/AuthProvider";
import { HomeCard, useGetHomeCardsQueryGenerated } from "../types/generated";
import LoadingIcons from "react-loading-icons";

export function Cards() {
  const [sortedCards, setSortedCards] = useState<HomeCard[]>([]);
  const { cardIndex, handleCardIndexChange } = useContext(NavigationContext);
  const { nowPlayingIndex } = useContext(MediaPlayerContext);
  const { loggedIn } = useContext(AuthContext);

  const { data, loading } = useGetHomeCardsQueryGenerated();

  const onSwiper = () => {};
  const onChange = (swiper: Swiper) => handleCardIndexChange(swiper.realIndex);

  let maxHeight: string;
  if (window.innerHeight < 600) {
    maxHeight =
      nowPlayingIndex > -1 || !loggedIn ? "max-h-[300px]" : "max-h-[350px]";
  } else {
    maxHeight = "max-h-[400px]";
  }

  useEffect(() => {
    if(data && data.getHomeCards && data.getHomeCards.length > 0) {
      const cards = [...data.getHomeCards];
      cards.sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      })
      console.log(cards);
      setSortedCards(cards);
    }
  }, [data]);

  return (
    <div id="home-cards" className="flex h-full w-full flex-col justify-center">
      <div className="swiper-container">
        <ReactSwiper
          onSwiper={onSwiper}
          onSlideChange={onChange}
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          modules={[EffectCoverflow, Keyboard, Navigation, Pagination]}
          breakpoints={{
            320: {
              slidesPerView: 1.4,
              keyboard: {
                enabled: false,
              },
              navigation: {
                enabled: false,
              },
            },
            1024: {
              slidesPerView: 2.4,
              keyboard: {
                enabled: true,
              },
              navigation: {
                enabled: true,
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
            },
          }}
          spaceBetween={15}
          initialSlide={cardIndex}
          loop={true}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          className="mySwiper py-4"
          style={{ paddingBottom: 40 }}
        >
          {loading && !data ? (
            <div className="flex w-full items-center justify-center">
              <LoadingIcons.Bars />
            </div>
          ) : (
            sortedCards.map(({ title, image, url, cssId }, index) => {
              if (!url) return null;
              return (
                <SwiperSlide key={index}>
                  <Link
                    to={url}
                    target={url.includes("http") ? "_blank" : undefined}
                  >
                    <div
                      id={cssId ? cssId : `card-${index}`}
                      className={`swiper-item__bg relative m-auto ${maxHeight} aspect-[1/1.5] md:max-h-[48vw] lg:max-h-[30vw]`}
                    >
                      <img
                        alt={title}
                        src={image}
                        className="swiper-item__bg-img"
                      />
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })
          )}
        </ReactSwiper>
      </div>
    </div>
  );
}
