import { PauseCircle, PlayCircle } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import { IconButton } from "@mui/material";
import { useContext } from "react";
import { MediaPlayerContext } from "../Media/MediaPlayerProvider";

interface Props {
  locked: boolean;
  id: number;
  onPlayAll: () => void;
}

export function PlayPauseButton({ locked, id, onPlayAll }: Props) {
  // const [albumIsNowPlaying, setAlbumIsNowPlaying] = useState(false);
  // const [buttonAction, setButtonAction] = useState<() => void>(() => {});
  const { isPlaying, isPaused, nowPlayingAlbum, setIsPlaying, playbackError } =
    useContext(MediaPlayerContext);

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleResume = () => {
    setIsPlaying(true);
  };

  const isAlbumPlaying = nowPlayingAlbum.id == id;

  let buttonAction = onPlayAll;
  if (locked) {
    buttonAction = () => {};
  } else if (isPlaying && isAlbumPlaying) {
    buttonAction = handlePause;
  } else if (isPaused && isAlbumPlaying) {
    buttonAction = handleResume;
  }

  return (
    <IconButton onClick={buttonAction}>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#6DFE95" />
          <stop offset={1} stopColor="#1CF9D7" />
        </linearGradient>
      </svg>
      {locked && (
        <div className="play-button-locked">
          <LockIcon sx={{ fontSize: "32px" }} />
        </div>
      )}
      {(playbackError || (!locked && (!isPlaying || !isAlbumPlaying))) && (
        <PlayCircle sx={{ fill: "url(#linearColors)", fontSize: "50px" }} />
      )}
      {!locked && isPlaying && isAlbumPlaying && !playbackError && (
        <PauseCircle sx={{ fill: "url(#linearColors)", fontSize: "50px" }} />
      )}
    </IconButton>
  );
}
