import env from "react-dotenv";
import { IconButton, Typography, Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import { TokenResult } from "@square/web-payments-sdk-types";
import LoadingIcons from "react-loading-icons";
import {GetUserCardsOnFileDocumentGenerated, useAddPaymentMethodMutationGenerated} from "../types/generated";

interface Props {
  open: boolean;
  onDismiss: () => void;
}

export function AddCardModal({ open, onDismiss }: Props) {
  const [addCard, {loading, error}] = useAddPaymentMethodMutationGenerated();

  const appId = env.SQUARE_APP_ID;
  const locationId = env.SQUARE_LOCATION_ID;
  

  const onPayment = async (props: TokenResult) => {
    try {
      await addCard({
        variables: {
          input: {
            sourceId: props.token!,
            card: props.details?.card!,
          },
        },
        refetchQueries: [{ query: GetUserCardsOnFileDocumentGenerated }],
      })
      onDismiss();
    } catch (err: any) {
    }
  };

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className=" flex h-full w-full items-center justify-center">
        <Box className="h-{350px} tos-body-bkg relative mx-6 w-fit rounded-2xl p-6">
          <IconButton
            onClick={onDismiss}
            sx={{
              color: "#67F99B",
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 999,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="z-0 mt-12 flex items-center justify-center">
            <Typography id="modal-modal-title" variant="h5">
              Add a Payment Method
            </Typography>
          </div>

          <div className="my-4 mt-12 flex items-center justify-center">
            {error && (
              <Typography variant="body1">
                Sorry we were not able to add your card at this time..
              </Typography>
            )}
          </div>

          {loading && (
            <div>
              <div style={{ margin: "0 auto", width: "200px" }}>
                <LoadingIcons.Bars width="198px" />
              </div>
            </div>
          )}
          {!loading && (
            <>
              <PaymentForm
                applicationId={appId}
                locationId={locationId}
                cardTokenizeResponseReceived={onPayment}
              >
                <CreditCard
                  buttonProps={{
                    css: {
                      fontSize: 16,
                      borderRadius: 40,
                      background:
                        "linear-gradient(180deg, #6DFE95 0%, #6DFE95 30%, #0cb0e8 100%);",
                      fontFamily: "HexFranklinBold",
                      color: "black",
                      textTransform: "none",
                    },
                  }}
                >
                  ADD PAYMENT METHOD
                </CreditCard>
              </PaymentForm>
            </>
          )}
        </Box>
      </div>
    </Modal>
  );
}

