import { SectionHero } from "../ui/components/SectionHero";
import { ListItem } from "../ui/ListItem";
import {
  SkeletonVariants,
  StrapiAlbumCategory,
  useGetAlbumsByCategorySuspenseQueryGenerated,
} from "../types/generated";
import { Skeleton } from "../ui/Skeleton";
import { useCallback, useEffect, useState } from "react";
import { InfiniteScroller } from "../ui/InfiniteScroller";
import { FeaturedContent } from "../ui/FeaturedContent";

interface SectionIndexProps {
  title: string;
  type: StrapiAlbumCategory;
}

export function SectionIndex({ title, type }: SectionIndexProps) {
  const [totalItems, setTotalItems] = useState(0);

  const { data, fetchMore } = useGetAlbumsByCategorySuspenseQueryGenerated({
    variables: {
      category: type,
      start: 0,
      limit: 10,
    },
  });

  const getNextPage = useCallback(() => {
    fetchMore({
      variables: {
        start: data?.getAlbumsByCategory?.results?.length || 0,
        limit: 10,
      },
    });
  }, [fetchMore, data?.getAlbumsByCategory.results.length]);

  useEffect(() => {
    if (data?.getAlbumsByCategory.pagination.total) {
      setTotalItems(data.getAlbumsByCategory.pagination.total);
    }
  }, [data]);

  return (
    <div className="flex min-w-[100vw] flex-col md:min-w-auto">
      <SectionHero title={title} />
      {data && data.getAlbumsByCategory.results.length > 0 && (
        <InfiniteScroller
          skeleton={
            <div className="flex px-4 lg:min-w-[100vw] lg:justify-center">
              <Skeleton variant={SkeletonVariants.sectionItem} />
            </div>
          }
          onLoadRequest={getNextPage}
          allResultsLoaded={
            (data && data?.getAlbumsByCategory.results.length >= totalItems) ||
            false
          }
        >
          <div className="flex min-w-[100vw] flex-col items-center ">
            {data &&
              data.getAlbumsByCategory?.results?.length > 0 &&
              data.getAlbumsByCategory.results.map((tile: any, i: number) => {
                return i === 0 ? (
                  <FeaturedContent
                    key="listen-section-feature"
                    headline="Latest Release"
                    title={tile.title}
                    artwork={tile.coverArt}
                    subtitle={tile.artist}
                    detailSlug={tile.detailSlug}
                    index={i}
                  />
                ) : (
                  <div className="flex w-full px-4 lg:min-w-[844px] lg:max-w-[844px]">
                    <ListItem
                      key={tile.id}
                      path={`/listen/albums/${tile.id}`}
                      title={tile.title ?? ""}
                      artwork={tile.coverArt ?? ""}
                      info={tile.artist ?? ""}
                    />
                  </div>
                );
              })}
          </div>
        </InfiniteScroller>
      )}
    </div>
  );
}
