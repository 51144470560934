import { useEffect, useState } from "react";
import {
  SquareSubscription,
  useCancelSubscriptionMutationGenerated,
} from "../../../types/generated";
import { getStatus } from "../../../methods/SquareSubscriptionMethods";
import env from "react-dotenv";

interface SquareSubscriptionInputProps {
  subscription: SquareSubscription;
  onSubscriptionCanceled: (subscription: SquareSubscription) => void;
}

interface Cell {
  label: string;
  value: string;
}

export const useSquareSubscriptionLogic = ({
  subscription,
  onSubscriptionCanceled,
}: SquareSubscriptionInputProps) => {
  const [cells, setCells] = useState<Cell[]>([]);

  const [
    cancelSubscription,
    { loading: mutationLoading, error: mutationError, data },
  ] = useCancelSubscriptionMutationGenerated();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (data?.cancelSubscription) {
      onSubscriptionCanceled(data.cancelSubscription);
    }
  }, [data, onSubscriptionCanceled]);

  useEffect(() => {
    const buildCells = (subscription: SquareSubscription): Cell[] => {
      return [
        {
          label: "Plan",
          value:
            subscription.planVariationId == env.SQUARE_MONTHLY_PLAN_VARIATION_ID
              ? "Monthly"
              : "Annual",
        },
        {
          label: "Price",
          value: `$${
            subscription.planVariationId == env.SQUARE_MONTHLY_PLAN_VARIATION_ID
              ? "10"
              : "120"
          }`,
        },
        {
          label: "Status",
          value: getStatus(subscription),
        },
        {
          label: "Subscription Start",
          value: subscription.startDate ?? "",
        },
        {
          label: "Next Payment Date",
          value: subscription.chargedThroughDate ?? "",
        },
      ];
    };

    setCells(buildCells(subscription));
  }, [subscription]);

  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      await cancelSubscription();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setShowDialog(false);
      setLoading(false);
    }
  };

  const openDialog = () => setShowDialog(true);
  const closeDialog = () => setShowDialog(false);

  return {
    cells,
    cancelSubscription: handleCancelSubscription,
    loading: mutationLoading || loading,
    error: mutationError?.message ?? error,
    isActive: getStatus(subscription) === "Active",
    showDialog,
    openDialog,
    closeDialog,
  };
};

export type SquareSubscriptionProps = ReturnType<
  typeof useSquareSubscriptionLogic
>;
