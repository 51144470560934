import { useContext, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { usePicture } from "../utils/hooks";

const Avatar = () => {
  const { user } = useContext(AuthContext);

  const picture = usePicture(user);

  const [newProfilePic, setNewProfilePic] = useState<File | null>(null);

  const { updatePicture } = useContext(AuthContext);

  const handleProfilePicChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;

    setNewProfilePic(file);
    if (file) {
      await updatePicture(file);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className="relative flex h-40 w-40 items-center justify-center rounded-full"
        style={
          newProfilePic
            ? {
                backgroundImage: `url(${URL.createObjectURL(newProfilePic)})`,
                backgroundSize: "cover",
              }
            : picture
            ? {
                backgroundImage: `url(${picture})`,
                backgroundSize: "cover",
              }
            : {
                background:
                  "linear-gradient(180deg, #6DFE95 0%, #6DFE95 30%, #0cb0e8 100%)",
              }
        }
      >
        <input
          type="file"
          accept="image/jpeg, image/png"
          className="absolute z-50 h-full w-full opacity-0"
          onChange={handleProfilePicChange}
        />
        {!user?.picture && !newProfilePic && (
          <>
            <span className="mt-2 text-sm text-black opacity-50">
              Upload Profile Picture
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default Avatar;
