import { useState } from 'react';
import { Button, IconButton, Typography, Modal, Box, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GuestPass from '../assets/guest.svg';
import {useInviteGuestMutationGenerated} from "../types/generated";
import LoadingIcons from 'react-loading-icons';

interface UseGuestPassModalProps {
  open: boolean;
  onDismiss: () => void;
}

export const UseGuestPassModal = ({ open, onDismiss }: UseGuestPassModalProps) => {
  const [confirm, setConfirm] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string|null>(null);
  const [invite, { loading }] = useInviteGuestMutationGenerated({
    refetchQueries: ['GetMyWallet'],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }

  const handleSubmit = async () => {
    const response = await invite({ variables: { email } });
    if(response.data?.inviteGuest) {
      clear();
    } else {
      setError("Unable to send invite. Please try again later.");
    }
  };
  
  const sendToConfirm = () => {
    setConfirm(true);
  };

  const clear = () => {
    setConfirm(false);
    setError(null);
    setEmail("");
    onDismiss();
  }

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex justify-center items-center"
    >
      <div className="flex h-full w-full lg:max-w-[760px] items-center justify-center">
        <Box className="h-{350px} bg-light-gold relative mx-6 w-full rounded-2xl p-6">
          <div className="flex items-center justify-between">
            <div className="z-0 my-4 flex items-center gap-4">
              <img src={GuestPass} alt="keys-icon" style={{ height: 50 }} />
              <Typography variant="h3" sx={{ color: '#c69c6d' }}>Use A Guest Pass</Typography>
            </div>
            <IconButton onClick={clear} sx={{ color: "#000" }}>
              <CloseIcon />
            </IconButton>
          </div>
          {loading ? (
            <div className="flex flex-col items-center gap-2 my-4">
              <LoadingIcons.Bars fill='#c69c6d' />
              <Typography variant="body1" sx={{ color: '#c69c6d' }}>
                Sending Invite...
              </Typography>
            </div>
          ) : (
            <>
              {confirm ? (
                <div className="flex flex-col gap-2 my-4">
                  {error && (
                    <div className="w-full py-2 px-4 bg-red-700 rounded-md">
                      <Typography variant="h6" color="white">{error}</Typography>
                    </div>
                  )}
                  <Typography variant="h5" color="black">
                    Confirmation
                  </Typography>

                  <Typography variant="body1" sx={{ color: '#000' }}>
                    Please confirm that you are about to spend 1 guest pass to invite the following user:
                  </Typography>

                  <div className="flex flex-col gap-4 mt-4">
                    <Typography variant="h3" className="text-center" sx={{ color: '#000' }}>{email}</Typography>
                    <Button variant="bassnectarDarkGold" onClick={handleSubmit}>Confirm and Send Invite</Button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-2 my-4">
                  <Typography variant="h5" color="black">
                    Give the gift of unlocked
                  </Typography>

                  <Typography variant="body1" sx={{ color: '#000' }}>
                    Please enter the email of the person you would like to gift
                    a 24-Hour unlocked pass. This person will receive an email with
                    instructions on how to use the pass.
                  </Typography>

                  <div className="flex flex-col gap-4 mt-4">
                    <TextField label="Email" variant="filled" value={email} onChange={handleChange} />
                    <Button variant="bassnectarDarkGold" onClick={sendToConfirm}>Send Invite</Button>
                  </div>
                </div>
              )}
            </>
          )}
        </Box>
      </div>
    </Modal>
  )
}
