import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTixrRedirect } from "../utils/hooks";
import { StrapiTreasureItem } from "../types/generated";

interface TreasureItemProps {
  item: StrapiTreasureItem;
}

export function TreasureItem({ item }: TreasureItemProps) {
  const tixrRedirect = useTixrRedirect();

  return item.hidden ? null : (
    <div className="mr-2 flex h-[100px] flex-col items-center">
      {item.ctaType === "link" && item.ctaPath && (
        <Link to={item.ctaPath}>
          <img src={item.artwork!} alt={item.name} style={{ height: 75 }} />
          <Typography variant="caption">{item.description}</Typography>
        </Link>
      )}

      {item.ctaType === "tixr" && (
        <div onClick={tixrRedirect}>
          <img src={item.artwork!} alt={item.name} style={{ height: 75 }} />
          <Typography variant="caption">{item.description}</Typography>
        </div>
      )}

      {!item.ctaType && (
        <>
          <img src={item.artwork!} alt={item.name} style={{ height: 75 }} />
          <Typography variant="caption">{item.description}</Typography>
        </>
      )}
    </div>
  );
}
