import { createRef, useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import BarNavigation from "../Navigation/BarNavigation";
import OtherSide from "../Sales/OtherSide";
import { Player } from "../Media/Player";
import TopNavigation from "../Navigation/TopNavigation";
import { MediaPlayerContext } from "../Media/MediaPlayerProvider";
import { AuthContext } from "../Auth/AuthProvider";
// import { TakeoverNotification } from "./TakeoverNotification";
import { EventBanner } from "../Sales/EventBanner";
import { LiveEventContext } from "../Events/LiveEventProvider";
import { FullScreenPlayer } from "../Media/FullScreenPlayer";
import { DimensionProvider } from "./DimensionProvider";
// import { Skeleton } from "./Skeleton";

interface Props {
  noTabs?: boolean;
  minimal?: boolean;
}

export function Page({ noTabs = false, minimal = false }: Props) {
  const [bannerDismissed, setBannerDismissed] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { nowPlayingIndex } = useContext(MediaPlayerContext);
  const { authenticating, loggedIn } = useContext(AuthContext);

  const { eventDay, eventEnded, eventLoading } = useContext(LiveEventContext);

  const excludeBannerPatterns = [
    /^\/about$/g,
    /^\/join$/g,
    /^\/auth.*$/g,
    /^\/signin.*$/g,
  ];
  const { pathname } = useLocation();
  const excludeBanner = excludeBannerPatterns.some((p) => p.test(pathname));

  const footerRef = createRef<HTMLDivElement>();
  const headerRef = createRef<HTMLDivElement>();
  const observerRootRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!footerRef.current) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      setFooterHeight(footerRef?.current?.clientHeight || 0);
    });
    resizeObserver.observe(footerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [footerRef]);

  useEffect(() => {
    if (!headerRef.current) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      setHeaderHeight(headerRef?.current?.clientHeight || 0);
    });
    resizeObserver.observe(headerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [headerRef]);

  return isModalOpen ? (
    <FullScreenPlayer onDismiss={() => setIsModalOpen(false)} />
  ) : (
    <div
      className={`flex h-full min-w-[100vw] flex-col justify-between overflow-y-clip overscroll-none md:min-w-auto`}
    >
      <DimensionProvider
        observerRoot={observerRootRef.current}
        footerHeight={footerHeight}
        headerHeight={headerHeight}
      >
        <div
          className={`flex h-full w-[100vw] min-w-[100vw] flex-col overflow-hidden overflow-y-auto overscroll-none md:mb-0`}
        >
          {pathname.indexOf("locoqueen") === -1 && (
            <div ref={headerRef}>
              <TopNavigation minimal={minimal} />
            </div>
          )}
          <div
            ref={observerRootRef}
            className="flex h-full w-full flex-col"
            style={{ marginBottom: footerHeight }}
          >
            <Outlet />
          </div>
        </div>

        {!noTabs && (
          <div
            className="fixed bottom-0 left-0 z-[998] min-w-[100vw]"
            ref={footerRef}
          >
            {/* (authenticating || eventLoading) && <Skeleton variant="banner" /> */}

            {!eventLoading && eventDay && !eventEnded && <EventBanner />}

            {!authenticating &&
              !eventLoading &&
              !loggedIn &&
              !bannerDismissed &&
              !excludeBanner &&
              (!eventDay || eventEnded) && (
                <OtherSide onDismiss={() => setBannerDismissed(true)} />
              )}
            {nowPlayingIndex > -1 && loggedIn && (
              <Player onOpen={() => setIsModalOpen(true)} />
            )}
            <BarNavigation />
          </div>
        )}
        {/*
        <TakeoverNotification />
          */}
      </DimensionProvider>
    </div>
  );
}
