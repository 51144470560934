import { withLogicHook } from "../../../utils/withLogicHook";
import {
  StripeSubscriptionProps,
  useStripeSubscriptionLogic,
} from "./useStripeSubscriptionLogic";

const StripeSubscriptionComponent = ({ cells }: StripeSubscriptionProps) => {
  return (
    <div className="flex w-full flex-col items-center">
      <div className="mt-4 w-full flex-col px-4">
        {cells.map((cell, index) => (
          <div key={`${index}`} className="flex w-full justify-between">
            <div className="p-2">{cell.label}</div>
            <div className="p-2">{cell.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const StripeSubscription = withLogicHook(
  StripeSubscriptionComponent,
  useStripeSubscriptionLogic
);
