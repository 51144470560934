import { PropsWithChildren, createContext, useState, useEffect } from "react";
import {
  Notification,
  NotificationType,
  useGetNotificationsQueryGenerated,
} from "../types/generated";

interface NotificationContextParams {
  notifications: Notification[];
  takeovers: Notification[];
  unreadCount: number;
}

export const NotificationsContext = createContext<NotificationContextParams>({
  notifications: [],
  takeovers: [],
  unreadCount: 0,
});

export function NotificationsProvider({ children }: PropsWithChildren) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [takeovers, setTakeovers] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const { data } = useGetNotificationsQueryGenerated({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data.getNotifications) {
      const { notifications, unreadCount } = data.getNotifications;
      const nextNotes: Notification[] = [];
      const nextTakeovers: Notification[] = [];
      notifications.forEach((note) => {
        if (note.type === NotificationType.notification) {
          nextNotes.push(note);
        } else if (note.type === NotificationType.takeover) {
          nextTakeovers.push(note);
        }
      });
      setNotifications(nextNotes);
      setTakeovers(nextTakeovers);
      setUnreadCount(unreadCount || 0);
    }
  }, [data]);

  return (
    <NotificationsContext.Provider
      value={{ notifications, takeovers, unreadCount }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
