import { Suspense } from "react";
import { Skeleton } from "../ui/Skeleton";
import { Route } from "react-router-dom";
import { CharityDetails } from "../Charities/Details";
import { DynamicPage } from "../ui/IndexPage";
import { SkeletonVariants } from "../types/generated";
// import { IndexPage } from "../ui/IndexPage";

export function useCharityRoutes() {
  return (
    <>
      <Route
        path="/igf"
        element={
          <Suspense
            fallback={<Skeleton variant={SkeletonVariants.indexWithGrid} />}
          >
            <DynamicPage />
          </Suspense>
        }
      />
      <Route path="/igf/:charityId" element={<CharityDetails />} />
    </>
  );
}
