import React from "react";

export const withLogicHook = <Comp extends {}, Hook>(
  Component: React.ComponentType<Comp>,
  logic: (props: Hook) => Comp
) => {
  const wrapped = (props: Hook) => <Component {...logic(props)} />;
  wrapped.displayName = `Wrapped(${getComponentName(Component)})`;
  return wrapped;
};

const getComponentName = <Comp,>(
  WrappedComponent: React.ComponentType<Comp>
) => {
  return WrappedComponent.displayName ?? WrappedComponent.name ?? "Component";
};
