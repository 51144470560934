import { Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import { Home } from "../Home";
import { NotFound } from "./404";
import { Page } from "../ui/Page";
import { isBrowser } from "react-device-detect";
import { useAuthRoutes } from "../Auth/Routes";
import { useListenRoutes } from "../Listen/Routes";
import { useProfileRoutes } from "../Profile/Routes";
import { useLegalRoutes } from "../Legal/Routes";
import { useSupportRoutes } from "../Support/Routes";
import { useSalesRoutes } from "../Sales/Routes";
import { useThoughtsRoutes } from "../Thoughts/Routes";
import { useArcadeRoutes } from "../Arcade/Routes";
import { useArtistRoutes } from "../Artists/Routes";
import { useEventRoutes } from "../Events/Routes";
import { useCharityRoutes } from "../Charities/Routes";
import {
  DynamicRoutePrivacy,
  useGetDynamicRoutesQueryGenerated,
} from "../types/generated";

import { useInteractRoutes } from "../Interact/Routes";
import { LockedRoutes } from "./LockedRoutes";
import { DynamicSuspenseRoute } from "./DynamicSuspenseRoute";
import { DiscordRedirect } from "./DiscordRedirect";

const Routing = () => {
  const arcadeRoutes = useArcadeRoutes();
  const artistRoutes = useArtistRoutes();
  const authRoutes = useAuthRoutes();
  const charityRoutes = useCharityRoutes();
  const eventRoutes = useEventRoutes();
  const legalRoutes = useLegalRoutes();
  const listenRoutes = useListenRoutes();
  const profileRoutes = useProfileRoutes();
  const supportRoutes = useSupportRoutes();
  const salesRoutes = useSalesRoutes();
  const thoughtsRoutes = useThoughtsRoutes();
  const interactRoutes = useInteractRoutes();

  const { data } = useGetDynamicRoutesQueryGenerated();

  // const { data, loading } = useGetDynamicRoutesQueryGenerated();

  const routes = (
    <Routes>
      <Route path="/" element={<Page />}>
        <Route path="/home" element={<Home />} />
        <Route index element={<Navigate to="home" replace />} />

        <Route path="/discord-redirect" element={<DiscordRedirect />} />

        {authRoutes}
        {/* {legalRoutes} */}
        {listenRoutes}
        {supportRoutes}
        {salesRoutes}

        <Route element={<ProtectedRoutes />}>
          {arcadeRoutes}
          {artistRoutes}
          {charityRoutes}
          {eventRoutes}
          {profileRoutes}
          {thoughtsRoutes}
          {data?.getDynamicRoutes?.routes
            ?.filter((r) => r.privacy === DynamicRoutePrivacy.FREESTYLE)
            .map((r) => (
              <Route
                key={r.slug}
                path={r.slug}
                element={<DynamicSuspenseRoute route={r} />}
              />
            ))}
        </Route>

        <Route element={<LockedRoutes />}>
          {interactRoutes}

          {data?.getDynamicRoutes?.routes
            ?.filter((r) => r.privacy === DynamicRoutePrivacy.UNLOCKED)
            .map((r) => (
              <Route
                key={r.slug}
                path={r.slug}
                element={<DynamicSuspenseRoute route={r} />}
              />
            ))}
        </Route>

        {data?.getDynamicRoutes?.routes
          ?.filter(
            (r) => !r.privacy || r.privacy === DynamicRoutePrivacy.PUBLIC
          )
          ?.map((r) => (
            <Route
              key={r.slug}
              path={r.slug}
              element={<DynamicSuspenseRoute route={r} />}
            />
          ))}

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );

  return isBrowser ? (
    <div className="flex w-screen flex-row">
      <div className="h-full grow" />
      <div className="grow-0 overflow-hidden">{routes}</div>
      <div className="h-full grow" />
    </div>
  ) : (
    routes
  );
};

export default Routing;

// const MaintenanceMode = () => <Maintenance />;
// export default MaintenanceMode;
