import { IconButton, Typography, Modal, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  price?: number;
  track?: number;
  onConfirm: (track?: number) => void;
  onDismiss: () => void;
}

export function ConfirmKeyModal({
  open,
  price,
  track,
  onDismiss,
  onConfirm,
}: Props) {
  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="flex h-full w-full items-center justify-center"
        onClick={onDismiss}
      >
        <Box className="h-{350px} tos-body-bkg round-4 mx-6 flex w-fit flex-col space-y-4 p-6">
          <Typography variant="h5">
            You are about to spend {price} key{price && price > 1 ? "s" : ""} to
            unlock this content
          </Typography>
          <Button variant="bassnectarJumbo" onClick={() => onConfirm(track)}>
            Confirm
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
