import { IconButton, Typography, Modal, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  reason?: string;
  onDismiss: () => void;
}

export function SubscriptionModal({ open, reason, onDismiss }: Props) {
  const navigate = useNavigate();

  const about = () => navigate("/about");
  const join = () => navigate("/join");

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="flex h-full w-full items-center justify-center"
        onClick={onDismiss}
      >
        <Box className="h-{350px} tos-body-bkg round-4 mx-6  w-fit p-6">
          {reason === "guest" && (
            <>
              <div className="flex items-center justify-between">
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Join The Other Side
                </Typography>
                <IconButton onClick={onDismiss} sx={{ color: "#fff" }}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="my-4">
                <Typography variant="h6" id="modal-modal-description">
                  In order to play media, you'll need to join The Other Side
                </Typography>
              </div>
              <div className="mt-[12px] flex flex-col gap-6 text-center">
                <Button
                  variant="bassnectarText"
                  onClick={about}
                  style={{ width: "100%" }}
                >
                  What is The Other Side?
                </Button>
                <Button
                  variant="bassnectar"
                  onClick={join}
                  style={{ width: "100%" }}
                >
                  Join Now
                </Button>
              </div>
            </>
          )}

          {reason === "locked" && (
            <>
              <div className="flex items-center justify-between">
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Unlocked membership required
                </Typography>
                <IconButton onClick={onDismiss} sx={{ color: "#fff" }}>
                  <CloseIcon />
                </IconButton>
              </div>
              <Typography
                variant="body1"
                id="modal-modal-description"
                sx={{ mt: 2 }}
              >
                This content is reserved exclusively for our unlocked members
              </Typography>
              <div className="mt-[12px] text-center">
                <Link to="/auth/create-account" state={{ paid: true }}>
                  <Button variant="bassnectar" style={{ width: "100%" }}>
                    Unlock The Other Side
                  </Button>
                </Link>
              </div>
            </>
          )}
          {/*<Link to="/compare-plans" style={{fontSize: "12px"}}>Learn More</Link>*/}
        </Box>
      </div>
    </Modal>
  );
}
