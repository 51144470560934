import { TypeformWidget } from "../ui/TypeformWidget";

type Survey = {
  surveyId: string;
  surveyTitle: string;
};

type Props = {
  surveys: Survey[];
};

export function EventSurvey({ surveys }: Props) {
  return (
    <>
      {surveys.map((s) => (
        <div className="mt-5">
          <TypeformWidget
            key={s.surveyId}
            id={s.surveyId}
            title={s.surveyTitle}
          />
        </div>
      ))}
    </>
  );
}
