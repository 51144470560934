import { useEffect, useState } from "react";
import { StripeSubscription } from "../../../types/generated";

interface StripeSubscriptionInputProps {
  subscription: StripeSubscription;
}

interface Cell {
  label: string;
  value: string;
}

export const useStripeSubscriptionLogic = ({
  subscription,
}: StripeSubscriptionInputProps) => {
  const [cells, setCells] = useState<Cell[]>([]);

  const formatDate = (stripeDate?: string | null): string => {
    if (!stripeDate) return "";
    return stripeDate.split(" ")[0];
  };

  useEffect(() => {
    const buildCells = (subscription: StripeSubscription): Cell[] => {
      return [
        {
          label: "Status",
          value: "Active",
        },
        // {
        //   label: "Subscription Start",
        //   value: formatDate(subscription.currentPeriodStartUtc),
        // },
        {
          label: "Subscription Ends",
          value: "6/1/2024",
        },
      ];
    };

    setCells(buildCells(subscription));
  }, [subscription]);

  return {
    cells,
  };
};

export type StripeSubscriptionProps = ReturnType<
  typeof useStripeSubscriptionLogic
>;
