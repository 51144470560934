import { IconButton, Typography, Modal, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { ContentCopy } from "@mui/icons-material";
import {useGetTicketmasterPresaleCodeQueryGenerated} from "../types/generated";
import LoadingIcons from "react-loading-icons";

interface Props {
  open: boolean;
  url?: string;
  code?: string;
  onDismiss: () => void;
}

export function TicketmasterCodeModal({ open, url, onDismiss }: Props) {
  const { loading, data: tmData, error } =
    useGetTicketmasterPresaleCodeQueryGenerated();

  const handleCopyToClipboard = () => {
    if (tmData?.getTicketmasterPresaleCode) {
      navigator.clipboard.writeText(tmData.getTicketmasterPresaleCode || "");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex h-full w-full items-center justify-center">
        <Box className="h-{350px} tos-body-bkg round-4 mx-6  w-fit p-6">
          <div className="flex items-center justify-between">
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Your Access Code is:
            </Typography>
            <IconButton onClick={onDismiss} sx={{ color: "#fff" }}>
              <CloseIcon />
            </IconButton>
          </div>
          {!loading && tmData && tmData.getTicketmasterPresaleCode && (
            <div className="space-between align-center flex w-full">
              <div className="w-[80%]">
                <Typography
                  variant="body1"
                  id="modal-modal-description"
                  className="mt-2 w-full break-all bg-[#00000040] p-4"
                >
                  {tmData.getTicketmasterPresaleCode}
                </Typography>
              </div>
              <div className="w-[10%]">
                <Button
                  className="my-2"
                  endIcon={<ContentCopy />}
                  fullWidth
                  onClick={handleCopyToClipboard}
                />
              </div>
              <div className="mt-4 flex flex-col gap-4">
                <Link to={url || "#"}>
                  <Button variant="bassnectar">
                    Enter the Presale
                  </Button>
                </Link>
              </div>
            </div>
          )}
          {loading && (
            <LoadingIcons.Bars />
          )}
          {error && (
            <Typography variant="body1">
              Sorry an Error Occured when fetching your code.
              Please try again or contact customer service.
            </Typography>
          )}
        </Box>
      </div>
    </Modal>
  );
}
