import { SvgIcon } from "@mui/material";

export function KeyIcon() {
  return (
    <SvgIcon sx={{ height: 48, width: 100 }} viewBox="">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 224.07 626.23"
      >
        <g style={{ transform: "rotate(270deg) translateX(-405px)" }}>
          <path
            d="M73.5,178.28c0,21.29,17.25,38.54,38.54,38.54c21.29,0,38.54-17.25,38.54-38.54c0-21.29-17.25-38.54-38.54-38.54
		C90.75,139.74,73.5,157,73.5,178.28z M139.82,187.7c0,15.35-12.44,27.78-27.78,27.78s-27.78-12.44-27.78-27.78
		c0-15.35,12.44-27.79,27.78-27.79S139.82,172.35,139.82,187.7z"
          />
          <path
            d="M49.3,156.32c0,34.64,28.1,62.74,62.74,62.74s62.74-28.1,62.74-62.74c0-34.64-28.1-62.74-62.74-62.74
		S49.3,121.68,49.3,156.32z M161.11,168.87c0,27.11-21.96,49.07-49.07,49.07c-27.11,0-49.07-21.96-49.07-49.07
		c0-27.11,21.96-49.07,49.07-49.07C139.15,119.8,161.11,141.76,161.11,168.87z"
          />
          <path
            d="M80.24,50.67c-0.47-0.25-1.08-0.43-2.42,0.47s-9.1,8.02-12.62,10.55c-4.41,3.52-9.7,7.71-13.56,11.65
		c-3.85,3.92-7.35,8.25-10.42,12.86c-3.07,4.62-5.71,9.57-7.86,14.77c-2.15,5.2-3.83,10.64-4.97,16.31
		c-1.12,5.67-1.73,11.52-1.73,17.52c0,48.02,38.27,86.92,85.42,86.92c47.15,0,85.37-38.92,85.37-86.94c0-3-0.16-5.98-0.45-8.9
		c-0.29-2.91-0.74-5.8-1.3-8.63c-0.56-2.82-1.28-5.62-2.11-8.34c-0.83-2.71-1.79-5.38-2.87-7.98c-1.08-2.6-2.26-5.13-3.59-7.6
		c-1.32-2.46-2.76-4.86-4.28-7.17c-1.52-2.33-3.16-4.55-4.91-6.7c-1.75-2.15-3.59-4.21-5.51-6.18c-1.95-1.95-3.97-3.83-6.07-5.6
		c-1.9-1.59-5.13-3.94-7.55-5.56c-7.37-5.42-13.22-11.38-14.56-12.28s-1.95-0.69-2.42-0.47c-1.34,0.65-3.23,2.71-4.15,4.59
		c-0.72,1.46-2.69,7.19-2.78,10.51c-0.09,3.41,0.27,7.42,2.49,11.34c3.09,5.44,15.75,15.3,17.52,16.76c2.06,1.7,5.92,4.55,7.53,6.16
		c3.23,3.23,6.14,6.77,8.69,10.55s4.77,7.84,6.57,12.1c1.79,4.26,3.2,8.74,4.15,13.38c0.96,4.64,1.46,9.43,1.46,14.36
		c0,39.35-31.91,71.25-71.26,71.25c-39.35,0-71.26-31.91-71.26-71.25c0-2.46,0.11-4.89,0.36-7.28c0.25-2.4,0.6-4.75,1.08-7.08
		c0.47-2.33,1.05-4.62,1.75-6.83c0.69-2.22,1.5-4.41,2.4-6.54c0.9-2.13,1.9-4.21,3-6.23c1.1-2.02,2.29-3.97,3.56-5.87
		c1.28-1.9,2.64-3.72,4.1-5.49c1.46-1.77,2.98-3.45,4.59-5.06c1.61-1.61,3.32-3.14,5.22-4.71c0,0,14.74-11.49,17.81-16.94
		c2.22-3.94,2.58-7.95,2.49-11.34c-0.09-3.29-2.06-9.03-2.78-10.51C83.47,53.39,81.59,51.3,80.24,50.67z"
          />
          <path
            d="M224.07,111.29c0-29.96-11.81-58.68-32.8-79.9c-0.65-0.65-1.32-1.3-1.99-1.95c-1.28-1.21-2.67-2.49-4.1-3.74
		c-1.32-1.17-2.78-2.35-4.19-3.47c-2.08-1.97-15.17-10.64-15.17-10.64c-4.17-2.58-8.78-6.05-12.59-8.99
		c-3.81-2.91-3.76-2.58-4.48-2.58c-0.74,0-1.1,0.47-1.1,0.47c-9.5,9.86-10.33,30.18,9.21,39.32c39.91,20.53,60.52,65.5,49.88,108.85
		c-10.62,43.36-49.74,73.88-94.69,73.88c-44.95,0-84.07-30.5-94.69-73.88C6.7,105.3,27.31,60.33,67.2,39.83
		c19.52-9.14,18.71-29.47,9.21-39.32c0,0-0.38-0.47-1.1-0.47c-0.72,0-0.67-0.34-4.48,2.58C67.02,5.52,62.4,9,57.81,11.84
		c-3.65,2.4-5.2,3.43-8.25,5.47c-3.03,2.06-5.69,4.26-8.54,6.61c-2.85,2.35-5.6,4.86-8.22,7.51C11.81,52.6,0,81.33,0,111.29
		c0,29.96,11.79,58.69,32.8,79.86c15.42,15.53,34.86,26.06,55.85,30.58c0.94,0.26,1.86,0.51,2.76,0.72V497.7
		c-1.74,0.01-4.42,0.22-6.73,1.97c-4.74,3.58-4.74,11.26-4.74,16.39H45.51v-4.59c0-18.36-25.25-18.36-25.25,0v87.22
		c0,16.07,25.25,16.07,25.25,0v-6.89h34.43c0,9.18,0,18.36,11.48,18.36v16.07h41.31V222.78c2.08-0.49,4.22-1.14,6.41-1.91
		c19.57-4.92,37.65-15.11,52.14-29.72C212.26,169.97,224.07,141.24,224.07,111.29z M112.04,224.22c-0.16,0-0.32-0.01-0.48-0.01
		C111.71,224.21,111.87,224.22,112.04,224.22c0.14-0.01,0.29-0.01,0.43-0.01C112.32,224.21,112.18,224.22,112.04,224.22z"
          />
          <path
            d="M112.04,29.05c5.45,0,9.86-4.41,9.86-9.86c0-5.45-4.41-9.86-9.86-9.86s-9.86,4.41-9.86,9.86
		C102.18,24.64,106.59,29.05,112.04,29.05z"
          />
          <circle cx="112.04" cy="54.15" r="14.34" />
        </g>
      </svg>
    </SvgIcon>
  );
}
