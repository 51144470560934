import {Typography} from "@mui/material";
import LoadingIcons from "react-loading-icons";
import {useSearchParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "./AuthProvider";
import {useAcceptInviteMutationGenerated} from "../types/generated";

export const AcceptInvite = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [params] = useSearchParams();
  const invitationId = params.get("invitationId");

  const { signIn, waitForUserGroup } = useContext(AuthContext);

  const [accept] = useAcceptInviteMutationGenerated();

  useEffect(() => {
    const auth = async () => {
      if(invitationId) {
        const { data } = await accept({
          variables: {
            invitationId,
          }
        });
        if(data?.acceptInvite.success === true) {
          const { username, password } = data?.acceptInvite;
          await signIn(username!, password!);
          await waitForUserGroup("unlocked");
          setLoading(false);
        } else if(data?.acceptInvite.success === false && data?.acceptInvite.error) {
          setError(data?.acceptInvite.error);
          setLoading(false);
        }
      }
    }
    auth();
  }, [invitationId, signIn, accept, waitForUserGroup]);

  return (
    <div className="flex flex-col h-full w-full items-center justify-center">
      {loading && (
        <>
          <LoadingIcons.Bars />    
          <Typography variant="h6">Accepting invite...</Typography>
        </>
      )}
      {error && (
        <Typography variant="h6">{error}</Typography>
      )}
    </div>
  );
};
