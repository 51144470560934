import { IconButton, Typography, Modal, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { KeyIcon } from "./KeyIcon";

interface Props {
  open: boolean;
  reason: string;
  text?: string;
  onDismiss: () => void;
  onSubmit: () => void;
}

export function AudioArcadeModal({
  open,
  reason,
  text,
  onDismiss,
  onSubmit,
}: Props) {
  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="flex h-full w-full items-center justify-center"
        onClick={onDismiss}
      >
        <Box className="h-{350px} tos-body-bkg round-4 mx-6  w-fit p-6">
          <div className="flex items-center justify-between">
            <Typography id="modal-modal-title" variant="h5" component="h2">
              {reason === "error" ? "Unable to Activate" : "Play Audio Arcade"}
            </Typography>
            <IconButton onClick={onDismiss} sx={{ color: "#fff" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="my-4">
            <Typography variant="h6" id="modal-modal-description">
              {reason === "error"
                ? "Sorry we were not able to activate your session. Check your key balance and try again."
                : text
                ? text
                : `Each play in the Audio Arcade costs 1 key. Spending a key will
              unlock unlimited gameplay within a 5-hour radio broadcast which includes
              an exclusive mixtape followed by a custom cut mix of ambient music.
              Come & Go as you please but heads up: the music is continuous and will keep
              playing even if you leave.
              Click below to spend a key and start your engine!`}
            </Typography>
          </div>
          {reason === "error" ? (
            <Button variant="bassnectar" onClick={onDismiss} fullWidth>
              Ok
            </Button>
          ) : (
            <Button
              variant="bassnectar"
              onClick={onSubmit}
              endIcon={<KeyIcon />}
              fullWidth
            >
              Start a Session
            </Button>
          )}
        </Box>
      </div>
    </Modal>
  );
}
