import { Typography } from "@mui/material";
import TosLogo from "../assets/TOS LOGO.png";

export const Maintenance = () => {
  return (
    <div className="absolute flex h-screen w-screen flex-col items-center justify-center bg-black">
      <img src={TosLogo} alt="TOS" />

      <Typography
        variant="h1"
        color={"white"}
        marginTop={2}
        textAlign={"center"}
      >
        down for brief maintenance
        <br />
        we will return shortly
      </Typography>
    </div>
  );
};
