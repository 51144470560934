import { Typography, Button } from "@mui/material";
import { useContext, useState } from "react";
import ReactCodeInput from "react-code-input";
import { Navigate, useLocation } from "react-router-dom";
import { SectionHero } from "../ui/components/SectionHero";
import { AuthContext } from "../Auth/AuthProvider";
import { ErrorToast } from "../Auth/ErrorToast";

export function AttributeConfirmationForm() {
  const [code, setCode] = useState("");
  const [success, setSuccess] = useState(false);

  const { verifyAttribute, user } = useContext(AuthContext);

  const {
    state: { key },
  } = useLocation();

  const handleCode = (value: string) => {
    setCode(value);
  };

  const handleSubmit = async () => {
    if (code.length === 6 && user !== null) {
      const success = await verifyAttribute(key, code);
      setSuccess(success);
    }
  };

  return (
    <div className="h-full w-full">
      {success && <Navigate to="/profile/accountInfo" replace={true} />}
      <SectionHero title="Verification" />
      <div className="flex flex-col px-6">
        <Typography variant="h5">Confirm Update</Typography>
        <div className="mt-2">
          <Typography variant="h6">
            To keep your account secure, we need to confirm it's really you.
            Enter the code we sent to your email to verify your updates.
          </Typography>
        </div>

        <div className="mt-4 w-full">
          <ReactCodeInput
            name="confirmationCode"
            type="number"
            fields={6}
            inputMode="numeric"
            onChange={handleCode}
          />
        </div>

        <ErrorToast />

        <div className="mt-8 flex w-full items-center justify-center">
          <Button variant="bassnectar" onClick={handleSubmit}>
            {"Confirm"}
          </Button>
        </div>
      </div>
    </div>
  );
}
