export interface TileData {
  artwork: string;
  title: string;
  subtitle: string;
  id: string;
}

export interface EventType {
  id: number;
  title: string;
  path: string;
  type: string;
}

export function UpcomingEvents() {
  /*
  const {
    data: eventData,
    loading: isLoading,
    error: isError,
  } = useGetEventsByTypeQueryGenerated({
    variables: { type: 1 },
  });
   */

  return (
    <div>Upcoming</div>
    /*
    <div className="mt-10 h-full w-full">
      {!isLoading && !isError && eventData && (
        <TileList title="Upcoming Events">
          {eventData?.getEventsByType.map((item) => (
            <AlbumCard
              key={item.id}
              info={item.subtitle ?? undefined}
              title={item.title ?? ""}
              artwork={item.artwork ?? undefined}
              locked={!userIsUnlocked(user)}
              onClick={navigateToEvent(item.id)}
            />
          ))}
        </TileList>
      )}
    </div>
     */
  );
}
