import { Suspense } from "react";
import { Skeleton } from "../ui/Skeleton";
import { DynamicRoute, SkeletonVariants } from "../types/generated";
import { DynamicPage } from "../ui/IndexPage";

interface Props {
  route: DynamicRoute;
}

export const DynamicSuspenseRoute = ({ route }: Props) => {
  return (
    <Suspense
      fallback={
        <Skeleton variant={route.skeletonVariant || SkeletonVariants.index} />
      }
    >
      <DynamicPage />
    </Suspense>
  );
};
