import env from "react-dotenv";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Badge, Button, Typography } from "@mui/material";
import { SectionHero } from "../ui/components/SectionHero";
import { TreasureBox } from "./TreasureBox";
import { InfoFooter } from "../ui/InfoFooter";
import { AuthContext } from "../Auth/AuthProvider";
import { Divider } from "../ui/Divider";
import NotificationsIcon from "../assets/notifications.svg";
import DefaultProfilePicture from "../assets/defaultProfilePicture.svg";
import AccountInformationIcon from "../assets/accountInformation.svg";
import ArcadeIcon from "../assets/arcadeIcon.svg";
import ContactSupportIcon from "../assets/contactSupport.svg";
import { NotificationsContext } from "./NotificationsContext";
import { userIsUnlocked, userIsGold, userHasLinkedDiscord, userIsVip, userIsAGuest } from "../Auth/utils";
import { AppVersion } from "../ui/AppVersion";
import { UpgradeSubscriptionModal } from "./UpgradeSubscriptionModal";
import { ProfileListSection } from "./ProfileListSection";
import DiscordIcon from "../assets/DiscordIcon.svg";
import MediaQuery from "react-responsive";
import {Wallet} from "./Wallet";
import {UserSubscriptionStatus} from "./UserSubscriptionStatus";

export interface Section {
  icon: string;
  title: string;
  link?: {
    url: string;
    rel?: string;
    target?: string;
  };
  action?: ReactNode;
  badge?: ReactNode;
}

const Settings = () => {
  const navigate = useNavigate();

  const { user, signOut, getIdToken, waitForUserAttribute } = useContext(AuthContext);
  const { unreadCount } = useContext(NotificationsContext);

  const [searchParams] = useSearchParams();

  const logout = async () => {
    await signOut();
    navigate("/");
  };

  const [sections, setSections] = useState<Section[]>([]);

  const [idToken, setIdToken] = useState<string>();

  const [showUpgradeSubscription, setShowUpgradeSubscription] = useState(false);

  useEffect(() => {
    const refreshUser = async () => {
      await waitForUserAttribute("discordId");
      navigate("/profile");
    }
    const discordLinked = searchParams.get("discordLinked");
    if(discordLinked) {
      refreshUser();
    }
  }, [searchParams, waitForUserAttribute, navigate]);

  useEffect(() => {
    const buildSections = (groups: string[]) => {
      const sections: Section[] = [
        {
          icon: NotificationsIcon,
          title: "Notifications",
          link: {
            url: "/profile/notifications",
          },
          badge: <Badge badgeContent={unreadCount} color="error" />,
        },
        {
          icon: DefaultProfilePicture,
          title: "Profile Picture",
          link: {
            url: "/profile/avatar",
          },
        },
        {
          icon: AccountInformationIcon,
          title: "My Account Information",
          link: {
            url: "/profile/accountInfo",
          },
        },
        {
          icon: ArcadeIcon,
          title: "My High Scores",
          link: {
            url: "/profile/scores",
          },
        },
        {
          icon: ContactSupportIcon,
          title: "Get help",
          link: {
            url: "https://bassnetwork.freshdesk.com/support/tickets/new",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      ];

      if (userIsUnlocked(user)) {
        if (userIsGold(user) === false) {
          sections.push({
            icon: AccountInformationIcon,
            title: "Upgrade Subscription",
            action: (
              <Button
                variant="bassnectarGold"
                onClick={() => setShowUpgradeSubscription(true)}
              >
                UPGRADE
              </Button>
            ),
          });
        }

        if (userIsUnlocked(user)) {
          sections.push({
            icon: DiscordIcon,
            title: "Community Haven",
            action: (
              <div className="flex items-center justify-center space-x-2">
                {userHasLinkedDiscord(user) === false ? (
                  <a
                    href={`https://discord.com/oauth2/authorize?client_id=1238256833102348419&response_type=code&redirect_uri=${encodeURIComponent(
                      `${env.API_URL}/join-discord`
                    )}&scope=identify+guilds.join&state=${idToken}`}
                  >
                    <Button className="w-full" variant="bassnectar">
                      <span>CONNECT</span>
                      <MediaQuery minWidth={769}>
                         <span className="ml-1">DISCORD</span>
                      </MediaQuery>
                    </Button>
                  </a>
                ) : (
                  <a
                    href="https://discord.com/channels/784687598000930847/1065052871416090625"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className="w-full" variant="bassnectar">
                      OPEN
                    </Button>
                  </a>
                )}
              </div>
            ),
          });
        }
      }

      setSections(sections);
    };

    buildSections(user?.groups ?? []);
  }, [user, user?.groups, unreadCount, idToken]);

  useEffect(() => {
    const loadToken = async () => {
      setIdToken(await getIdToken());
    };

    loadToken();
  }, [getIdToken]);

  return (
    <div id="profile-detail">
      {user && (
        <>
          <SectionHero title="Profile" />
          <UpgradeSubscriptionModal
            open={showUpgradeSubscription}
            onDismiss={() => setShowUpgradeSubscription(false)}
          />
          <div className="flex flex-col lg:min-w-[100vw] lg:items-center">
            <div className="flex flex-col items-center justify-center px-6 lg:max-w-[750px]">
              <div className="my-4 flex w-full items-center justify-between px-2">
                <Typography variant="h5">{`Welcome ${
                  user!.username
                }!`}</Typography>
              </div>

              <UserSubscriptionStatus user={user} />

              {sections.map((section, index) => (
                <ProfileListSection key={index} section={section} />
              ))}

              <div className="w-full">
                {userIsUnlocked(user) && (
                  <div className="mt-6">
                    {!userIsVip(user) && !userIsAGuest(user) && (
                      <Wallet />
                    )}
                    <TreasureBox />
                  </div>
                )}

                <div className="mt-8 flex w-full justify-center">
                  <Button variant="bassnectarJumbo" onClick={logout} fullWidth>
                    Sign Out
                  </Button>
                </div>

                <div className="mt-4 flex w-full flex-nowrap items-center justify-center text-center">
                  <AppVersion />
                </div>

                <InfoFooter />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;
