import { useContext } from "react";
import SwipeOptions from "./SwipeOptions";
import { Button } from "@mui/material";
import { SectionHero } from "../ui/components/SectionHero";
import { useNavigate } from "react-router-dom";
import { NotificationsContext } from "./NotificationsContext";

const Notifications = () => {
  const { notifications } = useContext(NotificationsContext);
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  return (
    <div id="notifications-page" className="flex min-w-[100vw] flex-col md:min-w-auto lg:items-center">
      <SectionHero title="Notifications" />
      <div className="mb-8 flex flex-col items-center justify-center px-6 lg:max-w-[750px]">
        <div className="w-full gap-2">
          {notifications.length > 0 && (
            <ul>
              {notifications
                .sort((a, b) => {
                  if (a.id < b.id) return 1;
                  if (a.id > b.id) return -1;
                  return 0;
                })
                .map((note) => (
                  <li key={note.id}>
                    <SwipeOptions id={note.id} status={note.status || "unread"}>
                      {note.title}
                    </SwipeOptions>
                  </li>
                ))}
            </ul>
          )}
          <Button variant="bassnectarText" onClick={handleBack}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
