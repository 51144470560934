import {
  Children,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { DimensionContext } from "./DimensionProvider";

export function FiftyFifty({ children }: PropsWithChildren) {
  const [maxHeight, setMaxHeight] = useState<string | undefined>();

  const { headerHeight, footerHeight } = useContext(DimensionContext);
  console.log(headerHeight);
  console.log(footerHeight);
  useEffect(() => {
    setMaxHeight(`calc(100vh - ${headerHeight}px - ${footerHeight}px - 1rem)`);
  }, [footerHeight, headerHeight]);

  return (
    <div className="z-10 flex w-full">
      <div className="flex w-1/2 flex-col px-4">
        {Children.toArray(children)[0]}
      </div>
      <div
        className="mb-4 flex w-1/2 flex-col gap-4 overflow-y-auto"
        style={{ maxHeight }}
      >
        {Children.toArray(children)[1]}
      </div>
    </div>
  );
}
