import { IconButton, Typography, Modal, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import LoadingIcons from "react-loading-icons";

interface Props {
  open: boolean;
  onDismiss: () => void;
  onSubmit: () => void;
  loading: boolean;
}

export function CancelSubscriptionModal({
  open,
  onDismiss,
  onSubmit,
  loading,
}: Props) {
  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex h-full w-full items-center justify-center">
        <Box className="h-{350px} tos-body-bkg round-4 mx-6  w-fit p-6">
          {loading ? (
            <div className="flex h-full flex-col items-center justify-center">
              <LoadingIcons.Bars width="200px" />
              <Typography variant="h6">Turning off Auto-Renew</Typography>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-between">
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Turn off Auto-Renew?
                </Typography>
                <IconButton onClick={onDismiss} sx={{ color: "#fff" }}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="my-4">
                <Typography
                  variant="body1"
                  id="modal-modal-description"
                  sx={{ lineHeight: 1.25 }}
                >
                  As an unlocked member you have paid for an entire year. By
                  taking this action, you will prevent your subscription from
                  renewing automatically, but your subscription will remain
                  active until your renewal date and no refund will be granted.
                  For the duration of your subscription period you will be able
                  to enjoy our services, including buying tickets to Bassnectar
                  Live Events.
                </Typography>
              </div>
              <div className="mt-[12px] text-center">
                <Button variant="bassnectar" fullWidth onClick={onSubmit}>
                  Turn off auto renew
                </Button>
              </div>
              <div className="mt-4 flex flex-col items-center gap-4">
                <Typography
                  variant="body1"
                  id="modal-modal-description"
                  sx={{ lineHeight: 1.25 }}
                >
                  If you wish to delete your account info and forefeit the
                  remainder of your subscription, please use the following link
                  to request your data be deleted permanently:
                </Typography>

                <Link to="https://bassnetwork.freshdesk.com/support/tickets/new">
                  <Button variant="bassnectarText">Delete my data</Button>
                </Link>
              </div>
            </>
          )}
        </Box>
      </div>
    </Modal>
  );
}
